/* tslint:disable */
/* eslint-disable */
/**
 * Urstamm API
 * Urstamm API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Batch } from './Batch';
import {
    BatchFromJSON,
    BatchFromJSONTyped,
    BatchToJSON,
} from './Batch';
import type { User } from './User';
import {
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * 
 * @export
 * @interface BatchState
 */
export interface BatchState {
    /**
     * 
     * @type {number}
     * @memberof BatchState
     */
    id?: number;
    /**
     * 
     * @type {Date}
     * @memberof BatchState
     */
    date?: Date;
    /**
     * 
     * @type {string}
     * @memberof BatchState
     */
    state: BatchStateStateEnum;
    /**
     * 
     * @type {Batch}
     * @memberof BatchState
     */
    batch?: Batch;
    /**
     * 
     * @type {User}
     * @memberof BatchState
     */
    author?: User;
}


/**
 * @export
 */
export const BatchStateStateEnum = {
    InProgress: 'IN_PROGRESS',
    Closed: 'CLOSED'
} as const;
export type BatchStateStateEnum = typeof BatchStateStateEnum[keyof typeof BatchStateStateEnum];


/**
 * Check if a given object implements the BatchState interface.
 */
export function instanceOfBatchState(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "state" in value;

    return isInstance;
}

export function BatchStateFromJSON(json: any): BatchState {
    return BatchStateFromJSONTyped(json, false);
}

export function BatchStateFromJSONTyped(json: any, ignoreDiscriminator: boolean): BatchState {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'date': !exists(json, 'date') ? undefined : (new Date(json['date'])),
        'state': json['state'],
        'batch': !exists(json, 'batch') ? undefined : BatchFromJSON(json['batch']),
        'author': !exists(json, 'author') ? undefined : UserFromJSON(json['author']),
    };
}

export function BatchStateToJSON(value?: BatchState | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'date': value.date === undefined ? undefined : (value.date.toISOString()),
        'state': value.state,
        'batch': BatchToJSON(value.batch),
        'author': UserToJSON(value.author),
    };
}

