/* tslint:disable */
/* eslint-disable */
/**
 * Urstamm API
 * Urstamm API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type {
    CustomLoggingCriteria,
    EntityWithFileDTOLogging,
    Logging,
    LoggingDTO,
    PageLogging,
} from '../models';
import {
    CustomLoggingCriteriaToJSON,
    EntityWithFileDTOLoggingToJSON,
    LoggingDTOFromJSON,
    LoggingFromJSON,
    PageLoggingFromJSON
} from '../models';
import * as runtime from '../runtime';

export interface CreateLoggingCustomRequest {
    entityWithFileDTOLogging: EntityWithFileDTOLogging;
}

export interface DeleteLoggingCustomRequest {
    id: number;
}

export interface DownloadLoggingPermitRequest {
    id: number;
}

export interface GetAllLoggingsForMyCompanyRequest {
    customLoggingCriteria: CustomLoggingCriteria;
}

export interface GetLoggingCustomRequest {
    id: number;
}

export interface GetPageLoggingsForMyCompanyRequest {
    customLoggingCriteria: CustomLoggingCriteria;
    page?: number;
    size?: number;
    sort?: Array<string>;
}

export interface UpdateLoggingCustomRequest {
    id: number;
    entityWithFileDTOLogging: EntityWithFileDTOLogging;
}

/**
 * 
 */
export class CustomLoggingResourceApi extends runtime.BaseAPI {

    /**
     * Create a new Logging connecting to Company of logged user.
     * Create a Logging
     */
    async createLoggingCustomRaw(requestParameters: CreateLoggingCustomRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Logging>> {
        if (requestParameters.entityWithFileDTOLogging === null || requestParameters.entityWithFileDTOLogging === undefined) {
            throw new runtime.RequiredError('entityWithFileDTOLogging','Required parameter requestParameters.entityWithFileDTOLogging was null or undefined when calling createLoggingCustom.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/custom/loggings`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: EntityWithFileDTOLoggingToJSON(requestParameters.entityWithFileDTOLogging),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LoggingFromJSON(jsonValue));
    }

    /**
     * Create a new Logging connecting to Company of logged user.
     * Create a Logging
     */
    async createLoggingCustom(requestParameters: CreateLoggingCustomRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Logging> {
        const response = await this.createLoggingCustomRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete Logging identified by given id.
     * Delete a Logging
     */
    async deleteLoggingCustomRaw(requestParameters: DeleteLoggingCustomRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteLoggingCustom.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/custom/loggings/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete Logging identified by given id.
     * Delete a Logging
     */
    async deleteLoggingCustom(requestParameters: DeleteLoggingCustomRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteLoggingCustomRaw(requestParameters, initOverrides);
    }

    /**
     * Download permit image/document corresponding to Logging identified by given id.
     * Download permit of Logging
     */
    async downloadLoggingPermitRaw(requestParameters: DownloadLoggingPermitRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling downloadLoggingPermit.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/custom/loggings/{id}/download/permit`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Download permit image/document corresponding to Logging identified by given id.
     * Download permit of Logging
     */
    async downloadLoggingPermit(requestParameters: DownloadLoggingPermitRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.downloadLoggingPermitRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a list (page) of Logging belonging to Company of logged User.
     * Get all loggings for my Company
     */
    async getAllLoggingsForMyCompanyRaw(requestParameters: GetAllLoggingsForMyCompanyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Logging>>> {
        if (requestParameters.customLoggingCriteria === null || requestParameters.customLoggingCriteria === undefined) {
            throw new runtime.RequiredError('customLoggingCriteria','Required parameter requestParameters.customLoggingCriteria was null or undefined when calling getAllLoggingsForMyCompany.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/custom/loggings/company/my/all`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CustomLoggingCriteriaToJSON(requestParameters.customLoggingCriteria),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(LoggingFromJSON));
    }

    /**
     * Get a list (page) of Logging belonging to Company of logged User.
     * Get all loggings for my Company
     */
    async getAllLoggingsForMyCompany(requestParameters: GetAllLoggingsForMyCompanyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Logging>> {
        const response = await this.getAllLoggingsForMyCompanyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Logging identified by given id (must belong to Company of logged user).
     * Get a specific Logging
     */
    async getLoggingCustomRaw(requestParameters: GetLoggingCustomRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LoggingDTO>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getLoggingCustom.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/custom/loggings/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LoggingDTOFromJSON(jsonValue));
    }

    /**
     * Get Logging identified by given id (must belong to Company of logged user).
     * Get a specific Logging
     */
    async getLoggingCustom(requestParameters: GetLoggingCustomRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LoggingDTO> {
        const response = await this.getLoggingCustomRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a page of Logging belonging to Company of logged User.
     * Get a page of loggings for my Company
     */
    async getPageLoggingsForMyCompanyRaw(requestParameters: GetPageLoggingsForMyCompanyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PageLogging>> {
        if (requestParameters.customLoggingCriteria === null || requestParameters.customLoggingCriteria === undefined) {
            throw new runtime.RequiredError('customLoggingCriteria','Required parameter requestParameters.customLoggingCriteria was null or undefined when calling getPageLoggingsForMyCompany.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/custom/loggings/company/my`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CustomLoggingCriteriaToJSON(requestParameters.customLoggingCriteria),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageLoggingFromJSON(jsonValue));
    }

    /**
     * Get a page of Logging belonging to Company of logged User.
     * Get a page of loggings for my Company
     */
    async getPageLoggingsForMyCompany(requestParameters: GetPageLoggingsForMyCompanyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PageLogging> {
        const response = await this.getPageLoggingsForMyCompanyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Partial update Logging identified by given id (must belong to Company of logged user).
     * Partial update a Logging
     */
    async updateLoggingCustomRaw(requestParameters: UpdateLoggingCustomRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Logging>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateLoggingCustom.');
        }

        if (requestParameters.entityWithFileDTOLogging === null || requestParameters.entityWithFileDTOLogging === undefined) {
            throw new runtime.RequiredError('entityWithFileDTOLogging','Required parameter requestParameters.entityWithFileDTOLogging was null or undefined when calling updateLoggingCustom.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/custom/loggings/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: EntityWithFileDTOLoggingToJSON(requestParameters.entityWithFileDTOLogging),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LoggingFromJSON(jsonValue));
    }

    /**
     * Partial update Logging identified by given id (must belong to Company of logged user).
     * Partial update a Logging
     */
    async updateLoggingCustom(requestParameters: UpdateLoggingCustomRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Logging> {
        const response = await this.updateLoggingCustomRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
