import Constants from 'expo-constants';

let extra = Constants.manifest?.extra;

export const environment = {
  debugRoom: extra?.debugRoom,
  production: extra?.isProduction,
  language: extra?.language,
  apiUrl: extra?.apiUrl,
  sentryDsn: extra?.sentryDsn,
  sentryEnableDevelopment: extra?.sentryEnableDevelopment,
  disableLoader: extra?.disableLoader,
  inlineAuthTokenKey: extra?.inlineAuthTokenKey,
  debugForceOffline: extra?.debugForceOffline,
  enableOfflineSync: extra?.enableOfflineSync,
  supportEmail: extra?.supportEmail,
};
