/* tslint:disable */
/* eslint-disable */
/**
 * Urstamm API
 * Urstamm API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CustomDeliverySheetCriteria,
  DeliverySheet,
  DeliverySheetDTO,
  DeliverySheetErpImportation,
  DeliverySheetPhoto,
  DeliverySheetValidationDTO,
  EntityWithFileDTODeliverySheetErpImportation,
  EntityWithFileDTODeliverySheetPhoto,
  PageDeliverySheetDTO,
} from '../models';
import {
    CustomDeliverySheetCriteriaFromJSON,
    CustomDeliverySheetCriteriaToJSON,
    DeliverySheetFromJSON,
    DeliverySheetToJSON,
    DeliverySheetDTOFromJSON,
    DeliverySheetDTOToJSON,
    DeliverySheetErpImportationFromJSON,
    DeliverySheetErpImportationToJSON,
    DeliverySheetPhotoFromJSON,
    DeliverySheetPhotoToJSON,
    DeliverySheetValidationDTOFromJSON,
    DeliverySheetValidationDTOToJSON,
    EntityWithFileDTODeliverySheetErpImportationFromJSON,
    EntityWithFileDTODeliverySheetErpImportationToJSON,
    EntityWithFileDTODeliverySheetPhotoFromJSON,
    EntityWithFileDTODeliverySheetPhotoToJSON,
    PageDeliverySheetDTOFromJSON,
    PageDeliverySheetDTOToJSON,
} from '../models';

export interface CopyDeliverySheetRequest {
    id: number;
}

export interface CreateDeliverySheetCustomRequest {
    deliverySheetDTO: DeliverySheetDTO;
}

export interface DownloadDeliverySheetErpImportationRequest {
    id: number;
    importId: number;
}

export interface DownloadDeliverySheetPhotoRequest {
    id: number;
    photoId: number;
}

export interface ExportDeliverySheetRequest {
    id: number;
    deliverySheetExportType: ExportDeliverySheetDeliverySheetExportTypeEnum;
    notes?: string;
}

export interface GetAllMyDeliverySheetsRequest {
    customDeliverySheetCriteria: CustomDeliverySheetCriteria;
}

export interface GetDeliverySheetCustomRequest {
    id: number;
}

export interface GetDeliverySheetsByLoggingRequest {
    loggingId: number;
    sortBy?: string;
    direction?: GetDeliverySheetsByLoggingDirectionEnum;
}

export interface GetMyDeliverySheetsPageRequest {
    customDeliverySheetCriteria: CustomDeliverySheetCriteria;
    page?: number;
    size?: number;
    sort?: Array<string>;
}

export interface UpdateDeliverySheetCustomRequest {
    id: number;
    deliverySheetDTO: DeliverySheetDTO;
    updateOnly?: boolean;
}

export interface UploadDeliverySheetImportFileRequest {
    id: number;
    trunkDataSource: UploadDeliverySheetImportFileTrunkDataSourceEnum;
    entityWithFileDTODeliverySheetErpImportation: EntityWithFileDTODeliverySheetErpImportation;
}

export interface UploadDeliverySheetPhotoRequest {
    id: number;
    entityWithFileDTODeliverySheetPhoto: EntityWithFileDTODeliverySheetPhoto;
}

export interface ValidateDeliverySheetRequest {
    id: number;
    deliverySheetValidationDTO: DeliverySheetValidationDTO;
}

/**
 * 
 */
export class CustomDeliverySheetResourceApi extends runtime.BaseAPI {

    /**
     * Copy an existing DeliverySheet copying all properties but trunks.
     * Copy a DeliverySheet without trunks
     */
    async copyDeliverySheetRaw(requestParameters: CopyDeliverySheetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DeliverySheet>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling copyDeliverySheet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/custom/delivery-sheets/{id}/copy`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DeliverySheetFromJSON(jsonValue));
    }

    /**
     * Copy an existing DeliverySheet copying all properties but trunks.
     * Copy a DeliverySheet without trunks
     */
    async copyDeliverySheet(requestParameters: CopyDeliverySheetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DeliverySheet> {
        const response = await this.copyDeliverySheetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a new DeliverySheet connecting to given Pile.
     * Create a DeliverySheet
     */
    async createDeliverySheetCustomRaw(requestParameters: CreateDeliverySheetCustomRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DeliverySheet>> {
        if (requestParameters.deliverySheetDTO === null || requestParameters.deliverySheetDTO === undefined) {
            throw new runtime.RequiredError('deliverySheetDTO','Required parameter requestParameters.deliverySheetDTO was null or undefined when calling createDeliverySheetCustom.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/custom/delivery-sheets`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DeliverySheetDTOToJSON(requestParameters.deliverySheetDTO),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DeliverySheetFromJSON(jsonValue));
    }

    /**
     * Create a new DeliverySheet connecting to given Pile.
     * Create a DeliverySheet
     */
    async createDeliverySheetCustom(requestParameters: CreateDeliverySheetCustomRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DeliverySheet> {
        const response = await this.createDeliverySheetCustomRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Download erp importation file corresponding to DeliverySheet identified by given id.
     * Download erp importation of DeliverySheet
     */
    async downloadDeliverySheetErpImportationRaw(requestParameters: DownloadDeliverySheetErpImportationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling downloadDeliverySheetErpImportation.');
        }

        if (requestParameters.importId === null || requestParameters.importId === undefined) {
            throw new runtime.RequiredError('importId','Required parameter requestParameters.importId was null or undefined when calling downloadDeliverySheetErpImportation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/custom/delivery-sheets/{id}/download/import/{importId}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"importId"}}`, encodeURIComponent(String(requestParameters.importId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Download erp importation file corresponding to DeliverySheet identified by given id.
     * Download erp importation of DeliverySheet
     */
    async downloadDeliverySheetErpImportation(requestParameters: DownloadDeliverySheetErpImportationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.downloadDeliverySheetErpImportationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Download photo image corresponding to DeliverySheet identified by given id.
     * Download photo of DeliverySheet
     */
    async downloadDeliverySheetPhotoRaw(requestParameters: DownloadDeliverySheetPhotoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling downloadDeliverySheetPhoto.');
        }

        if (requestParameters.photoId === null || requestParameters.photoId === undefined) {
            throw new runtime.RequiredError('photoId','Required parameter requestParameters.photoId was null or undefined when calling downloadDeliverySheetPhoto.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/custom/delivery-sheets/{id}/download/photo/{photoId}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"photoId"}}`, encodeURIComponent(String(requestParameters.photoId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Download photo image corresponding to DeliverySheet identified by given id.
     * Download photo of DeliverySheet
     */
    async downloadDeliverySheetPhoto(requestParameters: DownloadDeliverySheetPhotoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.downloadDeliverySheetPhotoRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Download DeliverySheet identified by given id with format set in url.
     * Export DeliverySheet with desired format
     */
    async exportDeliverySheetRaw(requestParameters: ExportDeliverySheetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling exportDeliverySheet.');
        }

        if (requestParameters.deliverySheetExportType === null || requestParameters.deliverySheetExportType === undefined) {
            throw new runtime.RequiredError('deliverySheetExportType','Required parameter requestParameters.deliverySheetExportType was null or undefined when calling exportDeliverySheet.');
        }

        const queryParameters: any = {};

        if (requestParameters.notes !== undefined) {
            queryParameters['notes'] = requestParameters.notes;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/custom/delivery-sheets/{id}/export/{deliverySheetExportType}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"deliverySheetExportType"}}`, encodeURIComponent(String(requestParameters.deliverySheetExportType))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Download DeliverySheet identified by given id with format set in url.
     * Export DeliverySheet with desired format
     */
    async exportDeliverySheet(requestParameters: ExportDeliverySheetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.exportDeliverySheetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all DeliverySheets belonging to Company of logged user.
     * Get all DeliverySheets of logged user Company
     */
    async getAllMyDeliverySheetsRaw(requestParameters: GetAllMyDeliverySheetsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<DeliverySheetDTO>>> {
        if (requestParameters.customDeliverySheetCriteria === null || requestParameters.customDeliverySheetCriteria === undefined) {
            throw new runtime.RequiredError('customDeliverySheetCriteria','Required parameter requestParameters.customDeliverySheetCriteria was null or undefined when calling getAllMyDeliverySheets.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/custom/delivery-sheets/my/all`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CustomDeliverySheetCriteriaToJSON(requestParameters.customDeliverySheetCriteria),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DeliverySheetDTOFromJSON));
    }

    /**
     * Get all DeliverySheets belonging to Company of logged user.
     * Get all DeliverySheets of logged user Company
     */
    async getAllMyDeliverySheets(requestParameters: GetAllMyDeliverySheetsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<DeliverySheetDTO>> {
        const response = await this.getAllMyDeliverySheetsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get DeliverySheet identified by given id (must belong to Company of logged user).
     * Get a specific DeliverySheet
     */
    async getDeliverySheetCustomRaw(requestParameters: GetDeliverySheetCustomRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DeliverySheetDTO>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getDeliverySheetCustom.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/custom/delivery-sheets/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DeliverySheetDTOFromJSON(jsonValue));
    }

    /**
     * Get DeliverySheet identified by given id (must belong to Company of logged user).
     * Get a specific DeliverySheet
     */
    async getDeliverySheetCustom(requestParameters: GetDeliverySheetCustomRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DeliverySheetDTO> {
        const response = await this.getDeliverySheetCustomRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a list of DeliverySheets for Logging with given id (must belong to Company of logged user).
     * Get a list of DeliverySheets for Logging
     */
    async getDeliverySheetsByLoggingRaw(requestParameters: GetDeliverySheetsByLoggingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<DeliverySheetDTO>>> {
        if (requestParameters.loggingId === null || requestParameters.loggingId === undefined) {
            throw new runtime.RequiredError('loggingId','Required parameter requestParameters.loggingId was null or undefined when calling getDeliverySheetsByLogging.');
        }

        const queryParameters: any = {};

        if (requestParameters.sortBy !== undefined) {
            queryParameters['sortBy'] = requestParameters.sortBy;
        }

        if (requestParameters.direction !== undefined) {
            queryParameters['direction'] = requestParameters.direction;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/custom/delivery-sheets/logging/{loggingId}`.replace(`{${"loggingId"}}`, encodeURIComponent(String(requestParameters.loggingId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DeliverySheetDTOFromJSON));
    }

    /**
     * Get a list of DeliverySheets for Logging with given id (must belong to Company of logged user).
     * Get a list of DeliverySheets for Logging
     */
    async getDeliverySheetsByLogging(requestParameters: GetDeliverySheetsByLoggingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<DeliverySheetDTO>> {
        const response = await this.getDeliverySheetsByLoggingRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a page of DeliverySheets belonging to Company of logged user.
     * Get a page of DeliverySheets of logged user Company
     */
    async getMyDeliverySheetsPageRaw(requestParameters: GetMyDeliverySheetsPageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PageDeliverySheetDTO>> {
        if (requestParameters.customDeliverySheetCriteria === null || requestParameters.customDeliverySheetCriteria === undefined) {
            throw new runtime.RequiredError('customDeliverySheetCriteria','Required parameter requestParameters.customDeliverySheetCriteria was null or undefined when calling getMyDeliverySheetsPage.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/custom/delivery-sheets/my/page`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CustomDeliverySheetCriteriaToJSON(requestParameters.customDeliverySheetCriteria),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageDeliverySheetDTOFromJSON(jsonValue));
    }

    /**
     * Get a page of DeliverySheets belonging to Company of logged user.
     * Get a page of DeliverySheets of logged user Company
     */
    async getMyDeliverySheetsPage(requestParameters: GetMyDeliverySheetsPageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PageDeliverySheetDTO> {
        const response = await this.getMyDeliverySheetsPageRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Partial update DeliverySheet identified by given id (must belong to Pile of logged user).
     * Partial update a DeliverySheet
     */
    async updateDeliverySheetCustomRaw(requestParameters: UpdateDeliverySheetCustomRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DeliverySheet>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateDeliverySheetCustom.');
        }

        if (requestParameters.deliverySheetDTO === null || requestParameters.deliverySheetDTO === undefined) {
            throw new runtime.RequiredError('deliverySheetDTO','Required parameter requestParameters.deliverySheetDTO was null or undefined when calling updateDeliverySheetCustom.');
        }

        const queryParameters: any = {};

        if (requestParameters.updateOnly !== undefined) {
            queryParameters['updateOnly'] = requestParameters.updateOnly;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/custom/delivery-sheets/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DeliverySheetDTOToJSON(requestParameters.deliverySheetDTO),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DeliverySheetFromJSON(jsonValue));
    }

    /**
     * Partial update DeliverySheet identified by given id (must belong to Pile of logged user).
     * Partial update a DeliverySheet
     */
    async updateDeliverySheetCustom(requestParameters: UpdateDeliverySheetCustomRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DeliverySheet> {
        const response = await this.updateDeliverySheetCustomRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Upload a file with data to import in existing DeliverySheet.
     * Upload file for DeliverySheet import
     */
    async uploadDeliverySheetImportFileRaw(requestParameters: UploadDeliverySheetImportFileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DeliverySheetErpImportation>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling uploadDeliverySheetImportFile.');
        }

        if (requestParameters.trunkDataSource === null || requestParameters.trunkDataSource === undefined) {
            throw new runtime.RequiredError('trunkDataSource','Required parameter requestParameters.trunkDataSource was null or undefined when calling uploadDeliverySheetImportFile.');
        }

        if (requestParameters.entityWithFileDTODeliverySheetErpImportation === null || requestParameters.entityWithFileDTODeliverySheetErpImportation === undefined) {
            throw new runtime.RequiredError('entityWithFileDTODeliverySheetErpImportation','Required parameter requestParameters.entityWithFileDTODeliverySheetErpImportation was null or undefined when calling uploadDeliverySheetImportFile.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/custom/delivery-sheets/{id}/import/{trunkDataSource}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"trunkDataSource"}}`, encodeURIComponent(String(requestParameters.trunkDataSource))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: EntityWithFileDTODeliverySheetErpImportationToJSON(requestParameters.entityWithFileDTODeliverySheetErpImportation),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DeliverySheetErpImportationFromJSON(jsonValue));
    }

    /**
     * Upload a file with data to import in existing DeliverySheet.
     * Upload file for DeliverySheet import
     */
    async uploadDeliverySheetImportFile(requestParameters: UploadDeliverySheetImportFileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DeliverySheetErpImportation> {
        const response = await this.uploadDeliverySheetImportFileRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Upload a photo for DeliverySheet identified by given id.
     * Upload photo for DeliverySheet
     */
    async uploadDeliverySheetPhotoRaw(requestParameters: UploadDeliverySheetPhotoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DeliverySheetPhoto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling uploadDeliverySheetPhoto.');
        }

        if (requestParameters.entityWithFileDTODeliverySheetPhoto === null || requestParameters.entityWithFileDTODeliverySheetPhoto === undefined) {
            throw new runtime.RequiredError('entityWithFileDTODeliverySheetPhoto','Required parameter requestParameters.entityWithFileDTODeliverySheetPhoto was null or undefined when calling uploadDeliverySheetPhoto.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        // console.log('new delivery photo ... ', requestParameters.entityWithFileDTODeliverySheetPhoto.entity?.photoCreationDate);

        const response = await this.request({
            path: `/api/custom/delivery-sheets/{id}/photo`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: EntityWithFileDTODeliverySheetPhotoToJSON(requestParameters.entityWithFileDTODeliverySheetPhoto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DeliverySheetPhotoFromJSON(jsonValue));
    }

    /**
     * Upload a photo for DeliverySheet identified by given id.
     * Upload photo for DeliverySheet
     */
    async uploadDeliverySheetPhoto(requestParameters: UploadDeliverySheetPhotoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DeliverySheetPhoto> {
        const response = await this.uploadDeliverySheetPhotoRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Validate a DeliverySheet with changes on trunks.
     * Validate DeliverySheet
     */
    async validateDeliverySheetRaw(requestParameters: ValidateDeliverySheetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DeliverySheet>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling validateDeliverySheet.');
        }

        if (requestParameters.deliverySheetValidationDTO === null || requestParameters.deliverySheetValidationDTO === undefined) {
            throw new runtime.RequiredError('deliverySheetValidationDTO','Required parameter requestParameters.deliverySheetValidationDTO was null or undefined when calling validateDeliverySheet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/custom/delivery-sheets/{id}/validate`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DeliverySheetValidationDTOToJSON(requestParameters.deliverySheetValidationDTO),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DeliverySheetFromJSON(jsonValue));
    }

    /**
     * Validate a DeliverySheet with changes on trunks.
     * Validate DeliverySheet
     */
    async validateDeliverySheet(requestParameters: ValidateDeliverySheetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DeliverySheet> {
        const response = await this.validateDeliverySheetRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const ExportDeliverySheetDeliverySheetExportTypeEnum = {
    Pdf: 'PDF',
    Xml: 'XML'
} as const;
export type ExportDeliverySheetDeliverySheetExportTypeEnum = typeof ExportDeliverySheetDeliverySheetExportTypeEnum[keyof typeof ExportDeliverySheetDeliverySheetExportTypeEnum];
/**
 * @export
 */
export const GetDeliverySheetsByLoggingDirectionEnum = {
    Asc: 'ASC',
    Desc: 'DESC'
} as const;
export type GetDeliverySheetsByLoggingDirectionEnum = typeof GetDeliverySheetsByLoggingDirectionEnum[keyof typeof GetDeliverySheetsByLoggingDirectionEnum];
/**
 * @export
 */
export const UploadDeliverySheetImportFileTrunkDataSourceEnum = {
    Manual: 'MANUAL',
    ErpLehmann: 'ERP_LEHMANN'
} as const;
export type UploadDeliverySheetImportFileTrunkDataSourceEnum = typeof UploadDeliverySheetImportFileTrunkDataSourceEnum[keyof typeof UploadDeliverySheetImportFileTrunkDataSourceEnum];
