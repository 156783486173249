/* tslint:disable */
/* eslint-disable */
/**
 * Urstamm API
 * Urstamm API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type {
    EntityWithFileDTOTreeDTO,
    Tree,
    TreeDTO,
} from '../models';
import {
    EntityWithFileDTOTreeDTOToJSON,
    TreeDTOFromJSON,
    TreeFromJSON
} from '../models';
import * as runtime from '../runtime';

export interface CreateTreeCustomRequest {
    entityWithFileDTOTreeDTO: EntityWithFileDTOTreeDTO;
}

export interface DownloadTreePhotoRequest {
    id: number;
}

export interface GetTreesByLoggingRequest {
    loggingId: number;
    sortBy?: string;
    direction?: GetTreesByLoggingDirectionEnum;
}

export interface UpdateTreeCustomRequest {
    id: number;
    entityWithFileDTOTreeDTO: EntityWithFileDTOTreeDTO;
}

/**
 * 
 */
export class CustomTreeResourceApi extends runtime.BaseAPI {

    /**
     * Create a new Tree connecting to given Logging.
     * Create a Tree
     */
    async createTreeCustomRaw(requestParameters: CreateTreeCustomRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Tree>> {
        if (requestParameters.entityWithFileDTOTreeDTO === null || requestParameters.entityWithFileDTOTreeDTO === undefined) {
            throw new runtime.RequiredError('entityWithFileDTOTreeDTO','Required parameter requestParameters.entityWithFileDTOTreeDTO was null or undefined when calling createTreeCustom.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        // console.log('new tree...', requestParameters.entityWithFileDTOTreeDTO.entity);

        const response = await this.request({
            path: `/api/custom/trees`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: EntityWithFileDTOTreeDTOToJSON(requestParameters.entityWithFileDTOTreeDTO),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TreeFromJSON(jsonValue));
    }

    /**
     * Create a new Tree connecting to given Logging.
     * Create a Tree
     */
    async createTreeCustom(requestParameters: CreateTreeCustomRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Tree> {
        const response = await this.createTreeCustomRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Download photo corresponding to Tree identified by given id.
     * Download photo of Tree
     */
    async downloadTreePhotoRaw(requestParameters: DownloadTreePhotoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling downloadTreePhoto.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/custom/trees/{id}/download/photo`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Download photo corresponding to Tree identified by given id.
     * Download photo of Tree
     */
    async downloadTreePhoto(requestParameters: DownloadTreePhotoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.downloadTreePhotoRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a list of all Trees for Company.
     * Get a list of all Trees for Company
     */
    async getAllMyTreesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Tree>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/custom/trees/my/all`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TreeFromJSON));
    }

    /**
     * Get a list of all Trees for Company.
     * Get a list of all Trees for Company
     */
    async getAllMyTrees(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Tree>> {
        const response = await this.getAllMyTreesRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get a list of Trees for Logging with given id (must belong to Company of logged user).
     * Get a list of Trees for Logging
     */
    async getTreesByLoggingRaw(requestParameters: GetTreesByLoggingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<TreeDTO>>> {
        if (requestParameters.loggingId === null || requestParameters.loggingId === undefined) {
            throw new runtime.RequiredError('loggingId','Required parameter requestParameters.loggingId was null or undefined when calling getTreesByLogging.');
        }

        const queryParameters: any = {};

        if (requestParameters.sortBy !== undefined) {
            queryParameters['sortBy'] = requestParameters.sortBy;
        }

        if (requestParameters.direction !== undefined) {
            queryParameters['direction'] = requestParameters.direction;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/custom/trees/logging/{loggingId}`.replace(`{${"loggingId"}}`, encodeURIComponent(String(requestParameters.loggingId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TreeDTOFromJSON));
    }

    /**
     * Get a list of Trees for Logging with given id (must belong to Company of logged user).
     * Get a list of Trees for Logging
     */
    async getTreesByLogging(requestParameters: GetTreesByLoggingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<TreeDTO>> {
        const response = await this.getTreesByLoggingRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Partial update Tree identified by given id (must belong to Logging of logged user).
     * Partial update a Tree
     */
    async updateTreeCustomRaw(requestParameters: UpdateTreeCustomRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Tree>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateTreeCustom.');
        }

        if (requestParameters.entityWithFileDTOTreeDTO === null || requestParameters.entityWithFileDTOTreeDTO === undefined) {
            throw new runtime.RequiredError('entityWithFileDTOTreeDTO','Required parameter requestParameters.entityWithFileDTOTreeDTO was null or undefined when calling updateTreeCustom.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/custom/trees/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: EntityWithFileDTOTreeDTOToJSON(requestParameters.entityWithFileDTOTreeDTO),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TreeFromJSON(jsonValue));
    }

    /**
     * Partial update Tree identified by given id (must belong to Logging of logged user).
     * Partial update a Tree
     */
    async updateTreeCustom(requestParameters: UpdateTreeCustomRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Tree> {
        const response = await this.updateTreeCustomRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const GetTreesByLoggingDirectionEnum = {
    Asc: 'ASC',
    Desc: 'DESC'
} as const;
export type GetTreesByLoggingDirectionEnum = typeof GetTreesByLoggingDirectionEnum[keyof typeof GetTreesByLoggingDirectionEnum];
