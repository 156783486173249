import { customPushTokenResourceApi } from '@services/apis/ApiConfiguration';
import { PushToken, PushTokenOperatingSystemEnum } from '@services/apis/generated';
import * as Device from 'expo-device';
import * as Notifications from 'expo-notifications';
import { Platform } from 'react-native';
import { Logger } from './Logger';
import { PlatformHelper } from './PlatformHelper';

export class NotificationHelper {
  protected static readonly GRANTED = 'granted';

  static registerForPushNotificationsAsync = async () => {
    if (Device.isDevice && !PlatformHelper.isWeb()) {
      const { status: existingStatus } = await Notifications.getPermissionsAsync();
      let finalStatus = existingStatus;
      if (existingStatus !== this.GRANTED) {
        const { status } = await Notifications.requestPermissionsAsync();
        finalStatus = status;
      }
      if (finalStatus !== this.GRANTED) {
        Logger.warn(NotificationHelper.name, 'Failed to get push token for push notification!');
        return;
      }
      const token = (await Notifications.getExpoPushTokenAsync()).data;
      // console.log(token);
      let platform: PushTokenOperatingSystemEnum = PlatformHelper.isIos()
        ? PushTokenOperatingSystemEnum.Ios
        : PushTokenOperatingSystemEnum.Android;
      let pushToken: PushToken = {
        token: token,
        operatingSystem: platform
      };
      customPushTokenResourceApi
        .createMyPushToken({ pushToken: pushToken })
        .then(res => {
          Logger.log(NotificationHelper.name, res);
        })
        .catch(error => Logger.warn(NotificationHelper.name, 'Cannot create push token'));
    } else {
      Logger.warn(NotificationHelper.name, 'Must use physical device for Push Notifications');
    }

    if (Platform.OS === 'android') {
      Notifications.setNotificationChannelAsync('default', {
        name: 'default',
        importance: Notifications.AndroidImportance.MAX,
        vibrationPattern: [0, 250, 250, 250],
        lightColor: '#FF231F7C'
      });
    }
  };
}
