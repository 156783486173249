import UrstammImageBack from '@components/images/corner/UrstammImageBack';
import UrstammImageMenu from '@components/images/corner/UrstammImageMenu';
import UrstammImageNewTree from '@components/images/corner/UrstammImageNewTree';
import BaseLayoutCorner, { BaseLayoutOptions } from '@components/layouts/base/BaseLayoutCorner';
import {
  applySavingToReference,
  deepCopyAndAddSelectedParameter,
  restoreListToReference,
  selectElementFormList
} from '@components/utility-components/modal/UrstammModalSelect';
import UrstammTitle from '@components/utility-components/title/UrstammTitle';
import ProcessorBatchRegistrationView from '@components/views/processor-batch/ProcessorBatchRegistrationView';
import { AlertHelper } from '@helpers/AlertHelper';
import { ErrorHelper } from '@helpers/ErrorHelper';
import { PlatformHelper } from '@helpers/PlatformHelper';
import { i18n } from '@i18n/i18n';
import { useFocusEffect } from '@react-navigation/native';
import { changeLoaderStatus } from '@redux/features/loader/loaderSlice';
import { resetProcessorBatchList } from '@redux/features/processor-batch/processorBatchSlice';
import { setIncomingProcessorShippingNotePage } from '@redux/features/processor-shipping-note/incomingProcessorShippingNoteSlice';
import { RootState } from '@redux/store';
import { customProcessorBatchResourceApi } from '@services/apis/ApiConfiguration';
import {
  ProcessorBatch,
  ProcessorBatchCurrentStateEnum,
  ProcessorBatchDTO,
  ProcessorBatchRecordingTypeEnum,
  ProcessorShippingNoteDTO,
  ProcessorShippingNoteDTOCurrentStateEnum,
  Product
} from '@services/apis/generated';
import React, { useCallback, useState } from 'react';
import { Keyboard } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import { RootStackScreenProps, UrstammNavigationHelper } from '../../navigation/UrstammNavigationHelper';
import { formatPhotoNumber, generateSet, sleep } from '../../utils/classes/UrstammUtilityFunctions';
import { UrstammStyleCornerButton, UrstammStyleHeader, UrstammStyleLayout } from '../../utils/styles/UrstammStyle';

export default function ProcessorBatchRegistrationContainer({
  navigation,
  route
}: RootStackScreenProps<'ProcessorBatchRegistration'>) {
  const rdxProcessorShippingNoteList = useSelector(
    (state: RootState) => state.persistedReducer.incomingProcessorShippingNote.list
  );
  const rdxProcessorShippingNoteCompleteList = useSelector(
    (state: RootState) => state.persistedReducer.incomingProcessorShippingNote.completeList
  );
  const rdxProcessorShippingNotePage = useSelector(
    (state: RootState) => state.persistedReducer.incomingProcessorShippingNote.page
  );
  const rdxProcessorShippingNoteTotalPages = useSelector(
    (state: RootState) => state.persistedReducer.incomingProcessorShippingNote.totalPages
  );
  const rdxProcessorShippingNoteSortBy = useSelector(
    (state: RootState) => state.persistedReducer.incomingProcessorShippingNote.sortBy
  );
  const rdxUserExtendedMe = useSelector((state: RootState) => state.persistedReducer.user.extendedMe);
  const rdxMyCompany = useSelector((state: RootState) => state.persistedReducer.user.myCompany);
  const dispatch = useDispatch();

  const [processorBatchList, setProcessorBatchList] = useStateWithCallbackLazy<ProcessorBatch[]>([]);
  const [processorBatchUniqueIdentifier, setProcessorBatchUniqueIdentifier] = useState<string>('');
  const [resetForm, setResetForm] = useState<boolean>(false);
  const [sourceProductList, setSourceProductList] = useState<any>();
  const [sourceProductListReference, setSourceProductListReference] = useState<any>();
  const [singleTreeRecord, setSingleTreeRecord] = useState<boolean>(false);
  const [dateRecord, setDateRecord] = useState<boolean>(false);

  let cornerOption: BaseLayoutOptions = {
    cornerTopLeft: {
      showCorner: true,
      text: i18n.t('utility_components.corner_button.back'),
      icon: (
        <UrstammImageBack width={PlatformHelper.normalizeByDevice(34)} height={PlatformHelper.normalizeByDevice(24)} />
      )
    },
    cornerTopRight: {
      showCorner: true,
      text: i18n.t('utility_components.corner_button.menu'),
      icon: (
        <UrstammImageMenu width={PlatformHelper.normalizeByDevice(34)} height={PlatformHelper.normalizeByDevice(24)} />
      )
    },
    cornerBottomLeft: {
      showCorner: false,
      text: i18n.t('utility_components.corner_button.sort_by'),
      icon: undefined
    },
    cornerBottomRight: {
      showCorner: false,
      text: undefined,
      icon: (
        <UrstammImageNewTree
          width={PlatformHelper.normalizeByDevice(34)}
          height={PlatformHelper.normalizeByDevice(24)}
        />
      )
    }
  };

  useFocusEffect(
    useCallback(() => {
      setResetForm(false);

      if (route.params?.processorBatchList && route.params.processorBatchList.length > 0) {
        //TODO: Test
        let list: ProcessorShippingNoteDTO[] = JSON.parse(JSON.stringify(rdxProcessorShippingNoteCompleteList));
        list = list.filter(ds => ds.currentState != ProcessorShippingNoteDTOCurrentStateEnum.InProgress);

        setProcessorBatchList(route.params?.processorBatchList, updatedProcessorBatchList => {
          deepCopyAndAddSelectedParameter(list, setSourceProductList, setSourceProductListReference);
        });
      }

      generateProcessorBatchUniqueIdentifier(route.params?.processorBatchList ? route.params?.processorBatchList : []);
      return () => {
        setResetForm(true);
      };
    }, [route.params])
  );

  const saveProcessorBatch = (processorBatch: ProcessorBatchDTO) => {
    let sourceProductsSet: Set<Product> = new Set(generateSet(sourceProductList));

    dispatch(changeLoaderStatus(true));

    let processorBatchObj: ProcessorBatch = {
      name: processorBatch.name!,
      uniqueIdentifier: processorBatch.uniqueIdentifier!,
      creationDate: processorBatch.creationDate,
      currentState: ProcessorBatchCurrentStateEnum.InProgress,
      recordingType: processorBatch.recordingType! as any,
      comment: processorBatch.comment!,
      sourceProducts: sourceProductsSet
    };

    customProcessorBatchResourceApi
      .createProcessorBatchCustom({ processorBatch: processorBatchObj })
      .then((processorBatch: ProcessorBatch) => {
        dispatch(changeLoaderStatus(false));
        if (processorBatch) {
          setResetForm(true);
          dispatch(resetProcessorBatchList());
          UrstammNavigationHelper.navigateToProcessorBatchList(navigation, true);
        }
      })
      .catch(async error => {
        dispatch(changeLoaderStatus(false));
        let errorMessage = await ErrorHelper.getServerMessageFromJsonOrResponse(error);
        let errorJson = await ErrorHelper.getDetailFromServerMessage(errorMessage);
        AlertHelper.showSimpleAlert('Error', errorJson);
      });
  };

  const filterProcessorShippingNoteListByProcessorBatchRecordingType = (
    processorBatchRT: ProcessorBatchRecordingTypeEnum
  ) => {
    /**
     * let sourceProductSelected: Product[] = JSON.parse(JSON.stringify(Array.from(processorBatchDTO.sourceProducts || new Set()))); //Copy Product (sourceProduct) from processorBatchDTO (Set)
    if (sourceProductSelected.length > 0) {
      let copyList = JSON.parse(JSON.stringify(list));
      sourceProductSelected.forEach((sourceProductSel: Product) => {
        copyList = selectSourceProduct(sourceProductSel, copyList, true);
      });
      //if i haven't ProcessorShippingNotes saved in the processorBatch I set the default list with (selected: false)
    } else {
      setSourceProductList(list, updatedSourceProductList => {});
      setSourceProductListReference(list);
    }
     */

    let list = [...rdxProcessorShippingNoteCompleteList];
    //Filtering ProcessorShippingNote by processorBatch recording type
    list = list.filter(
      elem =>
        // same recording type
        elem.outgoingBatch?.recordingType == (processorBatchRT as any) &&
        // is there something
        elem.incomingProducts?.length &&
        elem.incomingProducts?.length > 0 &&
        // is accepted
        elem.currentState == ProcessorShippingNoteDTOCurrentStateEnum.Accepted
    );

    let incomingProducts = list
      .map(
        shippingNoteItem =>
          shippingNoteItem.incomingProducts?.map(p => ({
            ...p,
            _acceptedProcessorShippingNote: shippingNoteItem
          })) || []
      )
      .reduce((prev, next) => [...prev, ...next], [] as Product[]);

    // removes duplicate
    incomingProducts = incomingProducts
      .filter((ip, index, array) => {
        return !array.slice(index + 1).find(p => p.id === ip.id);
      })
      .filter(p => !p.project); // do not show already used products in project

    incomingProducts = incomingProducts.map(val => ({
      ...val,
      selected: false
    }));

    setSourceProductList(incomingProducts);
    setSourceProductListReference(incomingProducts);
  };

  const goBack = async (): Promise<void> => {
    dispatch(resetProcessorBatchList());
    Keyboard.dismiss();
    await sleep(PlatformHelper.isIos() ? 300 : 100);
    UrstammNavigationHelper.navigateToProcessorBatchList(navigation, true);
  };

  /**
   * Prefix ex: U_BRU_001
   * U = Urstamm
   * BRU = prefixIdentifier
   * 001 = counter
   */
  const generateProcessorBatchUniqueIdentifier = (updatedProcessorBatchList: ProcessorBatch[]) => {
    let companyIdentifier = 'U';
    let prefixIdentifier = rdxMyCompany?.prefixIdentifier;
    if (updatedProcessorBatchList.length > 0) {
      let maxId: string = formatPhotoNumber((Number(updatedProcessorBatchList.length) + 1).toString());

      //Finally combining the two variables (string);
      return setProcessorBatchUniqueIdentifier(companyIdentifier + '_' + prefixIdentifier + '_' + maxId);
    }
    //Default value
    return setProcessorBatchUniqueIdentifier(companyIdentifier + '_' + prefixIdentifier + '_' + '001');
  };

  const getMoreDsItems = (): void => {
    if (rdxProcessorShippingNotePage < rdxProcessorShippingNoteTotalPages - 1) {
      dispatch(setIncomingProcessorShippingNotePage(rdxProcessorShippingNotePage + 1));
    }
  };

  const addSingleTreeToIdentifier = (add: string) => {
    // if(add) {
    //   if(!singleTreeRecord){
    //     setProcessorBatchUniqueIdentifier(processorBatchUniqueIdentifier + 'E')
    //   }
    //   setSingleTreeRecord(true);
    // } else {
    //   if(singleTreeRecord){
    //     // Remove last letter
    //     setProcessorBatchUniqueIdentifier(processorBatchUniqueIdentifier.slice(0,-1))
    //   }
    //   setSingleTreeRecord(false);
    // }
    if (add == ProcessorBatchRecordingTypeEnum.Single) {
      if (!singleTreeRecord && !dateRecord) {
        setProcessorBatchUniqueIdentifier(processorBatchUniqueIdentifier + 'E');
      } else if (!singleTreeRecord && dateRecord) {
        setProcessorBatchUniqueIdentifier(processorBatchUniqueIdentifier.slice(0, -1) + 'E');
      } else {
        // console.log('else')
      }
      setSingleTreeRecord(true);
    } else if (add == ProcessorBatchRecordingTypeEnum.Multiple) {
      if (singleTreeRecord || dateRecord) {
        // Remove last letter
        setProcessorBatchUniqueIdentifier(processorBatchUniqueIdentifier.slice(0, -1));
        setSingleTreeRecord(false);
        setDateRecord(false);
      } else {
        // console.log('nothing');
      }
    } else {
      if (singleTreeRecord) {
        setProcessorBatchUniqueIdentifier(processorBatchUniqueIdentifier.slice(0, -1) + 'D');
        setSingleTreeRecord(false);
        setDateRecord(true);
      } else {
        if (!dateRecord) {
          setProcessorBatchUniqueIdentifier(processorBatchUniqueIdentifier + 'D');
        }
        setSingleTreeRecord(false);
        setDateRecord(true);
      }
    }
  };

  return (
    <BaseLayoutCorner
      navigation={navigation}
      cornerOptions={cornerOption}
      layoutStyle={{
        topContainer: UrstammStyleLayout.topContainerCorner,
        bottomContainer: UrstammStyleLayout.baseBottomContainer,
        bottomSubContainer: UrstammStyleLayout.bottomSubContainer40,
        headerContainer: UrstammStyleHeader.headerCenterLogo,
        headerDetails: UrstammStyleHeader.headerDetails,
        cornerBottomLeft: UrstammStyleCornerButton.cornerBottomLeft40,
        cornerBottomRight: UrstammStyleCornerButton.cornerBottomRight40
      }}
      submitTopLeft={goBack}
      title={
        <UrstammTitle
          testID={'processorBatch_title'}
          text={i18n.t('views.processor_batch.processor_batch_registration.new_processor_batch')}
          fontStyle={UrstammStyleHeader.headerTextStyleBlack}
        />
      }
      view={
        <ProcessorBatchRegistrationView
          navigation={navigation}
          uniqueIdentifier={processorBatchUniqueIdentifier}
          submitProcessorBatchForm={(data: ProcessorBatchDTO) => saveProcessorBatch(data)}
          resetProcessorBatchForm={resetForm}
          dsProps={{
            processorShippingNoteList: sourceProductList,
            processorShippingNoteSelected: dsSelected =>
              selectElementFormList(dsSelected, setSourceProductList, sourceProductList),
            moreItems: getMoreDsItems,
            resetProcessorShippingNoteSelected: () =>
              restoreListToReference(setSourceProductList, sourceProductListReference),
            confirmProcessorShippingNoteSelected: () =>
              applySavingToReference(setSourceProductListReference, sourceProductList)
          }}
          userExtendedMe={rdxUserExtendedMe}
          recordingTypeSelected={filterProcessorShippingNoteListByProcessorBatchRecordingType}
          addSingleTreeToIdentifier={addSingleTreeToIdentifier}
        />
      }
    />
  );
}
