import { NumberHelper } from '@helpers/NumberHelper';
import { Trunk, TrunkBarkDiscountTable } from '@services/apis/generated';

const PI = 3.1416;

/**
 * Finds a TrunkBarkDiscountTable by species and diameter of trunk
 * @param trunk Reference trunk
 * @param barkDiscountList List of TrunkBarkDiscountTable
 * @returns The found (or undefined) trunk by species and diameter
 */
export const findBarkDiscountByTrunk = (
  trunk: Trunk,
  barkDiscountList: TrunkBarkDiscountTable[]
): TrunkBarkDiscountTable | undefined => {
  const diameterCm = trunk.diameter;
  let diameterClosest5 = NumberHelper.roundDownTo5(diameterCm);
  // console.log(`Searching bark discount for species ${trunk.species} and diameter ${diameterClosest5}.`);
  let barkFound = barkDiscountList.find(bark => bark.species == trunk.species && bark.diameter == diameterClosest5);
  if (!barkFound) {
    // console.log(`Bark discount not found for species ${trunk.species} and diameter ${diameterClosest5}.`);
    // Search again down
    diameterClosest5 = NumberHelper.roundDownTo5(diameterClosest5 - 1);
    // console.log(`Retrying with species ${trunk.species} and diameter ${diameterClosest5}.`);
    barkFound = barkDiscountList.find(bark => bark.species == trunk.species && bark.diameter == diameterClosest5);
  }
  return barkFound;
};

/**
 * Calculate the trunk volume using bark discount
 * @param trunk Reference trunk
 * @param barkDiscountList List of TrunkBarkDiscountTable
 * @returns The calculated volume of trunk
 */
export const calculateTrunkVolume = (trunk: Trunk, barkDiscountList: TrunkBarkDiscountTable[]): number | undefined => {
  if (!trunk.diameter || !trunk.species || !trunk.length) {
    // console.log(`Cannot calculate volume for trunk ${trunk.id} because diameter or species is null.`);
    return trunk.volume;
  }
  const diameterCm = trunk.diameter;
  const barkDiscount = findBarkDiscountByTrunk(trunk, barkDiscountList);
  if (!barkDiscount) return trunk.volume;
  const rind = barkDiscount.barkDiscountPercentage;
  if (!rind) return trunk.volume;
  // Formula from paper
  return (diameterCm * diameterCm * PI * trunk.length * (100 - rind)) / 4000000;
};
