import { DateHelper } from '@helpers/DateHelper';
import { NetworkHelper } from '@helpers/NetworkHelper';
import { setLoggingList } from '@redux/features/logging/loggingSlice';
import { OfflineSyncClassEnum, OfflineSyncTypeEnum } from '@redux/features/offlineSync/offlineSyncSlice';
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import { AnyAction, Dispatch } from 'redux';
import { sortOfflineList } from '../../utils/classes/UrstammUtilityFunctions';
import { customLoggingResourceApi } from './ApiConfiguration';
import {
  CreateLoggingCustomRequest,
  GetPageLoggingsForMyCompanyRequest,
  GetLoggingCustomRequest,
  Logging,
  LoggingCurrentStateEnum,
  LoggingDTO,
  LoggingPermitMediaTypeEnum,
  PageLogging,
  UpdateLoggingCustomRequest
} from './generated';

export class FullAvailableLoggingResourceApi {
  /**
   * Get all loggings list for company of logged user
   * @param rdxLoggingCompleteList Redux list of Logging
   * @param requestParameters Request parameters for call
   * @param initOverrides Init overrides parameter for call
   * @returns Updated list of logging or redux list if network is not reachable
   */
  static async getPageLoggingsForMyCompany(
    rdxLoggingCompleteList: LoggingDTO[],
    requestParameters: GetPageLoggingsForMyCompanyRequest,
    initOverrides?: RequestInit
  ): Promise<PageLogging> {
    let networkOk = await NetworkHelper.isNetworkOk();
    if (networkOk) return customLoggingResourceApi.getPageLoggingsForMyCompany(requestParameters, initOverrides);
    let pageLogging: PageLogging = {
      totalElements: rdxLoggingCompleteList.length,
      content: rdxLoggingCompleteList.map(dto => {
        return {
          id: dto.id,
          name: dto.name!,
          comment: dto.comment,
          creationDate: dto.creationDate,
          sumCubage: dto.sumCubage,
          sumWoodOtherAssortment: dto.sumWoodOtherAssortment,
          sumWoodTotal: dto.sumWoodTotal,
          currentState: dto.currentState != null ? LoggingCurrentStateEnum[dto.currentState!.toString()] : undefined,
          permitMediaType:
            dto.permitMediaType != null ? LoggingPermitMediaTypeEnum[dto.permitMediaType!.toString()] : undefined,
          permitFilename: dto.permitFilename,
          permitFilepath: dto.permitFilepath,
          permitUrl: dto.permitUrl,
          permitMd5: dto.permitMd5,
          uuid: dto.uuid
        };
      }),
      totalPages: 1
    };
    pageLogging.content = sortOfflineList(pageLogging.content, requestParameters);
    return pageLogging;
  }

  /**
   * Get logging detail by id
   * @param rdxLoggingCompleteList Redux list of Logging
   * @param requestParameters Request parameters for call
   * @param initOverrides Init overrides parameter for call
   * @returns Updated logging or redux object if network is not reachable
   */
  static async getLoggingCustom(
    rdxLoggingCompleteList: LoggingDTO[],
    requestParameters: GetLoggingCustomRequest,
    initOverrides?: RequestInit
  ): Promise<LoggingDTO> {
    let networkOk = await NetworkHelper.isNetworkOk();
    if (networkOk) return customLoggingResourceApi.getLoggingCustom(requestParameters, initOverrides);
    return rdxLoggingCompleteList.find(logging => logging.id == requestParameters.id) || {};
  }

  /**
   * Create a Logging
   * @param rdxOfflineSyncList List of offline objects to sync
   * @param dispatch Dispatch function for react
   * @param addOfflineObjectSync Function to dispatch
   * @param requestParameters Request parameters for call
   * @param initOverrides Init overrides parameter for call
   * @returns The promise of Http call or object if network is not reachable
   */
  static async createLoggingCustom(
    rdxOfflineSyncList,
    dispatch: Dispatch<AnyAction>,
    addOfflineObjectSync: ActionCreatorWithPayload<any, string>,
    requestParameters: CreateLoggingCustomRequest,
    initOverrides?: RequestInit
  ) {
    let networkOk = await NetworkHelper.isNetworkOk();
    if (networkOk && rdxOfflineSyncList.length == 0)
      return customLoggingResourceApi.createLoggingCustom(requestParameters, initOverrides);
    requestParameters.entityWithFileDTOLogging.entity!.id = DateHelper.nowTimestamp();
    dispatch(
      addOfflineObjectSync({
        entity: requestParameters.entityWithFileDTOLogging,
        type: OfflineSyncTypeEnum.INSERT,
        class: OfflineSyncClassEnum.LOGGING,
        name: requestParameters.entityWithFileDTOLogging.entity?.name
      })
    );
    dispatch(setLoggingList([requestParameters.entityWithFileDTOLogging.entity]));
    return requestParameters.entityWithFileDTOLogging.entity;
  }

  /**
   * Update a Logging
   * @param rdxOfflineSyncList List of offline objects to sync
   * @param dispatch Dispatch function for react
   * @param addOfflineObjectSync Function to dispatch
   * @param requestParameters Request parameters for call
   * @param initOverrides Init overrides parameter for call
   * @returns The promise of Http call or object if network is not reachable
   */
  static async updateLoggingCustom(
    rdxOfflineSyncList,
    dispatch: Dispatch<AnyAction>,
    addOfflineObjectSync: ActionCreatorWithPayload<any, string>,
    requestParameters: UpdateLoggingCustomRequest,
    initOverrides?: RequestInit
  ): Promise<Logging> {
    let networkOk = await NetworkHelper.isNetworkOk();
    if (networkOk && rdxOfflineSyncList.length == 0)
      return customLoggingResourceApi.updateLoggingCustom(requestParameters, initOverrides);
    dispatch(
      addOfflineObjectSync({
        entity: requestParameters.entityWithFileDTOLogging,
        type: OfflineSyncTypeEnum.UPDATE,
        class: OfflineSyncClassEnum.LOGGING,
        name: requestParameters.entityWithFileDTOLogging.entity?.name
      })
    );
    dispatch(setLoggingList([requestParameters.entityWithFileDTOLogging.entity]));
    return requestParameters.entityWithFileDTOLogging.entity!;
  }
}
