/* tslint:disable */
/* eslint-disable */
/**
 * Urstamm API
 * Urstamm API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Batch } from './Batch';
import {
    BatchFromJSON,
    BatchFromJSONTyped,
    BatchToJSON,
} from './Batch';
import type { Company } from './Company';
import {
    CompanyFromJSON,
    CompanyFromJSONTyped,
    CompanyToJSON,
} from './Company';
import type { DeliverySheetErpImportation } from './DeliverySheetErpImportation';
import {
    DeliverySheetErpImportationFromJSON,
    DeliverySheetErpImportationFromJSONTyped,
    DeliverySheetErpImportationToJSON,
} from './DeliverySheetErpImportation';
import type { DeliverySheetPhoto } from './DeliverySheetPhoto';
import {
    DeliverySheetPhotoFromJSON,
    DeliverySheetPhotoFromJSONTyped,
    DeliverySheetPhotoToJSON,
} from './DeliverySheetPhoto';
import type { DeliverySheetState } from './DeliverySheetState';
import {
    DeliverySheetStateFromJSON,
    DeliverySheetStateFromJSONTyped,
    DeliverySheetStateToJSON,
} from './DeliverySheetState';
import type { Packet } from './Packet';
import {
    PacketFromJSON,
    PacketFromJSONTyped,
    PacketToJSON,
} from './Packet';
import type { Pile } from './Pile';
import {
    PileFromJSON,
    PileFromJSONTyped,
    PileToJSON,
} from './Pile';
import type { Trunk } from './Trunk';
import {
    TrunkFromJSON,
    TrunkFromJSONTyped,
    TrunkToJSON,
} from './Trunk';

/**
 * 
 * @export
 * @interface DeliverySheet
 */
export interface DeliverySheet {
    /**
     * 
     * @type {number}
     * @memberof DeliverySheet
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof DeliverySheet
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof DeliverySheet
     */
    comment?: string;
    /**
     * 
     * @type {Date}
     * @memberof DeliverySheet
     */
    creationDate?: Date;
    /**
     * 
     * @type {number}
     * @memberof DeliverySheet
     */
    sumCubage?: number;
    /**
     * 
     * @type {boolean}
     * @memberof DeliverySheet
     */
    sentToSawmill?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DeliverySheet
     */
    currentState?: DeliverySheetCurrentStateEnum;
    /**
     * 
     * @type {string}
     * @memberof DeliverySheet
     */
    currentUrstammState?: DeliverySheetCurrentUrstammStateEnum;
    /**
     * 
     * @type {string}
     * @memberof DeliverySheet
     */
    recordingType?: DeliverySheetRecordingTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof DeliverySheet
     */
    automaticallyCreated?: boolean;
    /**
     * 
     * @type {number}
     * @memberof DeliverySheet
     */
    amountOfTruckJourneys?: number;
    /**
     * 
     * @type {string}
     * @memberof DeliverySheet
     */
    uuid?: string;
    /**
     * 
     * @type {Pile}
     * @memberof DeliverySheet
     */
    pile?: Pile;
    /**
     * 
     * @type {Company}
     * @memberof DeliverySheet
     */
    forestry?: Company;
    /**
     * 
     * @type {Company}
     * @memberof DeliverySheet
     */
    sawmill?: Company;
    /**
     * 
     * @type {Set<DeliverySheetErpImportation>}
     * @memberof DeliverySheet
     */
    deliverySheetErpImportations?: Set<DeliverySheetErpImportation>;
    /**
     * 
     * @type {Set<DeliverySheetPhoto>}
     * @memberof DeliverySheet
     */
    deliverySheetPhotos?: Set<DeliverySheetPhoto>;
    /**
     * 
     * @type {Set<DeliverySheetState>}
     * @memberof DeliverySheet
     */
    deliverySheetStates?: Set<DeliverySheetState>;
    /**
     * 
     * @type {Set<Trunk>}
     * @memberof DeliverySheet
     */
    trunks?: Set<Trunk>;
    /**
     * 
     * @type {Set<Batch>}
     * @memberof DeliverySheet
     */
    batches?: Set<Batch>;
    /**
     * 
     * @type {Set<Packet>}
     * @memberof DeliverySheet
     */
    packets?: Set<Packet>;
}


/**
 * @export
 */
export const DeliverySheetCurrentStateEnum = {
    InProgressByForestry: 'IN_PROGRESS_BY_FORESTRY',
    InProgressBySawmill: 'IN_PROGRESS_BY_SAWMILL',
    EditedBySawmill: 'EDITED_BY_SAWMILL',
    EditedByForestry: 'EDITED_BY_FORESTRY',
    Valid: 'VALID',
    Closed: 'CLOSED'
} as const;
export type DeliverySheetCurrentStateEnum = typeof DeliverySheetCurrentStateEnum[keyof typeof DeliverySheetCurrentStateEnum];

/**
 * @export
 */
export const DeliverySheetCurrentUrstammStateEnum = {
    InProgress: 'IN_PROGRESS',
    Delivered: 'DELIVERED',
    Validated: 'VALIDATED',
    Closed: 'CLOSED'
} as const;
export type DeliverySheetCurrentUrstammStateEnum = typeof DeliverySheetCurrentUrstammStateEnum[keyof typeof DeliverySheetCurrentUrstammStateEnum];

/**
 * @export
 */
export const DeliverySheetRecordingTypeEnum = {
    Single: 'SINGLE',
    Multiple: 'MULTIPLE',
    Date: 'DATE',
} as const;
export type DeliverySheetRecordingTypeEnum = typeof DeliverySheetRecordingTypeEnum[keyof typeof DeliverySheetRecordingTypeEnum];


/**
 * Check if a given object implements the DeliverySheet interface.
 */
export function instanceOfDeliverySheet(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function DeliverySheetFromJSON(json: any): DeliverySheet {
    return DeliverySheetFromJSONTyped(json, false);
}

export function DeliverySheetFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeliverySheet {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'comment': !exists(json, 'comment') ? undefined : json['comment'],
        'creationDate': !exists(json, 'creationDate') ? undefined : (new Date(json['creationDate'])),
        'sumCubage': !exists(json, 'sumCubage') ? undefined : json['sumCubage'],
        'sentToSawmill': !exists(json, 'sentToSawmill') ? undefined : json['sentToSawmill'],
        'currentState': !exists(json, 'currentState') ? undefined : json['currentState'],
        'currentUrstammState': !exists(json, 'currentUrstammState') ? undefined : json['currentUrstammState'],
        'recordingType': !exists(json, 'recordingType') ? undefined : json['recordingType'],
        'automaticallyCreated': !exists(json, 'automaticallyCreated') ? undefined : json['automaticallyCreated'],
        'amountOfTruckJourneys': !exists(json, 'amountOfTruckJourneys') ? undefined : json['amountOfTruckJourneys'],
        'uuid': !exists(json, 'uuid') ? undefined : json['uuid'],
        'pile': !exists(json, 'pile') ? undefined : PileFromJSON(json['pile']),
        'forestry': !exists(json, 'forestry') ? undefined : CompanyFromJSON(json['forestry']),
        'sawmill': !exists(json, 'sawmill') ? undefined : CompanyFromJSON(json['sawmill']),
        'deliverySheetErpImportations': !exists(json, 'deliverySheetErpImportations') ? undefined : (new Set((json['deliverySheetErpImportations'] as Array<any>).map(DeliverySheetErpImportationFromJSON))),
        'deliverySheetPhotos': !exists(json, 'deliverySheetPhotos') ? undefined : (new Set((json['deliverySheetPhotos'] as Array<any>).map(DeliverySheetPhotoFromJSON))),
        'deliverySheetStates': !exists(json, 'deliverySheetStates') ? undefined : (new Set((json['deliverySheetStates'] as Array<any>).map(DeliverySheetStateFromJSON))),
        'trunks': !exists(json, 'trunks') ? undefined : (new Set((json['trunks'] as Array<any>).map(TrunkFromJSON))),
        'batches': !exists(json, 'batches') ? undefined : (new Set((json['batches'] as Array<any>).map(BatchFromJSON))),
        'packets': !exists(json, 'packets') ? undefined : (new Set((json['packets'] as Array<any>).map(PacketFromJSON))),
    };
}

export function DeliverySheetToJSON(value?: DeliverySheet | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'comment': value.comment,
        'creationDate': value.creationDate === undefined ? undefined : (value.creationDate.toISOString()),
        'sumCubage': value.sumCubage,
        'sentToSawmill': value.sentToSawmill,
        'currentState': value.currentState,
        'currentUrstammState': value.currentUrstammState,
        'recordingType': value.recordingType,
        'automaticallyCreated': value.automaticallyCreated,
        'amountOfTruckJourneys': value.amountOfTruckJourneys,
        'uuid': value.uuid,
        'pile': PileToJSON(value.pile),
        'forestry': CompanyToJSON(value.forestry),
        'sawmill': CompanyToJSON(value.sawmill),
        'deliverySheetErpImportations': value.deliverySheetErpImportations === undefined ? undefined : (Array.from(value.deliverySheetErpImportations as Set<any>).map(DeliverySheetErpImportationToJSON)),
        'deliverySheetPhotos': value.deliverySheetPhotos === undefined ? undefined : (Array.from(value.deliverySheetPhotos as Set<any>).map(DeliverySheetPhotoToJSON)),
        'deliverySheetStates': value.deliverySheetStates === undefined ? undefined : (Array.from(value.deliverySheetStates as Set<any>).map(DeliverySheetStateToJSON)),
        'trunks': value.trunks === undefined ? undefined : (Array.from(value.trunks as Set<any>).map(TrunkToJSON)),
        'batches': value.batches === undefined ? undefined : (Array.from(value.batches as Set<any>).map(BatchToJSON)),
        'packets': value.packets === undefined ? undefined : (Array.from(value.packets as Set<any>).map(PacketToJSON)),
    };
}

