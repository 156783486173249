/* tslint:disable */
/* eslint-disable */
/**
 * Urstamm API
 * Urstamm API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type {
    EntityWithFileDTOPile,
    Pile,
} from '../models';
import {
    EntityWithFileDTOPileToJSON,
    PileFromJSON
} from '../models';
import * as runtime from '../runtime';

export interface CreatePileCustomRequest {
    entityWithFileDTOPile: EntityWithFileDTOPile;
}

export interface DownloadPilePhotoRequest {
    id: number;
}

export interface GetPilesByLoggingRequest {
    loggingId: number;
    sortBy?: string;
    direction?: GetPilesByLoggingDirectionEnum;
}

export interface UpdatePileCustomRequest {
    id: number;
    entityWithFileDTOPile: EntityWithFileDTOPile;
}

/**
 * 
 */
export class CustomPileResourceApi extends runtime.BaseAPI {

    /**
     * Create a new Pile connecting to given Logging.
     * Create a Pile
     */
    async createPileCustomRaw(requestParameters: CreatePileCustomRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Pile>> {
        if (requestParameters.entityWithFileDTOPile === null || requestParameters.entityWithFileDTOPile === undefined) {
            throw new runtime.RequiredError('entityWithFileDTOPile','Required parameter requestParameters.entityWithFileDTOPile was null or undefined when calling createPileCustom.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        // console.log('new pile....', requestParameters.entityWithFileDTOPile.entity);

        const response = await this.request({
            path: `/api/custom/piles`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: EntityWithFileDTOPileToJSON(requestParameters.entityWithFileDTOPile),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PileFromJSON(jsonValue));
    }

    /**
     * Create a new Pile connecting to given Logging.
     * Create a Pile
     */
    async createPileCustom(requestParameters: CreatePileCustomRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Pile> {
        const response = await this.createPileCustomRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Download photo corresponding to Pile identified by given id.
     * Download photo of Pile
     */
    async downloadPilePhotoRaw(requestParameters: DownloadPilePhotoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling downloadPilePhoto.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/custom/piles/{id}/download/photo`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Download photo corresponding to Pile identified by given id.
     * Download photo of Pile
     */
    async downloadPilePhoto(requestParameters: DownloadPilePhotoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.downloadPilePhotoRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a list of all Piles for Company.
     * Get a list of all Piles for Company
     */
    async getAllMyPilesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Pile>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/custom/piles/my/all`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PileFromJSON));
    }

    /**
     * Get a list of all Piles for Company.
     * Get a list of all Piles for Company
     */
    async getAllMyPiles(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Pile>> {
        const response = await this.getAllMyPilesRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get a list of Piles for Logging with given id (must belong to Company of logged user).
     * Get a list of Piles for Logging
     */
    async getPilesByLoggingRaw(requestParameters: GetPilesByLoggingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Pile>>> {
        if (requestParameters.loggingId === null || requestParameters.loggingId === undefined) {
            throw new runtime.RequiredError('loggingId','Required parameter requestParameters.loggingId was null or undefined when calling getPilesByLogging.');
        }

        const queryParameters: any = {};

        if (requestParameters.sortBy !== undefined) {
            queryParameters['sortBy'] = requestParameters.sortBy;
        }

        if (requestParameters.direction !== undefined) {
            queryParameters['direction'] = requestParameters.direction;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/custom/piles/logging/{loggingId}`.replace(`{${"loggingId"}}`, encodeURIComponent(String(requestParameters.loggingId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PileFromJSON));
    }

    /**
     * Get a list of Piles for Logging with given id (must belong to Company of logged user).
     * Get a list of Piles for Logging
     */
    async getPilesByLogging(requestParameters: GetPilesByLoggingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Pile>> {
        const response = await this.getPilesByLoggingRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Partial update Pile identified by given id (must belong to Logging of logged user).
     * Partial update a Pile
     */
    async updatePileCustomRaw(requestParameters: UpdatePileCustomRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Pile>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updatePileCustom.');
        }

        if (requestParameters.entityWithFileDTOPile === null || requestParameters.entityWithFileDTOPile === undefined) {
            throw new runtime.RequiredError('entityWithFileDTOPile','Required parameter requestParameters.entityWithFileDTOPile was null or undefined when calling updatePileCustom.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/custom/piles/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: EntityWithFileDTOPileToJSON(requestParameters.entityWithFileDTOPile),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PileFromJSON(jsonValue));
    }

    /**
     * Partial update Pile identified by given id (must belong to Logging of logged user).
     * Partial update a Pile
     */
    async updatePileCustom(requestParameters: UpdatePileCustomRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Pile> {
        const response = await this.updatePileCustomRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const GetPilesByLoggingDirectionEnum = {
    Asc: 'ASC',
    Desc: 'DESC'
} as const;
export type GetPilesByLoggingDirectionEnum = typeof GetPilesByLoggingDirectionEnum[keyof typeof GetPilesByLoggingDirectionEnum];
