import { environment } from '@environments/environment';
import { getNetworkStateAsync } from 'expo-network';
import { AlertHelper } from './AlertHelper';

export class NetworkHelper {
  /**
   * Checks if current network connection is ok
   * @returns {Promise<boolean>}
   */
  public static async isNetworkOk(quiet = true): Promise<boolean> {
    let networkState = await getNetworkStateAsync();
    // console.log('Network Status', networkState);
    let offline = !networkState.isConnected || !networkState.isInternetReachable || environment.debugForceOffline;
    let connectedAndReachable = networkState.isConnected == true && networkState.isInternetReachable == true;
    let online = connectedAndReachable && !offline;
    if (!online && !quiet) AlertHelper.showSimpleAlert('Error', 'please_check_your_internet_connection');
    return online;
  }
}
