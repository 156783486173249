import UrstammImageLogo from '@components/images/UrstammImageLogo';
import { RootState } from '@redux/store';
import { BlurView } from 'expo-blur';
import React, { Component, useEffect } from 'react';
import { ActivityIndicator, Modal, StyleSheet, View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import Layout from '../config/Layout';
import { environment } from '../environments/environment';
import { PlatformHelper } from '../helpers/PlatformHelper';
import { ColorTheme } from '../utils/styles/ColorTheme';
import { Logger } from './Logger';

export function Loader(props: any) {
  // TODO: UNUSED
  const rdxLoaderStatus = useSelector((state: RootState) => state.persistedReducer.loader.enabled);

  const rdxLoaderForSyncStatus = useSelector((state: RootState) => state.persistedReducer.loader.enabledForSync);
  const dispatch = useDispatch();

  useEffect(() => {}, [rdxLoaderStatus, rdxLoaderForSyncStatus]);

  if (!PlatformHelper.isWeb())
    return (
      <View>
        <Modal
          transparent={true}
          animationType={'fade'}
          visible={rdxLoaderForSyncStatus && !environment.disableLoader}
          onRequestClose={() => {
            // console.log('close modal');
          }}>
          <BlurView style={styles.modalBackground} intensity={60}>
            <View style={styles.activityIndicatorWrapper}>
              <UrstammImageLogo width={220} height={90} showText={true} />
              <ActivityIndicator
                animating={rdxLoaderForSyncStatus && !environment.disableLoader}
                size={'large'}
                color={ColorTheme.lightGreen}
              />
            </View>
          </BlurView>
        </Modal>
      </View>
    );
  else if (rdxLoaderForSyncStatus && !environment.disableLoader)
    return (
      <View style={styles.webActivityIndicatorWrapper}>
        <UrstammImageLogo width={220} height={90} showText={true} />
        <ActivityIndicator
          size={'large'}
          color={ColorTheme.mainGreen}
          animating={rdxLoaderForSyncStatus && !environment.disableLoader}
        />
      </View>
    );
  else return null;
}

/**
 * State interface with loading property
 */
export interface LoaderState {
  loading: boolean;
  showNoData?: boolean;
  refreshing?: boolean;
}

/**
 * Update state with loading true so the loader start
 * @param {React.Component<any, LoaderState>} context
 */
export function startLoader(context: Component<any, LoaderState>) {
  Logger.log(context.constructor.name, 'Starting loader');
  if (environment.disableLoader) return;
  context.setState({ loading: true, refreshing: true });
}

/**
 * Update state with loading false so the loader stop
 * @param {React.Component<any, LoaderState>} context
 */
export function stopLoader(context: Component<any, LoaderState>) {
  Logger.log(context.constructor.name, 'Stopping loader');
  if (environment.disableLoader) return;
  context.setState({ loading: false, refreshing: false });
}

const styles = StyleSheet.create({
  modalBackground: {
    flex: 1,
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    opacity: 1
  },
  activityIndicatorWrapper: {
    backgroundColor: 'transparent',
    height: 100,
    width: 100,
    borderRadius: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around'
  },
  webActivityIndicatorWrapper: {
    zIndex: 99,
    backgroundColor: '#00000040',
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    height: Layout.window.height - 10, //Header.HEIGHT,
    width: Layout.window.width,
    alignItems: 'center',
    justifyContent: 'center'
  }
});

export default Loader;
