import UrstammImageBack from '@components/images/corner/UrstammImageBack';
import UrstammImageMenu from '@components/images/corner/UrstammImageMenu';
import UrstammImagePlus from '@components/images/corner/UrstammImagePlus';
import BaseLayoutCorner, { BaseLayoutOptions } from '@components/layouts/base/BaseLayoutCorner';
import { ButtonSelect } from '@components/utility-components/button/UrstammButtonSelect';
import {
  applySavingToReference,
  restoreListToReference,
  selectElementFormList,
  selectSlideElementFormList
} from '@components/utility-components/modal/UrstammModalSelect';
import UrstammTitle from '@components/utility-components/title/UrstammTitle';
import ProductRegistrationView, { ProductForm } from '@components/views/product/ProductRegistrationView';
import { AlertHelper } from '@helpers/AlertHelper';
import { ErrorHelper } from '@helpers/ErrorHelper';
import { PlatformHelper } from '@helpers/PlatformHelper';
import { i18n } from '@i18n/i18n';
import { resetProcessorBatchList } from '@redux/features/processor-batch/processorBatchSlice';
import { changeLoaderStatus } from '@redux/features/loader/loaderSlice';
import { resetProductList } from '@redux/features/product/productSlice';
import {
  resetCompanyProductTypeList,
  setCompanyProductTypeList
} from '@redux/features/product-type/companyProductTypeSlice';
import { resetCompanySurfaceList, setCompanySurfaceList } from '@redux/features/surface/companySurfaceSlice';
import { resetCompanyAppearanceList, setCompanyAppearanceList } from '@redux/features/appearance/companyAppearanceSlice';
import { RootState } from '@redux/store';
import {
  customCompanyProductTypeResourceApi,
  customCompanyResourceApi,
  customCompanySurfaceResourceApi,
  customCompanyAppearanceResourceApi,
  customProductResourceApi,
  longListSize
} from '@services/apis/ApiConfiguration';
import {
  ProcessorBatchDTO,
  Company,
  CompanyProductType,
  CompanySurface,
  Product,
  ProductCertification,  
  ProductCertificationCertificationEnum,
  ProductCurrentStateEnum,
  PageCompany,
  ProductSpecies,
  ProductSpeciesSpeciesEnum,
  CompanyAppearance,
  ProductDTO
} from '@services/apis/generated';
import React, { useEffect, useState } from 'react';
import { Keyboard } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import { RootStackScreenProps, UrstammNavigationHelper } from '../../navigation/UrstammNavigationHelper';
import { getTextByTreeOrTrunkSpecies } from '../../utils/classes/UrstammUtilityCurrentState';
import {
  formatPhotoNumber,
  generateSetForCertification,
  generateSetForProductSpecies,
  sleep
} from '../../utils/classes/UrstammUtilityFunctions';
import { UrstammStyleCornerButton, UrstammStyleHeader, UrstammStyleLayout } from '../../utils/styles/UrstammStyle';

export default function ProductRegistrationContainer({ route, navigation }: RootStackScreenProps<'ProductRegistration'>) {
  //Redux
  const rdxCompanyProductTypeList = useSelector((state: RootState) => state.persistedReducer.companyProductType.list);
  const rdxCompanyProductTypeCompleteList = useSelector(
    (state: RootState) => state.persistedReducer.companyProductType.completeList
  );

  const rdxCompanySurfaceList = useSelector((state: RootState) => state.persistedReducer.companySurface.list);
  const rdxCompanySurfaceCompleteList = useSelector(
    (state: RootState) => state.persistedReducer.companySurface.completeList
  );

  const rdxCompanyAppearanceList = useSelector((state: RootState) => state.persistedReducer.companyAppearance.list);
  const rdxCompanyAppearanceCompleteList = useSelector(
    (state: RootState) => state.persistedReducer.companyAppearance.completeList
  );

  
  const dispatch = useDispatch();

  const [productUniqueIdentifier, setProductUniqueIdentifier] = useState<string>('');
  const [processorBatchSelected, setProcessorBatchSelected] = useStateWithCallbackLazy<ProcessorBatchDTO>({});

  const [certificationList, setCertificationList] = useStateWithCallbackLazy<any[]>([]);
  const [certificationListReference, setCertificationListReference] = useStateWithCallbackLazy<any[]>([]);

  const [productSpeciesList, setProductSpeciesList ] = useStateWithCallbackLazy<any[]>([]);
  const [productSpeciesListReference, setProductSpeciesListReference] = useStateWithCallbackLazy<any[]>([]);

  const [processorList, setProcessorList] = useState<Company[]>([]);

  let cornerOption: BaseLayoutOptions = {
    cornerTopLeft: {
      showCorner: true,
      text: i18n.t('utility_components.corner_button.back'),
      icon: (
        <UrstammImageBack width={PlatformHelper.normalizeByDevice(34)} height={PlatformHelper.normalizeByDevice(24)} />
      )
    },
    cornerTopRight: {
      showCorner: true,
      text: i18n.t('utility_components.corner_button.menu'),
      icon: (
        <UrstammImageMenu width={PlatformHelper.normalizeByDevice(34)} height={PlatformHelper.normalizeByDevice(24)} />
      )
    },
    cornerBottomLeft: {
      showCorner: false,
      text: i18n.t('utility_components.corner_button.sort_by'),
      icon: undefined
    },
    cornerBottomRight: {
      showCorner: false,
      text: i18n.t('generics.product'),
      icon: (
        <UrstammImagePlus width={PlatformHelper.normalizeByDevice(34)} height={PlatformHelper.normalizeByDevice(24)} />
      )
    }
  };

  useEffect(() => {
    if (route.params?.processorBatchSelected) {
      setProcessorBatchSelected(route.params?.processorBatchSelected, processorBatchUpdated => generateProductUniqueIdentifier(processorBatchUpdated));
      getAllCompanyProcessorConnectedToMe();
      getCompanyProductType();
      getCompanySurfaceResourceApi();
      getCompanyAppearanceResourceApi();
      getAllProductCertifications(route.params?.cloneFromProduct);
      getAllProductSpecies(route.params?.cloneFromProduct);
    }
  }, [route.params?.processorBatchSelected]);

  const goBack = async (): Promise<void> => {
    dispatch(resetProcessorBatchList());
    Keyboard.dismiss();
    await sleep(PlatformHelper.isIos() ? 300 : 100);
    UrstammNavigationHelper.navigateToProcessorBatchDetails(navigation, processorBatchSelected!);
  };

  /**
   * Setting processor list, it used for "Registration" and "Detail" Pages.
   * @param order
   */
  const getAllCompanyProcessorConnectedToMe = (order?: ButtonSelect[]) => {
    let sortBy = order && order.length > 0 ? [order[0].sort?.sortBy + ',' + order[0].sort?.direction] : ['name,desc'];
    dispatch(changeLoaderStatus(true));

    customCompanyResourceApi
      .getCompaniesConnectedToMe({
        sort: sortBy,
        customCompanyCriteria: {},
        page: 0,
        size: longListSize
      })
      .then((list: PageCompany) => {
        dispatch(changeLoaderStatus(false));

        if (list && list.content?.length) {
          list.content.push({ name: i18n.t('views.product.no_urstamm_processor'), id: -200 });
          setProcessorList(list.content);
        }
      })
      .catch(async error => {
        dispatch(changeLoaderStatus(false));
        let errorMessage = await ErrorHelper.getServerMessageFromJson(error);
        let errorJson = await ErrorHelper.getDetailFromServerMessage(errorMessage);
        AlertHelper.showSimpleAlert('Error', errorJson);
      });
  };

  const submitProduct = (data: ProductForm) => {
    dispatch(changeLoaderStatus(true));

    let certificationSet: Set<ProductCertification> = new Set(generateSetForCertification(certificationList));
    let speciesSet: Set<ProductSpecies> = generateSetForProductSpecies(productSpeciesList);

    let productObj: Product = {
      processorBatch: processorBatchSelected as any,
      currentState: ProductCurrentStateEnum.InProgress,
      uniqueIdentifier: data.product.uniqueIdentifier!,
      name: data.product.name!,
      creationDate: data.product.creationDate,
      comment: data.product.comment,
      processor: data.product.processorCustomName ? undefined : data.product.processor,
      processorCustomName:
        data.product.processorCustomName && data.product.processorCustomName.length > 0
          ? data.product.processorCustomName
          : undefined,
      sumCubage: data.product.sumCubage,
      productCertifications: certificationSet,
      productSpecies: speciesSet,
      productType: data.product.productType,
      surface: data.product.surface,
      appearance: data.product.appearance,
    };

    customProductResourceApi
      .createProductCustom({ entityWithFileDTOProduct: { entity: productObj } })
      .then(async (product: Product) => {
        dispatch(changeLoaderStatus(false));

        if (product) {
          dispatch(resetProductList());
          Keyboard.dismiss();
          await sleep(PlatformHelper.isIos() ? 300 : 100);
          UrstammNavigationHelper.navigateToProcessorBatchDetails(navigation, processorBatchSelected, true);
        }
      })
      .catch(async error => {
        dispatch(changeLoaderStatus(false));
        let errorMessage = await ErrorHelper.getServerMessageFromJsonOrResponse(error);
        let errorJson = await ErrorHelper.getDetailFromServerMessage(errorMessage);
        AlertHelper.showSimpleAlert('Error', errorJson);
      });
  };

  const getCompanyProductType = () => {
    dispatch(changeLoaderStatus(true));

    customCompanyProductTypeResourceApi
      .getAllCompanyProductTypesForCompany({})
      .then((productTypeList: CompanyProductType[]) => {
        dispatch(changeLoaderStatus(false));

        if (productTypeList) {
          dispatch(resetCompanyProductTypeList());
          dispatch(setCompanyProductTypeList(productTypeList));
        }
      })
      .catch(async error => {
        dispatch(changeLoaderStatus(false));
        let errorMessage = await ErrorHelper.getServerMessageFromJson(error);
        let errorJson = await ErrorHelper.getDetailFromServerMessage(errorMessage);
        AlertHelper.showSimpleAlert('Error', errorJson);
      });
  };

  const getCompanySurfaceResourceApi = () => {
    dispatch(changeLoaderStatus(true));

    customCompanySurfaceResourceApi
      .getAllCompanySurfacesForCompany({})
      .then((surfaceList: CompanySurface[]) => {
        dispatch(changeLoaderStatus(false));

        if (surfaceList) {
          dispatch(resetCompanySurfaceList());
          dispatch(setCompanySurfaceList(surfaceList));
        }
      })
      .catch(async error => {
        dispatch(changeLoaderStatus(false));
        let errorMessage = await ErrorHelper.getServerMessageFromJson(error);
        let errorJson = await ErrorHelper.getDetailFromServerMessage(errorMessage);
        AlertHelper.showSimpleAlert('Error', errorJson);
      });
  };

  const getCompanyAppearanceResourceApi = () => {
    dispatch(changeLoaderStatus(true));

    customCompanyAppearanceResourceApi
      .getAllCompanyAppearancesForCompany({})
      .then((appearanceList: CompanyAppearance[]) => {
        dispatch(changeLoaderStatus(false));

        if (appearanceList) {
          dispatch(resetCompanyAppearanceList());
          dispatch(setCompanyAppearanceList(appearanceList));
        }
      })
      .catch(async error => {
        dispatch(changeLoaderStatus(false));
        let errorMessage = await ErrorHelper.getServerMessageFromJson(error);
        let errorJson = await ErrorHelper.getDetailFromServerMessage(errorMessage);
        AlertHelper.showSimpleAlert('Error', errorJson);
      });
  };

  const getAllProductCertifications = (cloneFromProduct?: Product | ProductDTO) => {
    let filterList: any[] = [];
    Object.values(ProductCertificationCertificationEnum).map(state => {
      let type: any = {
        id: state,
        name: state,
        selected: state == ProductCertificationCertificationEnum.Urstamm,
        disabled: state == ProductCertificationCertificationEnum.Urstamm
      };
      filterList.push(type);
    });

    let productCertification = cloneFromProduct?.productCertifications ? [...cloneFromProduct?.productCertifications] : [];
    if (productCertification && productCertification.length > 0) {
      productCertification.forEach(elemSelected => {
        for (let val of filterList) {
          if (val.id == elemSelected.certification) {
            val.selected = true;
            break;
          }
        }
      });
    }
    setCertificationList(filterList, () => {});
    setCertificationListReference(filterList, () => {});
  };

  const getAllProductSpecies = (cloneFromProduct?: Product | ProductDTO) => {
    let filterList: any[] = Object.values(ProductSpeciesSpeciesEnum).map(state => ({
      id: state,
      name: state,
      text: getTextByTreeOrTrunkSpecies(state),
      selected: false,
      active: false,
      disabled: false
    }));

    let productSpecies = cloneFromProduct?.productSpecies ? [...cloneFromProduct?.productSpecies] : [];
    if (productSpecies && productSpecies.length > 0) {
      productSpecies.forEach(elemSelected => {
        for (let val of filterList) {
          if (val.id == elemSelected.species) {
            val.selected = true;
            val.active = true;
            break;
          }
        }
      });
    }
    setProductSpeciesList(filterList, () => {});
    setProductSpeciesListReference(filterList, () => {});
  };

  /**
   * Prefix ex: U_BRU_001
   * U = Urstamm
   * BRU = prefixIdentifier
   * 001 = counter
   */
  const generateProductUniqueIdentifier = (processorBatchSelected: ProcessorBatchDTO) => {
    let products = processorBatchSelected?.products ? [...processorBatchSelected?.products] : [];

    if (products.length > 0) {
      let maxId: string = formatPhotoNumber((Number(products.length) + 1).toString(), 2);
      return setProductUniqueIdentifier(processorBatchSelected.uniqueIdentifier + '_' + maxId);
    }
    //Default value
    return setProductUniqueIdentifier(processorBatchSelected.uniqueIdentifier + '_' + '01');
  };

  return (
    <BaseLayoutCorner
      navigation={navigation}
      cornerOptions={cornerOption}
      layoutStyle={{
        topContainer: UrstammStyleLayout.topContainerCorner,
        bottomContainer: UrstammStyleLayout.baseBottomContainer,
        bottomSubContainer: UrstammStyleLayout.bottomSubContainer40,
        headerContainer: UrstammStyleHeader.headerCenterLogo,
        headerDetails: UrstammStyleHeader.headerDetails,
        cornerBottomLeft: UrstammStyleCornerButton.cornerBottomLeft40,
        cornerBottomRight: UrstammStyleCornerButton.cornerBottomRight40
      }}
      submitTopLeft={goBack}
      title={
        <UrstammTitle
          testID={'product_title'}
          text={i18n.t('views.product.product_registration.new_product')}
          fontStyle={UrstammStyleHeader.headerTextStyleBlack}
        />
      }
      headerDetails={[{ title: i18n.t('generics.name'), value: processorBatchSelected?.name! }]}
      view={
        <ProductRegistrationView
          navigation={navigation}
          processorBatchDTO={processorBatchSelected!}
          uniqueIdentifier={productUniqueIdentifier}
          submitProductForm={submitProduct}
          companyProductTypeProps={{
            companyProductTypeList: rdxCompanyProductTypeList
          }}
        
          companySurfaceProps={{
            companySurfaceList: rdxCompanySurfaceList
          }}

          companyAppearanceProps={{
            companyAppearanceList: rdxCompanyAppearanceList
          }}

          certificationProps={{
            certificationList: certificationList,
            certificationSelected: (data: any) => selectElementFormList(data, setCertificationList, certificationList),
            resetCertificationSelected: () => restoreListToReference(setCertificationList, certificationListReference),
            confirmCertificationSelected: () => applySavingToReference(setCertificationListReference, certificationList)
          }}
          speciesProps={{
            speciesList: productSpeciesList,
            speciesSelected: (data: any) => selectSlideElementFormList(data, setProductSpeciesList, productSpeciesList),
            resetSpeciesSelected: () => restoreListToReference(setProductSpeciesList, productSpeciesListReference),
            confirmSpeciesSelected: () => applySavingToReference(setProductSpeciesListReference, productSpeciesList)
          }}
          processorList={processorList}
          // speciesProps={(data: any) => getSpeciesListId(data, setSpeciesList, speciesList)}
          cloneFromProduct={route.params?.cloneFromProduct}
        />
      }
    />
  );
}
