import UrstammButtonBase from '@components/utility-components/button/UrstammButtonBase';
import UrstammDatePicker from '@components/utility-components/date-picker/UrstammDatePicker';
import UrstammFilePicker, { UploadType } from '@components/utility-components/file-picker/UrstammFilePicker';
import UrstammIcon from '@components/utility-components/icon/UrstammIcon';
import UrstammInput from '@components/utility-components/input/UrstammInput';
import UrstammModal from '@components/utility-components/modal/UrstammModal';
import UrstammIndicator from '@components/utility-components/title/UrstammIndicator';
import { AlertHelper } from '@helpers/AlertHelper';
import { NumberHelper } from '@helpers/NumberHelper';
import { PlatformHelper } from '@helpers/PlatformHelper';
import { i18n } from '@i18n/i18n';
import { Logging, LoggingDTO } from '@services/apis/generated';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Linking, ScrollView, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import Collapsible from 'react-native-collapsible';
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import {
  getColorByLoggingState,
  getIconByLoggingState,
  getTextByLoggingState,
  loggingStateIsClosed
} from '../../../utils/classes/UrstammUtilityCurrentState';
import {
  urstammUtilDisplayError,
  urstammUtilValidator,
  urstammUtilValidatorInError
} from '../../../utils/classes/UrstammUtilityForm';
import { buildLoggingPermitUrl } from '../../../utils/classes/UrstammUtilityImage';
import { ColorTheme } from '../../../utils/styles/ColorTheme';
import {
  lightGreenBorderWhiteBGButton,
  lightGreenButton,
  mainGreenButton,
  warnButton
} from '../../../utils/styles/UrstammButtonModelStyle';
import { UrstammFontFamily, UrstammFontsize } from '../../../utils/styles/UrstammFont';
import {
  UrstammStyle,
  UrstammStyleButton,
  UrstammStyleCollapsible,
  UrstammStyleContainer,
  UrstammStyleLayout,
  UrstammStyleModal
} from '../../../utils/styles/UrstammStyle';
import { LoggingForm } from './LoggingRegistrationView';

export default function LoggingDetailsView(props: {
  navigation: any;
  loggingSelected: Logging;
  updateLogging: (form: any) => void;
  navigateToPlots: () => void;
  navigateToPiles: () => void;
  navigateToDeliverySheets: () => void;
  navigateToTrees: () => void;
  closeLogging: (confirm: boolean) => void;
  mediaType: (type: string) => void;
  loggingDTO?: LoggingDTO;
}) {
  const [loggingSelected, setLoggingSelected] = useState<Logging>();
  const [loggingDTO, setLoggingDTO] = useState<LoggingDTO>();
  const [permitPreview, setPermitPreview] = useState<string>('');
  const [openCloseLoggingModal, setOpenCloseLoggingModal] = useState<boolean>(false);
  const [collapsed, setCollapsed] = React.useState<boolean>(true);

  const [loggingForm, setLoggingForm] = useStateWithCallbackLazy<LoggingForm>({
    logging: {
      name: props.loggingSelected?.name,
      comment: props.loggingSelected?.comment,
      estimatedVolume: props.loggingSelected?.estimatedVolume
    },
    permit: ''
  });

  const [saveDisabled, setSaveDisabled] = useState<boolean>(true);

  //Validators
  const validators = useState<any>({
    name: { required: true },
    estimatedVolume: { numeric_no_digits: true }
  });

  const [errors, setErrors] = useStateWithCallbackLazy<any>({
    name: [],
    estimatedVolume: []
  });

  const validate = (value: string, key: string, updatedLoggingForm: LoggingForm) => {
    let errorList = urstammUtilValidator(value, key, validators);
    setErrors(
      (state: any) => ({ ...state, [key]: errorList }),
      errorsUpdated => saveButtonDisabled(errorsUpdated, updatedLoggingForm)
    );
  };

  const saveButtonDisabled = (errorsUpdated, updatedLoggingForm: LoggingForm) => {
    let disabled: boolean = false;
    if (urstammUtilValidatorInError(errorsUpdated) || updatedLoggingForm.logging.name?.length == 0) {
      disabled = true;
    }
    return setSaveDisabled(disabled);
  };

  useEffect(() => {
    if (props.loggingSelected) {
      setLoggingSelected(props.loggingSelected);
      setLoggingForm(
        {
          logging: {
            name: props.loggingSelected?.name,
            comment: props.loggingSelected?.comment,
            estimatedVolume: props.loggingSelected?.estimatedVolume
          },
          permit: ''
        },
        () => {}
      );

      if (props.loggingSelected.permitFilepath) {
        buildLoggingPermitUrl(props.loggingSelected).then(res => {
          setPermitPreview(res);
        });
      }
      //downloadLoggingPermit(props.loggingSelected);
    }
    return () => {};
  }, [props.loggingSelected]);

  useEffect(() => {
    if (props.loggingDTO) {
      setLoggingDTO(props.loggingDTO);
      // console.log(props.loggingDTO, 'loggingDTO');
    }
    return () => {};
  }, [props.loggingDTO]);

  /**
   *
   * @param key
   * @param value
   */
  const updateLoggingForm = (key: string, value: any, mustValidate?: boolean): void => {
    let logging = { ...loggingForm.logging };
    let permit = loggingForm.permit;

    if (key == 'permit') permit = value;

    const formKey = key as keyof Logging;
    logging[formKey] = value;
    setLoggingForm(
      { logging: logging, permit: permit },
      mustValidate ? updatedLoggingForm => validate(value, key, updatedLoggingForm) : () => {}
    );
  };

  const downloadDocument = async () => {
    let documentUrl = await buildLoggingPermitUrl(props.loggingSelected);
    if (documentUrl) {
      Linking.canOpenURL(documentUrl).then(supported => {
        if (supported) {
          Linking.openURL(documentUrl);
        } else {
          AlertHelper.showSimpleErrorAlert();
        }
      });
    }
  };

  // const downloadLoggingPermit = (logging: Logging) => {
  //     customLoggingResourceApi.downloadLoggingPermit({ id: logging?.id! }).then((permit) => {
  //         if (permit) {
  //             setPermitPreview(permit)
  //         }
  //     })
  // }

  return (
    <View style={[UrstammStyle.flex1]}>
      <ScrollView
        keyboardShouldPersistTaps="always"
        contentContainerStyle={[{ flexGrow: 1, flexDirection: 'column', paddingBottom: 144 }]}>
        {/**Status and creation date */}
        <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
          <View style={UrstammStyleContainer.row1SpaceBetween}>
            {loggingSelected?.currentState ? (
              <View style={[UrstammStyle.flex1, UrstammStyle.flexRow]}>
                {loggingSelected.currentState && getIconByLoggingState(loggingSelected.currentState)}
                <Text
                  style={[
                    UrstammStyleContainer.statusCreationDateTextWithIcon,
                    getColorByLoggingState(loggingSelected.currentState)
                  ]}>
                  {getTextByLoggingState(loggingSelected.currentState)}
                </Text>
              </View>
            ) : null}
            {loggingSelected?.creationDate ? (
              <View style={[UrstammStyle.flex1, UrstammStyle.flexRow, UrstammStyle.flexEndX]}>
                <Text style={UrstammStyle.titleStyle}>{i18n.t('generics.creation') + ' '}</Text>
                <Text style={UrstammStyle.dataStyle}>{moment(loggingSelected.creationDate).format('DD/MM/yyyy')}</Text>
              </View>
            ) : null}
          </View>
        </View>

        {/**Status and creation date */}
        {loggingSelected?.comment ? (
          <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
            <View style={UrstammStyleContainer.row1SpaceBetween}>
              <View style={[UrstammStyle.flex1, UrstammStyle.flexRow, UrstammStyle.wrap]}>
                <Text style={UrstammStyle.titleStyle}>{i18n.t('generics.comment') + ': '}</Text>
                <Text style={UrstammStyle.dataStyle}>{loggingSelected?.comment}</Text>
              </View>
            </View>
          </View>
        ) : null}

        <View style={UrstammStyle.divider} />

        {/**Total wood */}
        <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
          <UrstammIndicator
            testID={'total_wood_indicator'}
            text={i18n.t('generics.total_wood')}
            fontStyle={UrstammStyle.formBaseRMainGreen}
            counter={NumberHelper.roundWithThreeDecimals(loggingSelected?.sumWoodTotal!)}
            enableButton={false}
            buttonStyle={lightGreenBorderWhiteBGButton}
            icon={{
              showIcon: true,
              iconName: 'bars',
              iconColor: ColorTheme.lightGreen
            }}
            buttonText={i18n.t('generics.details')}
          />
        </View>

        {/**Cubage standinglist */}
        <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
          <UrstammIndicator
            testID={'cubage_standinglist_indicator'}
            text={i18n.t('generics.estimated_volume')}
            fontStyle={UrstammStyle.formBaseRMainGreen}
            counter={NumberHelper.roundWithThreeDecimals(loggingSelected?.estimatedVolume!)}
            icon={{
              showIcon: true,
              iconName: 'bars',
              iconColor: ColorTheme.lightGreen
            }}
            enableButton={false}
          />
        </View>

        {/**Trunks */}
        <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
          <UrstammIndicator
            testID={'trunks_indicator'}
            text={i18n.t('generics.trunks')}
            fontStyle={UrstammStyle.formBaseRMainGreen}
            counter={Number(loggingDTO?.trunks?.size)}
            icon={{
              showIcon: true,
              iconName: 'bars',
              iconColor: ColorTheme.lightGreen
            }}
            enableButton={false}
          />
        </View>

        {/**Trees */}
        <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
          <UrstammButtonBase
            testID={'navigate_trees'}
            text={i18n.t('generics.trees')}
            style={lightGreenButton}
            onSubmit={props.navigateToTrees}
            counter={loggingDTO?.trees?.size?.toString()}
          />
        </View>

        {/**Piles */}
        <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
          <UrstammButtonBase
            testID={'navigate_piles'}
            text={i18n.t('generics.piles')}
            style={lightGreenButton}
            onSubmit={props.navigateToPiles}
            counter={loggingDTO?.piles?.size?.toString()}
          />
        </View>

        {/**Delivery sheets */}
        <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
          <UrstammButtonBase
            testID={'navigate_delivery_sheets'}
            text={i18n.t('generics.delivery_sheet')}
            style={lightGreenButton}
            onSubmit={props.navigateToDeliverySheets}
            counter={loggingDTO?.deliverySheets?.size?.toString()}
          />
        </View>

        {/**Close logging */}
        {!loggingStateIsClosed(loggingSelected?.currentState!) && (
          <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
            <UrstammButtonBase
              testID={'close_logging'}
              text={i18n.t('views.logging.logging_details.close_logging')}
              style={warnButton}
              onSubmit={() => setOpenCloseLoggingModal(true)}
            />
          </View>
        )}

        {/**Edit logging */}
        <View>
          <View
            style={[
              UrstammStyleButton.smallBaseButtonStructure,
              { backgroundColor: ColorTheme.yellow },
              UrstammStyle.baseShadow
            ]}>
            <View style={UrstammStyleCollapsible.selectMainContainer}>
              <TouchableOpacity
                style={UrstammStyleCollapsible.selectCollapsible}
                onPress={() => setCollapsed(!collapsed)}>
                <Text style={UrstammStyleButton.baseButtonBWStyle}>{i18n.t('generics.edit')}</Text>

                <UrstammIcon name={collapsed ? 'angle-down' : 'angle-up'} size={24} color={'white'} />
              </TouchableOpacity>
            </View>
          </View>

          <View style={[{ marginTop: 4 }]}>
            <Collapsible
              duration={500}
              easing={'easeInBounce'}
              collapsed={collapsed}
              style={UrstammStyleCollapsible.containerCollapsible}>
              <ScrollView
                keyboardShouldPersistTaps="always"
                contentContainerStyle={[
                  {
                    flexGrow: 1,
                    flexDirection: 'column',
                    paddingBottom: 100
                  }
                ]}>
                {/** Name */}
                <View style={UrstammStyleLayout.formElementContainerGrw1}>
                  <UrstammInput
                    testID="logging_name"
                    style={{
                      containerViewStyle: UrstammStyleContainer.inputContainer,
                      textStyle: UrstammStyle.formBaseLDarkGrey
                    }}
                    icon={{
                      showIcon: false,
                      iconName: 'user',
                      iconColor: ColorTheme.lightGreen
                    }}
                    editable={!loggingStateIsClosed(loggingSelected?.currentState!)}
                    onChangeText={(text: string) => {
                      updateLoggingForm('name', text, true);
                    }}
                    value={loggingForm.logging.name ? loggingForm.logging.name : ''}
                    placeholder={i18n.t('views.logging.logging_registration.insert_logging_name_here')}
                    textInputTitle={i18n.t('generics.name')}
                    mandatory={true}
                  />

                  {errors['name'] && errors['name'].length > 0
                    ? errors['name'].map((error, idx) => {
                        return urstammUtilDisplayError(error, idx);
                      })
                    : null}
                </View>

                {/** Date Picker */}
                <View style={UrstammStyleLayout.formElementContainerGrw1}>
                  <UrstammDatePicker
                    testID={'logging_date'}
                    containerStyle={UrstammStyleContainer.inputContainer}
                    fontStyle={UrstammStyle.formBaseLDarkGrey}
                    textInputTitle={i18n.t('generics.date')}
                    onChange={(dateTime: string) => updateLoggingForm('creationDate', dateTime)}
                    disabled={loggingStateIsClosed(loggingSelected?.currentState!)}
                    defaultValue={loggingSelected?.creationDate}
                    icon={{
                      showIcon: true,
                      iconName: 'calendar',
                      iconColor: ColorTheme.lightGreen
                    }}
                    placeholder={i18n.t('views.logging.logging_registration.insert_logging_date_here')}
                    mode={'date'}
                  />
                </View>

                {/** Comment */}
                <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
                  <UrstammInput
                    testID="logging_comment"
                    style={{
                      containerViewStyle: UrstammStyleContainer.textareaContainer,
                      textStyle: UrstammStyle.formBaseLDarkGrey
                    }}
                    icon={{ showIcon: false }}
                    editable={!loggingStateIsClosed(loggingSelected?.currentState!)}
                    onChangeText={(text: string) => updateLoggingForm('comment', text)}
                    value={loggingForm.logging.comment ? loggingForm.logging.comment : ''}
                    placeholder={i18n.t('views.logging.logging_details.comment_of_the_logging')}
                    textInputTitle={i18n.t('generics.comment')}
                    multiline={true}
                  />
                </View>

                {/**Permit details */}
                <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
                  {/** Permit files */}
                  <UrstammFilePicker
                    testID={'permit_details'}
                    inputStyle={UrstammStyleContainer.cameraContainer}
                    fontStyle={UrstammStyle.formBaseLDarkGrey}
                    icon={{
                      showIcon: true,
                      iconName: 'camera',
                      iconColor: ColorTheme.lightGreen
                    }}
                    placeholder={i18n.t('views.logging.logging_registration.upload_permit_file')}
                    textInputTitle={i18n.t('views.logging.logging_registration.permit')}
                    uploadType={UploadType.all}
                    mandatory={false}
                    uri={permitPreview ? permitPreview : undefined}
                    disabled={loggingStateIsClosed(loggingSelected?.currentState!)}
                    mediaTypeUploaded={loggingSelected?.permitMediaType}
                    mediaTypePicked={(type: string) => props.mediaType(type)}
                    downloadDocument={downloadDocument}
                    deleteBase64={() => updateLoggingForm('permit', undefined)}
                    saveBase64={(base64: string) => updateLoggingForm('permit', base64)}
                  />
                </View>

                <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
                  <UrstammInput
                    testID="estimated_volume_pile"
                    style={{
                      containerViewStyle: UrstammStyleContainer.inputContainer,
                      textStyle: UrstammStyle.formBaseLDarkGrey
                    }}
                    icon={{ showIcon: false }}
                    keyboardType={'numeric'}
                    placeholder={i18n.t('generics.insert_estimated_volume_here')}
                    textInputTitle={i18n.t('generics.estimated_logging_volume')}
                    onChangeText={(text: string) => updateLoggingForm('estimatedVolume', text, true)}
                    value={loggingForm.logging.estimatedVolume ? loggingForm.logging.estimatedVolume.toString() : ''}
                    mandatory={false}
                  />
                  {/**Validator */}
                  {errors['estimatedVolume'] && errors['estimatedVolume'].length > 0
                    ? errors['estimatedVolume'].map((error, idx) => {
                        return urstammUtilDisplayError(error, idx);
                      })
                    : null}
                </View>

                {/**Navigate to plot */}
                <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
                  <UrstammButtonBase
                    testID={'navigate_plot_details'}
                    text={i18n.t('views.logging.logging_details.plot_details')}
                    style={mainGreenButton}
                    onSubmit={props.navigateToPlots}
                  />
                </View>

                {/**Save */}
                {!loggingStateIsClosed(loggingSelected?.currentState!) && (
                  <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
                    <UrstammButtonBase
                      testID={'save'}
                      text={i18n.t('generics.save')}
                      style={mainGreenButton}
                      onSubmit={() => props.updateLogging(loggingForm)}
                      disabled={urstammUtilValidatorInError(errors) || loggingForm.logging.name?.length == 0}
                    />
                  </View>
                )}
              </ScrollView>
            </Collapsible>
          </View>
        </View>

        {openCloseLoggingModal && (
          <UrstammModal
            testID={'close_logging_modal'}
            text={i18n.t('views.logging.logging_details.are_you_sure_want_close_logging')}
            textStyle={UrstammStyleModal.formBtextModalStyle}
            visible={openCloseLoggingModal}
            buttonText={{
              left: i18n.t('utility_components.modal.cancel'),
              right: i18n.t('utility_components.modal.confirm')
            }}
            confirm={(confirmed: boolean) => {
              props.closeLogging(confirmed), setOpenCloseLoggingModal(false);
            }}
          />
        )}
      </ScrollView>
    </View>
  );
}

const styles = StyleSheet.create({
  statusCreationMainContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  statusCreationContainer: {},
  statusCreationText: {
    alignItems: 'center',
    paddingLeft: 8,
    fontFamily: UrstammFontFamily.regularFont,
    fontSize: PlatformHelper.normalizeByDevice(UrstammFontsize.baseFontSize)
  },
  iconContainer: {
    paddingRight: 14
  },
  inputRow: {
    flexDirection: 'row',
    justifyContent: 'space-between'
  }
});
