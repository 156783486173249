import UrstammImageBack from '@components/images/corner/UrstammImageBack';
import UrstammImageMenu from '@components/images/corner/UrstammImageMenu';
import UrstammImagePlus from '@components/images/corner/UrstammImagePlus';
import BaseLayoutCorner, { BaseLayoutOptions } from '@components/layouts/base/BaseLayoutCorner';
import { ButtonSelect } from '@components/utility-components/button/UrstammButtonSelect';
import {
  applySavingToReference,
  deepCopyAndAddSelectedParameter,
  restoreListToReference,
  selectElementFormList,
  selectSlideElementFormList
} from '@components/utility-components/modal/UrstammModalSelect';
import UrstammTitle from '@components/utility-components/title/UrstammTitle';
import BatchDetailsView from '@components/views/batch/BatchDetailsView';
import { PacketImageForm } from '@components/views/packet/PacketDetailsView';
import { PacketForm } from '@components/views/packet/PacketRegistrationView';
import { AlertHelper } from '@helpers/AlertHelper';
import { ErrorHelper } from '@helpers/ErrorHelper';
import { PlatformHelper } from '@helpers/PlatformHelper';
import { i18n } from '@i18n/i18n';
import { resetBatchList } from '@redux/features/batch/batchSlice';
import { setDeliverySheetPage } from '@redux/features/delivery-sheet/deliverySheetSlice';
import { changeLoaderStatus } from '@redux/features/loader/loaderSlice';
import { resetPacketList, setPacketList, setPacketListSort } from '@redux/features/packet/packetSlice';
import {
  resetCompanyProductTypeList,
  setCompanyProductTypeList
} from '@redux/features/product-type/companyProductTypeSlice';
import { resetCompanySurfaceList, setCompanySurfaceList } from '@redux/features/surface/companySurfaceSlice';
import { RootState } from '@redux/store';
import {
  customBatchResourceApi,
  customCompanyProductTypeResourceApi,
  customCompanyResourceApi,
  customCompanySurfaceResourceApi,
  customPacketResourceApi,
  customTrunkResourceApi,
  longListSize
} from '@services/apis/ApiConfiguration';
import {
  Batch,
  BatchCurrentStateEnum,
  BatchDTO,
  BatchRecordingTypeEnum,
  Company,
  CompanyProductType,
  CompanySurface,
  DeliverySheet,
  DeliverySheetDTOCurrentUrstammStateEnum,
  Packet,
  PacketCertification,
  PacketCertificationCertificationEnum,
  PacketCurrentStateEnum,
  PacketDTO,
  PacketSpecies,
  PacketSpeciesSpeciesEnum,
  PageCompany,
  Trunk
} from '@services/apis/generated';
import React, { useEffect, useState } from 'react';
import { Keyboard } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import { RootStackScreenProps, UrstammNavigationHelper } from '../../navigation/UrstammNavigationHelper';
import { getTextByTreeOrTrunkSpecies } from '../../utils/classes/UrstammUtilityCurrentState';
import {
  batchIsClosed,
  generateSet,
  generateSetForCertification,
  generateSetForPacketSpecies,
  sleep
} from '../../utils/classes/UrstammUtilityFunctions';
import { UrstammStyleCornerButton, UrstammStyleHeader, UrstammStyleLayout } from '../../utils/styles/UrstammStyle';

export default function BatchDetailsContainer({ navigation, route }: RootStackScreenProps<'BatchDetails'>) {
  const rdxCompanySurfaceList = useSelector((state: RootState) => state.persistedReducer.companySurface.list);
  const rdxCompanySurfaceCompleteList = useSelector(
    (state: RootState) => state.persistedReducer.companySurface.completeList
  );
  const rdxDSList = useSelector((state: RootState) => state.persistedReducer.deliverySheet.list);
  const rdxDSCompleteList = useSelector((state: RootState) => state.persistedReducer.deliverySheet.completeList);
  const rdxDSPage = useSelector((state: RootState) => state.persistedReducer.deliverySheet.page);
  const rdxDSTotalPages = useSelector((state: RootState) => state.persistedReducer.deliverySheet.totalPages);
  const rdxDSSortBy = useSelector((state: RootState) => state.persistedReducer.deliverySheet.sortBy);
  const rdxUserExtendedMe = useSelector((state: RootState) => state.persistedReducer.user.extendedMe);
  const rdxMyCompany = useSelector((state: RootState) => state.persistedReducer.user.myCompany);
  const rdxCompanyProductTypeList = useSelector((state: RootState) => state.persistedReducer.companyProductType.list);

  const rdxPacketList = useSelector((state: RootState) => state.persistedReducer.packet.list);
  const rdxPacketCompleteList = useSelector((state: RootState) => state.persistedReducer.packet.completeList);
  const dispatch = useDispatch();

  const [processorList, setProcessorList] = useState<Company[]>([]);
  const [batchList, setBatchList] = useStateWithCallbackLazy<Batch[]>([]);
  const [batchSelected, setBatchSelected] = useState<Batch>();
  const [batchDTO, setBatchDTO] = useStateWithCallbackLazy<any>(() => {});
  const [batchUniqueIdentifier, setBatchUniqueIdentifier] = useState<string>('');
  const [resetForm, setResetForm] = useState<boolean>(false);
  const [dsList, setDsList] = useStateWithCallbackLazy<any>(() => {});
  const [dsListReference, setDsListReference] = useState<any>();
  const [showSortBy, setShowSortBy] = useState<boolean>(false);

  const [packetSelected, setPacketSelected] = useState<PacketDTO>();
  const [packetSpeciesList, setPacketSpeciesList] = useStateWithCallbackLazy<any[]>([]);
  const [packetSpeciesListReference, setPacketSpeciesListReference] = useStateWithCallbackLazy<any[]>([]);
  const [certificationList, setCertificationList] = useStateWithCallbackLazy<any[]>([]);
  const [certificationListReference, setCertificationListReference] = useStateWithCallbackLazy<any[]>([]);
  const [trunkListByBatchDS, setTrunkListByBatchDS] = useStateWithCallbackLazy<any[]>([]);
  const [trunkListByBatchDSReference, setTrunkListByBatchDSReference] = useStateWithCallbackLazy<any[]>([]);
  const [closeModalClosingPacket, setCloseModalClosingPacket] = useState<boolean>(false);

  let cornerOption: BaseLayoutOptions = {
    cornerTopLeft: {
      showCorner: true,
      text: i18n.t('utility_components.corner_button.back'),
      icon: (
        <UrstammImageBack width={PlatformHelper.normalizeByDevice(34)} height={PlatformHelper.normalizeByDevice(24)} />
      )
    },
    cornerTopRight: {
      showCorner: true,
      text: i18n.t('utility_components.corner_button.menu'),
      icon: (
        <UrstammImageMenu width={PlatformHelper.normalizeByDevice(34)} height={PlatformHelper.normalizeByDevice(24)} />
      )
    },
    cornerBottomLeft: {
      showCorner: false,
      text: i18n.t('utility_components.corner_button.sort_by'),
      icon: undefined
    },
    cornerBottomRight: {
      showCorner: !batchIsClosed(route.params?.batchSelected),
      text: i18n.t('generics.packet'),
      icon: (
        <UrstammImagePlus width={PlatformHelper.normalizeByDevice(34)} height={PlatformHelper.normalizeByDevice(24)} />
      )
    }
  };

  useEffect(() => {
    if (route.params?.batchSelected) {
      setBatchSelected(route.params?.batchSelected);
      getBatchDTObyBatchId(route.params?.batchSelected);
      getAllPacketByBatchId(route.params?.batchSelected);
    }
  }, [route.params?.batchSelected]);

  useEffect(() => {
    getAllCompanyProcessorConnectedToMe();
  }, []);

  const getBatchDTObyBatchId = (batch: Batch) => {
    customBatchResourceApi
      .getBatchCustom({ id: batch.id! })
      .then((batchDTO: BatchDTO) => {
        if (batchDTO)
          setBatchDTO(batchDTO, updatedBatchDTO => {
            rdxDSCompleteList.length > 0 ? copyDSObj(updatedBatchDTO) : {};
          });
      })
      .catch(async error => {
        dispatch(changeLoaderStatus(false));
        let errorMessage = await ErrorHelper.getServerMessageFromJson(error);
        let errorJson = await ErrorHelper.getDetailFromServerMessage(errorMessage);
        AlertHelper.showSimpleAlert('Error', errorJson);
      });
  };

  const copyDSObj = (batchDTO: BatchDTO) => {
    let list = JSON.parse(JSON.stringify(rdxDSCompleteList));

    //Filtering DS by batch recording type
    list = list.filter(ds =>
      ds.recordingType == batchDTO.recordingType
      // enable the selection of empty delivery sheets (no trunks)
      // && ds.trunks?.length
      // && ds.trunks?.length > 0
      && (
        (!(ds.trunks?.length && ds.trunks?.length > 0) && ds.currentUrstammState == DeliverySheetDTOCurrentUrstammStateEnum.Delivered)
        || ds.currentUrstammState == DeliverySheetDTOCurrentUrstammStateEnum.Validated
        || ds.currentUrstammState == DeliverySheetDTOCurrentUrstammStateEnum.Closed
      )
    );
    list = list.map(val => ({
      ...val,
      selected: false
    }));
    setDeliverySheetSelected(batchDTO, list);
  };

  /**
   *
   * @param batchDTO
   * @param list with custom value (selected: boolean)
   */
  const setDeliverySheetSelected = (batchDTO: BatchDTO, list) => {
    let deliverySheetSelected: DeliverySheet[] = JSON.parse(JSON.stringify(Array.from(batchDTO.deliverySheets || new Set()))); //Copy DS from batchDTO (Set)
    if (deliverySheetSelected.length > 0) {
      let copyList = JSON.parse(JSON.stringify(list));
      deliverySheetSelected.forEach((dsSel: DeliverySheet) => {
        copyList = selectDS(dsSel, copyList, true);
      });
      //if i haven't DSs saved in the batch I set the default list with (selected: false)
    } else {
      setDsList(list, updatedDSList => {});
      setDsListReference(list);
    }
  };

  const selectDS = (data, list, setReference?: boolean) => {
    let listCopy = JSON.parse(JSON.stringify(list));
    listCopy.forEach(val => {
      if (val.id == data.id) {
        val.selected = !data.selected;
      }
    });
    setDsList(listCopy, updatedDSList => {});
    setReference ? setDsListReference(listCopy) : null;
    return listCopy;
  };

  /**
   * When user closes modal I restore the previous list (using the reference)
   */
  const restoreDSList = () => {
    const cloneList = JSON.parse(JSON.stringify(dsListReference));
    setDsList(cloneList, () => {});
  };

  const updateBatch = (batch: BatchDTO, closingBatch?: boolean) => {
    let dsSet: Set<DeliverySheet> = new Set(generateSet(dsList));

    dispatch(changeLoaderStatus(true));

    let batchObj: Batch = {
      id: batchSelected?.id!,
      name: batch.name!,
      uniqueIdentifier: batchSelected?.uniqueIdentifier!,
      creationDate: batch.creationDate,
      currentState: closingBatch ? BatchCurrentStateEnum.Closed : BatchCurrentStateEnum.InProgress,
      recordingType: batchSelected?.recordingType! as any,
      comment: batch.comment!,
      deliverySheets: dsSet
    };

    customBatchResourceApi
      .updateBatchCustom({ id: batchSelected?.id!, batch: batchObj })
      .then((batch: Batch) => {
        dispatch(changeLoaderStatus(false));
        if (batch) {
          dispatch(resetBatchList());
          UrstammNavigationHelper.navigateToBatchList(navigation, true);
        }
      })
      .catch(async error => {
        dispatch(changeLoaderStatus(false));
        let errorMessage = await ErrorHelper.getServerMessageFromJson(error);
        let errorJson = await ErrorHelper.getDetailFromServerMessage(errorMessage);
        AlertHelper.showSimpleAlert('Error', errorJson);
      });
  };

  const goBack = async (): Promise<void> => {
    dispatch(resetBatchList());
    Keyboard.dismiss();
    await sleep(PlatformHelper.isIos() ? 300 : 100);
    UrstammNavigationHelper.navigateToBatchList(navigation, true);
  };

  const getMoreDsItems = (): void => {
    if (rdxDSPage < rdxDSTotalPages - 1) {
      dispatch(setDeliverySheetPage(rdxDSPage + 1));
    }
  };

  const validatePacket = async (data: Packet) => {
    getPacketCustom(data);
  };

  const getPacketCustom = (packetSelected: Packet) => {
    dispatch(changeLoaderStatus(true));

    customPacketResourceApi
      .getPacketCustom({ id: packetSelected.id! })
      .then((packet: PacketDTO) => {
        dispatch(changeLoaderStatus(false));
        if (packet) {
          let trunksSelected = packet.trunks ? [...packet.trunks] : [];
          setPacketSelected(packet);
          getTrunkListByDeliverySheet(trunksSelected);
          getAllPacketCertifications(packet);
          getAllPacketSpecies(packet);
          getCompanySurfaceResourceApi();
          getCompanyProductType();
        }
      })
      .catch(async error => {
        dispatch(changeLoaderStatus(false));
        let errorMessage = await ErrorHelper.getServerMessageFromJson(error);
        let errorJson = await ErrorHelper.getDetailFromServerMessage(errorMessage);
        AlertHelper.showSimpleAlert('Error', errorJson);
      });
  };

  const getTrunkListByDeliverySheet = (trunksSelected?: Trunk[]): void => {
    dispatch(changeLoaderStatus(true));
    let trunkByBatchDS: Trunk[] = [];

    customBatchResourceApi
      .getDSTrunksByBatch({ id: batchDTO.id! })
      .then((list: Set<Trunk>) => {
        if (list) {
          trunkByBatchDS = [...list];
          setTrunkListByBatchDS(trunkByBatchDS, listUpdated => {
            deepCopyAndAddSelectedParameter(
              listUpdated,
              setTrunkListByBatchDS,
              setTrunkListByBatchDSReference,
              trunksSelected
            );
          });
        }
      })
      .catch(async error => {
        dispatch(changeLoaderStatus(false));
        let errorMessage = await ErrorHelper.getServerMessageFromJson(error);
        let errorJson = await ErrorHelper.getDetailFromServerMessage(errorMessage);
        AlertHelper.showSimpleAlert('Error', errorJson);
      });
  };

  const getCompanySurfaceResourceApi = () => {
    dispatch(changeLoaderStatus(true));

    customCompanySurfaceResourceApi
      .getAllCompanySurfacesForCompany({})
      .then((surfaceList: CompanySurface[]) => {
        dispatch(changeLoaderStatus(false));

        if (surfaceList) {
          dispatch(resetCompanySurfaceList());
          dispatch(setCompanySurfaceList(surfaceList));
        }
      })
      .catch(async error => {
        dispatch(changeLoaderStatus(false));
        let errorMessage = await ErrorHelper.getServerMessageFromJson(error);
        let errorJson = await ErrorHelper.getDetailFromServerMessage(errorMessage);
        AlertHelper.showSimpleAlert('Error', errorJson);
      });
  };

  const applyOrder = (orderSelected: ButtonSelect[]): void => {
    dispatch(setPacketListSort(orderSelected));
    setShowSortBy(false);
  };

  const getAllPacketByBatchId = (batchSelected: Batch) => {
    dispatch(changeLoaderStatus(true));

    customPacketResourceApi
      .getPacketsByBatch({ batchId: batchSelected?.id, batchIdEquals: batchSelected.id })
      .then((packetList: Packet[]) => {
        // force keep packetSpecies
        return packetList.map(p => {
          p.packetCertifications = [...(p.packetCertifications?.values() || [])] as any;
          p.packetSpecies = [...(p.packetSpecies?.values() || [])] as any;
          p.trunks = [...(p.trunks?.values() || [])] as any;
          return p;
        })
      })
      .then((packetList: Packet[]) => {
        dispatch(changeLoaderStatus(false));
        if (packetList) {
          dispatch(resetPacketList());
          dispatch(setPacketList(packetList));
        }
      })
      .catch(async error => {
        dispatch(changeLoaderStatus(false));
        let errorMessage = await ErrorHelper.getServerMessageFromJson(error);
        let errorJson = await ErrorHelper.getDetailFromServerMessage(errorMessage);
        AlertHelper.showSimpleAlert('Error', errorJson);
      });
  };

  const navigateToPrefilledPacketRegistration = (packetDto: Packet | PacketDTO) => {
    dispatch(changeLoaderStatus(true));

    customPacketResourceApi
      .getPacketCustom({ id: packetDto.id! })
      .then((packet: PacketDTO) => {
        dispatch(changeLoaderStatus(false));
        if (packet) {
          let trunksSelected = packet.trunks ? [...packet.trunks] : [];
          setPacketSelected(packet);
          getTrunkListByDeliverySheet(trunksSelected);
          getAllPacketCertifications(packet);
          getAllPacketSpecies(packet);
          getCompanySurfaceResourceApi();
          getCompanyProductType();
          UrstammNavigationHelper.navigateToPacketRegistration(navigation, batchDTO, packet);
        }
      })
      .catch(async error => {
        dispatch(changeLoaderStatus(false));
        let errorMessage = await ErrorHelper.getServerMessageFromJson(error);
        let errorJson = await ErrorHelper.getDetailFromServerMessage(errorMessage);
        AlertHelper.showSimpleAlert('Error', errorJson);
      });
  };

  const navigateToPacketRegistration = () => {
    UrstammNavigationHelper.navigateToPacketRegistration(navigation, batchDTO);
  };

  const navigateToPacketDetails = (packetSelected: Packet) => {
    UrstammNavigationHelper.navigateToPacketDetails(navigation, batchDTO, packetSelected);
  };

  /**
   * Setting processor list, it used for "Registration" and "Detail" Pages.
   * @param order
   */
  const getAllCompanyProcessorConnectedToMe = (order?: ButtonSelect[]) => {
    let sortBy = order && order.length > 0 ? [order[0].sort?.sortBy + ',' + order[0].sort?.direction] : ['name,desc'];
    dispatch(changeLoaderStatus(true));

    customCompanyResourceApi
      .getCompaniesConnectedToMe({
        sort: sortBy,
        customCompanyCriteria: {},
        page: 0,
        size: longListSize
      })
      .then((list: PageCompany) => {
        dispatch(changeLoaderStatus(false));

        if (list && list.content?.length) {
          list.content.push({ name: i18n.t('views.packet.no_urstamm_processor'), id: -200 });
          setProcessorList(list.content);
        }
      })
      .catch(async error => {
        dispatch(changeLoaderStatus(false));
        let errorMessage = await ErrorHelper.getServerMessageFromJson(error);
        let errorJson = await ErrorHelper.getDetailFromServerMessage(errorMessage);
        AlertHelper.showSimpleAlert('Error', errorJson);
      });
  };

  const getAllPacketCertifications = (packet: PacketDTO | Packet) => {
    let filterList: any[] = [];
    Object.values(PacketCertificationCertificationEnum).map(state => {
      let type: any = {
        id: state,
        name: state,
        selected: state == PacketCertificationCertificationEnum.Urstamm,
        disabled: state == PacketCertificationCertificationEnum.Urstamm
      };
      filterList.push(type);
    });

    let packetCertification = packet.packetCertifications ? [...packet.packetCertifications] : [];
    if (packetCertification && packetCertification.length > 0) {
      packetCertification.forEach(elemSelected => {
        for (let val of filterList) {
          if (val.id == elemSelected.certification) {
            val.selected = true;
            break;
          }
        }
      });
    }
    setCertificationList(filterList, () => {});
    setCertificationListReference(filterList, () => {});
  };

  const getAllPacketSpecies = (packet: PacketDTO | Packet) => {
    let packetSpecies = packet.packetSpecies ? [...packet.packetSpecies] : [];

    let filterList: any[] = Object.values(PacketSpeciesSpeciesEnum).map(state => ({
      id: state,
      name: state,
      text: getTextByTreeOrTrunkSpecies(state),
      selected: !!packetSpecies.find(p => p.species === state),
      active: !!packetSpecies.find(p => p.species === state),
      disabled: false
    }));

    setPacketSpeciesList(filterList, () => {});
    setPacketSpeciesListReference(filterList, () => {});
  };

  const getCompanyProductType = () => {
    dispatch(changeLoaderStatus(true));

    customCompanyProductTypeResourceApi
      .getAllCompanyProductTypesForCompany({})
      .then((productTypeList: CompanyProductType[]) => {
        dispatch(changeLoaderStatus(false));

        if (productTypeList) {
          dispatch(resetCompanyProductTypeList());
          dispatch(setCompanyProductTypeList(productTypeList));
        }
      })
      .catch(async error => {
        dispatch(changeLoaderStatus(false));
        let errorMessage = await ErrorHelper.getServerMessageFromJson(error);
        let errorJson = await ErrorHelper.getDetailFromServerMessage(errorMessage);
        AlertHelper.showSimpleAlert('Error', errorJson);
      });
  };

  const closingPacket = (packetForm: PacketForm, packetImageForm: PacketImageForm) => {
    dispatch(changeLoaderStatus(true));

    let certificationSet: Set<PacketCertification> = new Set(generateSetForCertification(certificationList));
    let speciesSet: Set<PacketSpecies> = generateSetForPacketSpecies(packetSpeciesList);
    let trunksSet: Set<Trunk> = new Set(generateSet(trunkListByBatchDS));

    let packetObj: Packet = {
      id: packetSelected?.id,
      batch: batchSelected as any,
      currentState: PacketCurrentStateEnum.Closed,
      uniqueIdentifier: packetForm.packet.uniqueIdentifier!,
      name: packetForm.packet.name!,
      creationDate: packetForm.packet.creationDate,
      length: packetForm.packet.length,
      width: packetForm.packet.width,
      thickness: packetForm.packet.thickness,
      amountOfProducts: packetForm.packet.amountOfProducts,
      dryness: packetForm.packet.dryness,
      planningDimension: packetForm.packet.planningDimension,
      woodQuality: packetForm.packet.woodQuality,
      comment: packetForm.packet.comment,
      processor: packetForm.packet.processor,
      packetCertifications: certificationSet,
      packetSpecies: speciesSet,
      productType: packetForm.packet.productType,
      surface: packetForm.packet.surface,
      trunks: trunksSet,
      longitude: Number(packetImageForm?.longitude),
      latitude: Number(packetImageForm?.latitude),
      photoCreationDate: packetImageForm?.photoCreationDate,
    };

    customPacketResourceApi
      .updatePacketCustom({
        id: packetSelected?.id!,
        entityWithFileDTOPacket: {
          entity: packetObj,
          base64File: packetImageForm.base64File
        }
      })
      .then(async (packet: Packet) => {
        dispatch(changeLoaderStatus(false));

        if (packet) {
          getBatchDTObyBatchId(batchSelected!);
          //setCloseModalClosingPacket(!closeModalClosingPacket);
          setCloseModalClosingPacket(true);
          setCloseModalClosingPacket(false);
          getAllPacketByBatchId(batchDTO);
        }
      })
      .catch(async error => {
        dispatch(changeLoaderStatus(false));
        let errorMessage = await ErrorHelper.getServerMessageFromJson(error);
        let errorJson = await ErrorHelper.getDetailFromServerMessage(errorMessage);
        AlertHelper.showSimpleAlert('Error', errorJson);
      });
  };

  return (
    <BaseLayoutCorner
      navigation={navigation}
      cornerOptions={cornerOption}
      layoutStyle={{
        topContainer: UrstammStyleLayout.topContainerCorner,
        bottomContainer: UrstammStyleLayout.baseBottomContainer,
        bottomSubContainer: UrstammStyleLayout.bottomSubContainer40,
        headerContainer: UrstammStyleHeader.headerCenterLogo,
        headerDetails: UrstammStyleHeader.headerDetails,
        cornerBottomLeft: UrstammStyleCornerButton.cornerBottomLeft40,
        cornerBottomRight: UrstammStyleCornerButton.cornerBottomRight40
      }}
      submitTopLeft={goBack}
      submitBottomRight={navigateToPacketRegistration}
      title={
        <UrstammTitle
          testID={'batch_title'}
          text={i18n.t('generics.batch')}
          fontStyle={UrstammStyleHeader.headerTextStyleBlack}
        />
      }
      subTitle={
        <UrstammTitle
          testID={'trunk_details_subtitle'}
          text={batchSelected?.uniqueIdentifier ? batchSelected?.uniqueIdentifier : ''}
          fontStyle={UrstammStyleHeader.headerDetailsTextStyleBlack}
        />
      }
      headerDetails={[{ title: i18n.t('generics.name'), value: batchSelected?.name!, truncateValue: 30 }]}
      view={
        <BatchDetailsView
          navigation={navigation}
          batchSelectedDTO={batchDTO!}
          customDSList={dsList}
          moreItems={getMoreDsItems}
          userExtendedMe={rdxUserExtendedMe}
          deliverySheetSelected={dsSelected => selectDS(dsSelected.item, dsList, false)}
          updateBatch={(batchDTO: BatchDTO) => updateBatch(batchDTO)}
          resetDSSelected={restoreDSList}
          confirmDSSelected={() => applySavingToReference(setDsListReference, dsList)}
          packetList={rdxPacketList}
          showSortBy={showSortBy}
          applyOrder={(orderSelected: ButtonSelect[]) => applyOrder(orderSelected)}
          closeShowSortBy={() => setShowSortBy(false)}
          validate={(data: Packet) => validatePacket(data)}
          packetSelected={navigateToPacketDetails}
          processorList={processorList}
          companyProductTypeProps={{
            companyProductTypeList: rdxCompanyProductTypeList
          }}
          certificationProps={{
            certificationList: certificationList,
            certificationSelected: (data: any) => selectElementFormList(data, setCertificationList, certificationList),
            resetCertificationSelected: () => restoreListToReference(setCertificationList, certificationListReference),
            confirmCertificationSelected: () => applySavingToReference(setCertificationListReference, certificationList)
          }}
          speciesProps={{
            speciesList: packetSpeciesList,
            speciesSelected: (data: any) => selectSlideElementFormList(data, setPacketSpeciesList, packetSpeciesList),
            resetSpeciesSelected: () => restoreListToReference(setPacketSpeciesList, packetSpeciesListReference),
            confirmSpeciesSelected: () => applySavingToReference(setPacketSpeciesListReference, packetSpeciesList)
          }}
          trunkProps={{
            trunkList: trunkListByBatchDS,
            trunkSelected: (data: any) => selectElementFormList(data, setTrunkListByBatchDS, trunkListByBatchDS),
            resetTrunkSelected: () => restoreListToReference(setTrunkListByBatchDS, trunkListByBatchDSReference),
            confirmTrunkSelected: () => applySavingToReference(setTrunkListByBatchDSReference, trunkListByBatchDS)
          }}
          companySurfaceProps={{
            companySurfaceList: rdxCompanySurfaceList
          }}
          submitPacketForm={(packetForm: PacketForm, packetImageForm: PacketImageForm | undefined) =>
            closingPacket(packetForm, packetImageForm!)
          }
          closeModalClosingPacket={closeModalClosingPacket}
          closingBatch={(batchDTO: BatchDTO) => updateBatch(batchDTO, true)}
          clonePacket={navigateToPrefilledPacketRegistration}
        />
      }
    />
  );
}
