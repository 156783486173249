import UrstammButtonBase from '@components/utility-components/button/UrstammButtonBase';
import UrstammButtonSelect, { ButtonSelect } from '@components/utility-components/button/UrstammButtonSelect';
import UrstammFilePicker, {
  PickerMediaType,
  UploadType
} from '@components/utility-components/file-picker/UrstammFilePicker';
import UrstammIcon from '@components/utility-components/icon/UrstammIcon';
import UrstammInput from '@components/utility-components/input/UrstammInput';
import UrstammList from '@components/utility-components/list/UrstammList';
import UrstammModal from '@components/utility-components/modal/UrstammModal';
import UrstammModalSelect from '@components/utility-components/modal/UrstammModalSelect';
import { AlertHelper } from '@helpers/AlertHelper';
import { NumberHelper } from '@helpers/NumberHelper';
import { PlatformHelper } from '@helpers/PlatformHelper';
import { i18n } from '@i18n/i18n';
import { TrunkListItem } from '@redux/features/trunk/trunkSlice';
import { RootState } from '@redux/store';
import {
  Company,
  DeliverySheetCurrentStateEnum,
  DeliverySheetCurrentUrstammStateEnum,
  DeliverySheetDTO,
  DeliverySheetDTOCurrentStateEnum,
  DeliverySheetDTOCurrentUrstammStateEnum,
  DeliverySheetDTORecordingTypeEnum,
  DeliverySheetRecordingTypeEnum,
  ExportDeliverySheetDeliverySheetExportTypeEnum,
  Trunk
} from '@services/apis/generated';
import * as DocumentPicker from 'expo-document-picker';
import * as FileSystem from 'expo-file-system';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Linking, ScrollView, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import Collapsible from 'react-native-collapsible';
import { useSelector } from 'react-redux';
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import {
  getColorByDeliverySheetState,
  getTextByDeliverySheetUrstammState,
  getTextByDsRecordingType,
  loggingStateIsClosed
} from '../../../utils/classes/UrstammUtilityCurrentState';
import {
  urstammUtilDisplayError,
  urstammUtilUpdateForm,
  urstammUtilValidator,
  urstammUtilValidatorInError
} from '../../../utils/classes/UrstammUtilityForm';
import {
  canEditByDSCurrentState,
  canEditDSRecordingType,
  dsCurrentStatusIsOneOf,
  loggedUserIsASawmill,
  loggingIsClosed
} from '../../../utils/classes/UrstammUtilityFunctions';
import { buildDeliverySheetExportUrl } from '../../../utils/classes/UrstammUtilityImage';
import { ColorTheme } from '../../../utils/styles/ColorTheme';
import {
  lightGreenBorderWhiteBGButton,
  mainGreenButton,
  mainGreenButtonSelectActive
} from '../../../utils/styles/UrstammButtonModelStyle';
import {
  UrstammStyle,
  UrstammStyleButton,
  UrstammStyleCollapsible,
  UrstammStyleContainer,
  UrstammStyleLayout,
  UrstammStyleList,
  UrstammStyleModal
} from '../../../utils/styles/UrstammStyle';
import TrunkListView from '../trunk/TrunkListView';
import { DeliverySheetForm } from './DeliverySheetRegistrationView';

export interface DeliverySheetPhotoForm {
  base64File?: string;
  longitude?: string;
  latitude?: string;
  photoCreationDate?: Date;
}

export default function DeliverySheetDetailsView(props: {
  navigation: any;
  deliverySheetSelected: DeliverySheetDTO;
  submitDeliverySheetForm: (form: any) => void;
  uploadImage?: (photo: DeliverySheetPhotoForm) => void;
  uriMultiple?: string[];
  sawmillList?: Company[];
  moreItemsSawmill: () => void;
  trunkList: TrunkListItem[];
  trunkSelected: (trunkSelected: Trunk) => void;
  applyOrder: (orderSelected: ButtonSelect[]) => void;
  showSortBy: boolean;
  closeShowSortBy: () => void;
  validate: (validateAllAndIgnoreChanges: boolean) => void;
  validateTrunkSwitched: (trunk: TrunkListItem) => void;
  copyDeliverySheetSelected: () => void;
  importXml: (base64: string) => void;
}) {
  const rdxUserExtendedMe = useSelector((state: RootState) => state.persistedReducer.user.extendedMe);
  const [deliverySheetSelected, setDeliverySheetSelected] = useState<DeliverySheetDTO>();

  const [previewMultiple, setPreviewMultiple] = useState<string[]>([]);
  const [showSawmillListSelect, setShowSawmillListSelect] = useState<boolean>(false);
  const [openClosePdfModal, setOpenClosePdfModal] = useState<boolean>(false);
  const [pdfText, setPdfText] = useState<string>('');

  const [deliverySheetForm, setDeliverySheetForm] = useStateWithCallbackLazy<DeliverySheetForm>({
    deliverySheet: { comment: '' }
  });

  const [deliverySheetImageForm, setDeliverySheetImageForm] = useStateWithCallbackLazy<DeliverySheetPhotoForm[]>([]);

  const [deliverySheetRecordingType, setDeliverySheetRecordingType] = useState<ButtonSelect[]>([
    {
      text: i18n.t('views.tree.multiple_tree'),
      enum: DeliverySheetRecordingTypeEnum.Multiple,
      active: false
    },
    {
      text: i18n.t('views.tree.date'),
      enum: DeliverySheetRecordingTypeEnum.Date,
      active: false
    },
    {
      text: i18n.t('views.tree.single_tree'),
      enum: DeliverySheetRecordingTypeEnum.Single,
      active: false
    }
  ]);

  const [collapsed, setCollapsed] = React.useState<boolean>(true);

  useEffect(() => {
    if (props.deliverySheetSelected) {
      setDeliverySheetSelected(props.deliverySheetSelected);
      activeDeliverySheetRecordingType(props.deliverySheetSelected?.recordingType!);

      //Update form by DS selected
      setDeliverySheetForm(
        prevState => ({
          ...prevState,
          deliverySheet: {
            id: props.deliverySheetSelected.id,
            name: props.deliverySheetSelected.name,
            comment: props.deliverySheetSelected?.comment,
            sawmill: props.deliverySheetSelected?.sawmill,
            forestry: props.deliverySheetSelected?.forestry,
            recordingType: props.deliverySheetSelected?.recordingType,
            amountOfTruckJourneys: props.deliverySheetSelected?.amountOfTruckJourneys
          }
        }),
        () => {}
      );
    }
  }, [props.deliverySheetSelected]);

  useEffect(() => {
    if (props.uriMultiple && props.uriMultiple.length > 0) {
      setPreviewMultiple(props.uriMultiple);
    }
  }, [props.uriMultiple]);

  /**
   * FORM UPDATE
   */
  const updateDeliverySheetForm = (key: string, value: any, mustValidate?: boolean): void => {
    let form = urstammUtilUpdateForm(key, value, deliverySheetForm, 'deliverySheet');
    setDeliverySheetForm(
      prev => ({ ...prev, deliverySheet: form['deliverySheet'] }),
      mustValidate ? updatedDeliverySheetForm => validate(value, key, updatedDeliverySheetForm) : () => {}
    );
  };

  const updatePhoto = (base64: string, location: any, mustValidate?: boolean): void => {
    let photo: DeliverySheetPhotoForm = {
      base64File: base64,
      longitude: location.coords.longitude,
      latitude: location.coords.latitude,
      photoCreationDate: new Date()
    };
    setDeliverySheetImageForm(
      prev => [...prev, photo],
      deliverySheetImageFormUpdated => {}
    );
    props.uploadImage ? props.uploadImage(photo) : null;
  };

  const [saveDisabled, setSaveDisabled] = useState<boolean>(true);

  //Validators
  const validators = useState<any>({
    name: { required: true },
    amountOfTruckJourneys: { numeric: true, max3digits: true }
  });

  const [errors, setErrors] = useStateWithCallbackLazy<any>({
    name: [],
    amountOfTruckJourneys: []
  });

  const validate = (value: string, key: string, updatedDeliverySheetForm: DeliverySheetForm) => {
    let errorList = urstammUtilValidator(value, key, validators);
    setErrors(
      (state: any) => ({ ...state, [key]: errorList }),
      errorsUpdated => saveButtonDisabled(errorsUpdated, updatedDeliverySheetForm)
    );
  };

  const saveButtonDisabled = (errorsUpdated, updatedDeliverySheetForm: DeliverySheetForm) => {
    let disabled: boolean = false;
    if (urstammUtilValidatorInError(errorsUpdated) || updatedDeliverySheetForm.deliverySheet.name?.length == 0) {
      disabled = true;
    }
    return setSaveDisabled(disabled);
  };

  const activeDeliverySheetRecordingType = (
    deliverySheetRecordingTypeEnum: DeliverySheetDTORecordingTypeEnum
  ): void => {
    updateDeliverySheetForm('recordingType', deliverySheetRecordingTypeEnum);

    deliverySheetRecordingType.forEach(type => {
      type.active = type.enum == deliverySheetRecordingTypeEnum ? true : false;
    });
    setDeliverySheetRecordingType([...deliverySheetRecordingType]);
  };

  const deletePhoto = async (file: any) => {
    let fileBase64 = await FileSystem.readAsStringAsync(file, {
      encoding: 'base64'
    });
    setDeliverySheetImageForm(
      prevForm => prevForm.filter(val => val.base64File != fileBase64),
      deliverySheetImageFormUpdated => {
        // console.log(deliverySheetImageFormUpdated.length, 'delete');
      }
    );
  };

  const uploadDocument = async () => {
    let fileBase64: string = '';

    const result: any = await DocumentPicker.getDocumentAsync({
      type: 'text/xml',
      multiple: false
    }).then(async (document: any) => {
      if (PlatformHelper.isWeb()) {
        fileBase64 = document.uri.split(',')[1];
      } else {
        fileBase64 = await FileSystem.readAsStringAsync(document.uri, {
          encoding: 'base64'
        });
      }
      props.importXml(fileBase64);
    });
  };

  const exportXml = async () => {
    let documentUrl = await buildDeliverySheetExportUrl(
      props.deliverySheetSelected,
      ExportDeliverySheetDeliverySheetExportTypeEnum.Xml
    );
    if (documentUrl) {
      Linking.canOpenURL(documentUrl).then(supported => {
        if (supported) {
          Linking.openURL(documentUrl);
        } else {
          AlertHelper.showSimpleErrorAlert();
        }
      });
    }
  };

  const exportPdf = async () => {
    let documentUrl = await buildDeliverySheetExportUrl(
      props.deliverySheetSelected,
      ExportDeliverySheetDeliverySheetExportTypeEnum.Pdf,
      pdfText
    );
    if (documentUrl) {
      Linking.canOpenURL(documentUrl).then(supported => {
        if (supported) {
          Linking.openURL(documentUrl);
        } else {
          AlertHelper.showSimpleErrorAlert();
        }
      });
    }
    setPdfText('');
  };

  const canEditDSField = (field: string, deliverySheet?: DeliverySheetDTO) => {
    if (!deliverySheet) return false;
    const isSawmill = loggedUserIsASawmill(rdxUserExtendedMe);
    const dsState = deliverySheet.currentState!;
    const dsUrstammState = deliverySheet.currentUrstammState!;
    const loggingState = deliverySheet.logging?.currentState!;
    const dscuse = DeliverySheetCurrentUrstammStateEnum;
    const dscse = DeliverySheetCurrentStateEnum;
    switch (field) {
      case 'amountOfTruckJourneys':
        if (isSawmill) {
          return (
            !loggingStateIsClosed(loggingState) &&
            dsCurrentStatusIsOneOf(dsUrstammState, dscuse.Delivered, dscuse.InProgress)
          );
        } else {
          // console.log(loggingState, dsUrstammState, dsState)
          return (
            !loggingStateIsClosed(loggingState) &&
            dsCurrentStatusIsOneOf(dsUrstammState, dscuse.Validated, dscuse.InProgress)
          );
        }
      case 'comment':
      case 'name':
        return canEditByDSCurrentState(dsUrstammState, loggingState);
      case 'recordingType':
        return canEditDSRecordingType(deliverySheet);
      default:
        return false;
    }
  };

  const canSaveDS = (dsForm: DeliverySheetForm, deliverySheet?: DeliverySheetDTO) => {
    if (!deliverySheet) return false;
    return canEditDSField('amountOfTruckJourneys', deliverySheet);
  };

  return (
    <View style={UrstammStyle.flex1}>
      <ScrollView
        keyboardShouldPersistTaps="always"
        contentContainerStyle={[{ flexGrow: 1, flexDirection: 'column', marginBottom: 28 }]}>
        {/**Status and creation date */}
        <View style={[UrstammStyleLayout.formElementContainer]}>
          <View style={[UrstammStyleContainer.row1SpaceBetween, UrstammStyle.wrap]}>
            {deliverySheetSelected?.currentState && (
              <View style={[UrstammStyle.flexRow, UrstammStyle.paddingBottom16]}>
                <Text
                  style={[
                    UrstammStyleContainer.statusCreationDateText,
                    getColorByDeliverySheetState(deliverySheetSelected.currentState)
                  ]}>
                  {getTextByDeliverySheetUrstammState(deliverySheetSelected.currentUrstammState!)}
                </Text>
              </View>
            )}
            {deliverySheetSelected?.creationDate ? (
              <View style={[UrstammStyle.flexRow, UrstammStyle.flexEndX, { paddingBottom: 10 }]}>
                <Text style={UrstammStyle.titleStyle}>{i18n.t('generics.creation') + ' '}</Text>
                <Text style={UrstammStyle.dataStyle}>
                  {moment(deliverySheetSelected.creationDate).format('DD/MM/yyyy HH:mm')}
                </Text>
              </View>
            ) : null}
          </View>
        </View>

        <View style={[UrstammStyleLayout.formElementContainer]}>
          <View style={[UrstammStyleContainer.row1SpaceBetween, UrstammStyle.wrap]}>
            {deliverySheetSelected?.sumCubage ? (
              <View style={[UrstammStyle.flex1, UrstammStyle.flexRow]}>
                <Text style={UrstammStyle.titleStyle}>{i18n.t('generics.sum_cubage') + ': '}</Text>
                <Text style={UrstammStyle.dataStyle}>
                  {NumberHelper.roundWithThreeDecimals(deliverySheetSelected?.sumCubage)}
                </Text>
              </View>
            ) : null}
            {deliverySheetSelected?.trunks ? (
              <View style={[UrstammStyle.flexRow, UrstammStyle.flexEndX, { paddingBottom: 10 }]}>
                <Text style={UrstammStyle.titleStyle}>{i18n.t('generics.trunks') + ': '}</Text>
                <Text style={UrstammStyle.dataStyle}>{deliverySheetSelected?.trunks.length}</Text>
              </View>
            ) : null}
          </View>
        </View>

        {/**Logging + sawmill information */}
        <View style={UrstammStyleLayout.formElementContainerPd8}>
          <View style={[UrstammStyleContainer.row1SpaceBetween, UrstammStyle.wrap]}>
            {deliverySheetSelected?.logging ? (
              <View style={[UrstammStyle.flexRow, UrstammStyle.paddingBottom4, UrstammStyle.wrap]}>
                <Text style={UrstammStyle.titleStyle}>{i18n.t('generics.logging') + ': '}</Text>
                <Text style={[UrstammStyle.dataStyle]}>{deliverySheetSelected?.logging.name}</Text>
              </View>
            ) : null}
            {deliverySheetSelected?.pile ? (
              <View style={[UrstammStyle.flexRow, UrstammStyle.wrap]}>
                <Text style={UrstammStyle.titleStyle}>{i18n.t('generics.pile') + ': '}</Text>
                <Text style={UrstammStyle.dataStyle}>{deliverySheetSelected?.pile.name}</Text>
              </View>
            ) : null}
          </View>
        </View>

        <View style={UrstammStyleLayout.formElementContainerPd8}>
          <View style={[UrstammStyleContainer.row1SpaceBetween, UrstammStyle.wrap]}>
            {deliverySheetSelected?.sawmill ? (
              <View style={[UrstammStyle.flexRow, UrstammStyle.paddingBottom4]}>
                <Text style={UrstammStyle.titleStyle}>{i18n.t('generics.sawmill') + ': '}</Text>
                <Text style={[UrstammStyle.dataStyle]}>{deliverySheetSelected?.sawmill.name}</Text>
              </View>
            ) : null}
            {deliverySheetSelected?.recordingType ? (
              <View style={UrstammStyle.flexRow}>
                <Text style={UrstammStyle.titleStyle}>{i18n.t('generics.recording_type') + ': '}</Text>
                <Text style={UrstammStyle.dataStyle}>
                  {getTextByDsRecordingType(deliverySheetSelected?.recordingType)}
                </Text>
              </View>
            ) : null}
          </View>
        </View>

        {/**Forestry information */}
        {deliverySheetSelected?.forestry ? (
          <View style={[UrstammStyleLayout.formElementContainerPd8]}>
            <View style={UrstammStyleContainer.row1SpaceBetween}>
              <View style={[UrstammStyle.flex1, UrstammStyle.flexRow]}>
                <Text style={UrstammStyle.titleStyle}>{i18n.t('generics.forestry') + ': '}</Text>
                <Text style={UrstammStyle.dataStyle}>{deliverySheetSelected?.forestry.name}</Text>
              </View>
            </View>
          </View>
        ) : null}

        {/**Sawmill information */}
        {deliverySheetSelected?.comment ? (
          <View style={[UrstammStyleLayout.formElementContainerPd8]}>
            <View style={UrstammStyleContainer.row1SpaceBetween}>
              <View style={[UrstammStyle.flex1, UrstammStyle.flexRow]}>
                <Text style={UrstammStyle.titleStyle}>{i18n.t('generics.comment') + ': '}</Text>
                <Text style={UrstammStyle.dataStyle}>{deliverySheetSelected?.comment}</Text>
              </View>
            </View>
          </View>
        ) : null}

        <View style={[UrstammStyleLayout.formElementContainer]}>
          <View style={[UrstammStyleContainer.row1SpaceBetween, UrstammStyle.wrap]}>
            {deliverySheetSelected?.amountOfTruckJourneys ? (
              <View style={[UrstammStyle.flex1, UrstammStyle.flexRow]}>
                <Text style={UrstammStyle.titleStyle}>{i18n.t('generics.amountOfTruckJourneys') + ': '}</Text>
                <Text style={UrstammStyle.dataStyle}>
                  {NumberHelper.roundWithThreeDecimals(deliverySheetSelected?.amountOfTruckJourneys)}
                </Text>
              </View>
            ) : null}
          </View>
        </View>

        <View style={UrstammStyle.divider} />

        {/**Import xml DS */}
        {props.deliverySheetSelected &&
        props.deliverySheetSelected.currentUrstammState == DeliverySheetDTOCurrentUrstammStateEnum.Delivered &&
        props.deliverySheetSelected.trunks?.length == 0 ? (
          <View style={{ marginTop: 8 }}>
            <UrstammButtonBase
              testID={'import_delivery_sheet_xml'}
              text={i18n.t('generics.import_xml')}
              style={mainGreenButton}
              onSubmit={uploadDocument}
            />
          </View>
        ) : null}

        {/**Export xml DS */}
        {props.deliverySheetSelected &&
        props.deliverySheetSelected?.currentState == DeliverySheetDTOCurrentStateEnum.Closed &&
        !loggedUserIsASawmill(rdxUserExtendedMe) ? (
          <View style={{ marginTop: 8 }}>
            <UrstammButtonBase
              testID={'export_delivery_sheet_xml'}
              text={i18n.t('generics.export_xml')}
              style={mainGreenButton}
              onSubmit={exportXml}
            />
          </View>
        ) : null}

        {/**Export pdf DS */}
        {props.deliverySheetSelected &&
        props.deliverySheetSelected?.currentState == DeliverySheetDTOCurrentStateEnum.Closed ? (
          <View style={{ marginTop: 8 }}>
            <UrstammButtonBase
              testID={'export_delivery_sheet_pdf'}
              text={i18n.t('generics.export_pdf')}
              style={mainGreenButton}
              //onSubmit={exportPdf}
              onSubmit={() => setOpenClosePdfModal(true)}
            />
          </View>
        ) : null}

        {/**Edit DS Collapsible */}
        <View style={{ marginTop: 8 }}>
          <View
            style={[
              UrstammStyleButton.smallBaseButtonStructure,
              { backgroundColor: ColorTheme.yellow },
              UrstammStyle.baseShadow
            ]}>
            <View style={UrstammStyleCollapsible.selectMainContainer}>
              <TouchableOpacity
                style={UrstammStyleCollapsible.selectCollapsible}
                onPress={() => setCollapsed(!collapsed)}>
                <Text style={UrstammStyleButton.baseButtonBWStyle}>{i18n.t('generics.edit')}</Text>

                <UrstammIcon name={collapsed ? 'angle-down' : 'angle-up'} size={24} color={'white'} />
              </TouchableOpacity>
            </View>
          </View>

          <View style={[{ marginTop: 4 }]}>
            <Collapsible
              duration={500}
              easing={'easeInBounce'}
              collapsed={collapsed}
              style={UrstammStyleCollapsible.containerCollapsible}>
              <ScrollView
                keyboardShouldPersistTaps="always"
                contentContainerStyle={[
                  {
                    flexGrow: 1,
                    flexDirection: 'column',
                    paddingBottom: 40
                  }
                ]}>
                {/** Name */}
                <View style={UrstammStyleLayout.formElementContainerGrw1}>
                  <UrstammInput
                    testID="delivery_sheet_name"
                    style={{
                      containerViewStyle: UrstammStyleContainer.inputContainer,
                      textStyle: UrstammStyle.formBaseLDarkGrey
                    }}
                    icon={{ showIcon: false }}
                    onChangeText={(text: string) => {
                      updateDeliverySheetForm('name', text, true);
                    }}
                    placeholder={i18n.t(
                      'views.delivery_sheet.delivery_sheet_registration.insert_delivery_sheet_name_here'
                    )}
                    textInputTitle={i18n.t('generics.name')}
                    editable={canEditDSField('name', deliverySheetSelected)}
                    value={deliverySheetForm.deliverySheet?.name ? deliverySheetForm.deliverySheet.name : ''}
                    mandatory={true}
                  />

                  {/**Validator */}
                  {errors['name'] && errors['name'].length > 0
                    ? errors['name'].map((error, idx) => {
                        return urstammUtilDisplayError(error, idx);
                      })
                    : null}
                </View>

                {/** recordingType*/}
                {deliverySheetSelected && (!deliverySheetSelected?.recordingType || props.trunkList?.length == 0) ? (
                  <View style={UrstammStyleLayout.formElementContainerGrw1}>
                    <UrstammButtonSelect
                      testID={'recording_type_button_select'}
                      buttonSelectTitle={i18n.t('generics.choose_recording_type')}
                      options={deliverySheetRecordingType}
                      style={mainGreenButtonSelectActive}
                      mandatory={true}
                      disabled={!canEditDSField('recordingType', deliverySheetSelected)}
                      onSubmit={(data: ButtonSelect) => {
                        activeDeliverySheetRecordingType(data.enum);
                      }}
                    />
                  </View>
                ) : null}

                {/**Delivery sheet image */}
                {loggedUserIsASawmill(rdxUserExtendedMe) ? (
                  <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
                    <UrstammFilePicker
                      testID={'delivery_sheet_image'}
                      inputStyle={UrstammStyleContainer.cameraContainer}
                      fontStyle={UrstammStyle.formBaseLDarkGrey}
                      icon={{
                        showIcon: true,
                        iconName: 'camera',
                        iconColor: ColorTheme.lightGreen
                      }}
                      placeholder={i18n.t('generics.upload_photo_file')}
                      textInputTitle={i18n.t('generics.upload_photo_file')}
                      uriMultiple={previewMultiple ? previewMultiple : undefined}
                      uploadType={UploadType.camera}
                      multiple={true}
                      disabled={
                        loggingStateIsClosed(deliverySheetSelected?.logging?.currentState!) ||
                        deliverySheetSelected?.currentUrstammState == DeliverySheetDTOCurrentUrstammStateEnum.Closed ||
                        !loggedUserIsASawmill(rdxUserExtendedMe)
                      }
                      mandatory={false}
                      mediaTypeUploaded={PickerMediaType.Image}
                      deleteBase64={(file: any) => {
                        deletePhoto(file);
                      }}
                      saveBase64={(base64: string, location: any) => updatePhoto(base64, location, false)}
                    />
                  </View>
                ) : null}

                {/** Comment */}
                <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
                  <UrstammInput
                    testID="delivery_sheet_comment"
                    style={{
                      containerViewStyle: UrstammStyleContainer.textareaContainer,
                      textStyle: UrstammStyle.formBaseLDarkGrey
                    }}
                    icon={{ showIcon: false }}
                    onChangeText={(text: string) => updateDeliverySheetForm('comment', text)}
                    placeholder={i18n.t('generics.insert_comment_here')}
                    textInputTitle={i18n.t('generics.comment')}
                    editable={canEditDSField('comment', deliverySheetSelected)}
                    value={deliverySheetForm.deliverySheet?.comment ? deliverySheetForm.deliverySheet.comment : ''}
                    multiline={true}
                  />
                </View>

                {/** Amount of truck journey */}
                <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
                  <UrstammInput
                    testID="delivery_sheet_amount_of_truck_journeys"
                    style={{
                      containerViewStyle: UrstammStyleContainer.inputContainer,
                      textStyle: UrstammStyle.formBaseLDarkGrey
                    }}
                    value={
                      deliverySheetForm.deliverySheet.amountOfTruckJourneys ||
                      deliverySheetForm.deliverySheet.amountOfTruckJourneys == 0
                        ? deliverySheetForm.deliverySheet.amountOfTruckJourneys.toString()
                        : ''
                    }
                    editable={canEditDSField('amountOfTruckJourneys', deliverySheetSelected)}
                    icon={{ showIcon: false }}
                    onChangeText={(text: Number) => updateDeliverySheetForm('amountOfTruckJourneys', text, true)}
                    placeholder={i18n.t(
                      'views.delivery_sheet.delivery_sheet_registration.insert_amount_of_truck_journeys_here'
                    )}
                    textInputTitle={i18n.t('views.delivery_sheet.delivery_sheet_registration.amount_of_truck_journeys')}
                    keyboardType={'numeric'}
                    mandatory={false}
                  />
                </View>

                {props.deliverySheetSelected &&
                !loggedUserIsASawmill(rdxUserExtendedMe) &&
                (props.deliverySheetSelected?.sentToSawmill == undefined ||
                  !props.deliverySheetSelected?.sentToSawmill) ? (
                  <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
                    {/**Title */}
                    <View style={UrstammStyle.textTitleContainerFlex}>
                      <Text style={UrstammStyle.textTitle}>
                        {i18n.t('views.delivery_sheet.delivery_sheet_registration.choose_sawmill')}
                      </Text>
                    </View>

                    <UrstammButtonBase
                      testID={'choose_sawmill'}
                      text={
                        deliverySheetForm.deliverySheet?.sawmill
                          ? deliverySheetForm.deliverySheet?.sawmill.name
                          : props.deliverySheetSelected?.sawmill?.name
                          ? props.deliverySheetSelected?.sawmill?.name
                          : i18n.t('views.delivery_sheet.delivery_sheet_registration.choose_sawmill')
                      }
                      style={lightGreenBorderWhiteBGButton}
                      onSubmit={() => setShowSawmillListSelect(true)}
                      disabled={false}
                    />

                    {/**Sawmill Modal select */}
                    {showSawmillListSelect ? (
                      <UrstammModalSelect
                        testID={'modal_sawmill_select'}
                        visible={showSawmillListSelect}
                        text={{
                          textInputTitle: i18n.t('views.delivery_sheet.delivery_sheet_registration.choose_sawmill'),
                          textStyle: UrstammStyle.textTitle,
                          textInputSubTitle: i18n.t(
                            'views.delivery_sheet.delivery_sheet_registration.attention_sawmill_cannot_be_changed_afterwards'
                          ),
                          textSubTitleStyle: UrstammStyle.attentionSubTitle
                        }}
                        closeModal={() => setShowSawmillListSelect(false)}
                        listJSX={
                          <UrstammList
                            elementSelected={(sawmillSelected: any) => {
                              updateDeliverySheetForm('sawmill', sawmillSelected.item, false);
                              setShowSawmillListSelect(false);
                            }}
                            testID={'sawmill_list'}
                            textForEmptyList={i18n.t(
                              'views.delivery_sheet.delivery_sheet_registration.sawmill_not_found'
                            )}
                            list={props.sawmillList}
                            listStyle={{
                              mainTopContainerViewStyle: UrstammStyleList.mainCardContainerRowCenter,
                              mainTitleTextStyle: UrstammStyleList.cardMainTitleTextStyle,
                              bottomContainerViewStyle: UrstammStyleList.cardSubData,
                              bottomDataTextStyle: UrstammStyleList.cardSubTitleTextStyle,
                              paddingBottom: 4
                            }}
                            listSubData={undefined}
                            filterBy={undefined}
                            moreItems={props.moreItemsSawmill}
                            filterList={true}
                            showHash={false}
                          />
                        }
                      />
                    ) : null}
                  </View>
                ) : null}

                <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
                  <UrstammButtonBase
                    testID={'save_delivery_sheet'}
                    text={i18n.t('generics.save')}
                    style={mainGreenButton}
                    onSubmit={() => props.submitDeliverySheetForm(deliverySheetForm)}
                    disabled={!canSaveDS(deliverySheetForm, deliverySheetSelected)}
                  />
                </View>
              </ScrollView>
            </Collapsible>
          </View>
        </View>

        {deliverySheetSelected ? (
          <View style={UrstammStyle.flex1}>
            <TrunkListView
              navigation={props.navigation}
              deliverySheetSelected={deliverySheetSelected!}
              trunkList={props.trunkList}
              trunkSelected={trunkSelected => props.trunkSelected(trunkSelected)}
              showSortBy={props.showSortBy}
              closeShowSortBy={props.closeShowSortBy}
              applyOrder={props.applyOrder}
              validate={props.validate}
              validateTrunkSwitched={props.validateTrunkSwitched}
            />
          </View>
        ) : null}

        {false &&
        props.deliverySheetSelected &&
        loggedUserIsASawmill(rdxUserExtendedMe) &&
        (!props.trunkList || props.trunkList.length == 0) ? (
          <View style={[]}>
            <UrstammButtonBase
              testID={'import_delivery_sheet'}
              text={i18n.t('generics.import')}
              style={mainGreenButton}
              onSubmit={() => props.submitDeliverySheetForm(deliverySheetForm)}
            />
          </View>
        ) : null}
        {openClosePdfModal && (
          <UrstammModal
            testID={'pdf_modal'}
            text={i18n.t('views.delivery_sheet.modal_pdf_text')}
            textStyle={UrstammStyleModal.formBtextModalStyle}
            visible={openClosePdfModal}
            buttonText={{
              left: i18n.t('utility_components.modal.cancel'),
              right: i18n.t('utility_components.modal.confirm')
            }}
            textInput={true}
            onChangeText={data => setPdfText(data)}
            confirm={(confirmed: boolean) => {
              confirmed ? exportPdf() : null, setOpenClosePdfModal(false);
            }}
          />
        )}

        {/**Copy DS */}
        {props.deliverySheetSelected &&
        loggedUserIsASawmill(rdxUserExtendedMe) &&
        deliverySheetSelected?.recordingType == DeliverySheetDTORecordingTypeEnum.Multiple &&
        deliverySheetSelected?.logging &&
        !loggingIsClosed(deliverySheetSelected?.logging) ? (
          <View style={{ marginTop: 38 }}>
            <UrstammButtonBase
              testID={'copy_delivery_sheet'}
              text={i18n.t('generics.copy_delivery_sheet')}
              style={mainGreenButton}
              onSubmit={props.copyDeliverySheetSelected}
            />
          </View>
        ) : null}
      </ScrollView>
    </View>
  );
}

const styles = StyleSheet.create({});
