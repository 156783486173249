import { NetworkHelper } from '@helpers/NetworkHelper';
import { PlatformHelper } from '@helpers/PlatformHelper';
import { i18n } from '@i18n/i18n';
import { CameraType } from 'expo-camera';
import * as DocumentPicker from 'expo-document-picker';
import * as FileSystem from 'expo-file-system';
import * as ImageManipulator from 'expo-image-manipulator';
import * as ImagePicker from 'expo-image-picker';
import * as Location from 'expo-location';
import React, { useEffect, useState } from 'react';
import {
  ActivityIndicator,
  Image,
  Modal,
  Platform,
  StyleProp,
  StyleSheet,
  Text,
  TextStyle,
  TouchableOpacity,
  View,
  ViewStyle
} from 'react-native';
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import Layout from '../../../config/Layout';
import { getMandatorySymbol, urstammUtilOpenMaps } from '../../../utils/classes/UrstammUtilityFunctions';
import { ColorTheme } from '../../../utils/styles/ColorTheme';
import { UrstammFontFamily, UrstammFontsize } from '../../../utils/styles/UrstammFont';
import { UrstammStyle, UrstammStyleModal } from '../../../utils/styles/UrstammStyle';
import UrstammIcon, { UrstammIconProps } from '../icon/UrstammIcon';
import UrstammCameraPicker from './UrstammCameraPicker';

export enum UploadType {
  all = 'ALL',
  camera = 'CAMERA'
}

export enum PickerMediaType {
  Pdf = 'PDF',
  Image = 'IMAGE'
}

export type LocationPreviewProps = Pick<Location.LocationObject['coords'], 'latitude' | 'longitude'>;

export default function UrstammFilePicker(props: {
  style?: StyleProp<ViewStyle>;
  inputStyle?: StyleProp<ViewStyle>;
  fontStyle?: StyleProp<TextStyle>;
  placeholder?: string;
  testID: string;
  icon: UrstammIconProps;
  textInputTitle?: string;
  mandatory?: boolean;
  multiple?: boolean;
  locationProps?: LocationPreviewProps;
  uri?: string;
  uriMultiple?: string[];
  uploadType: UploadType;
  saveBase64: (base64: any, location: any, uri?: any) => void;
  deleteBase64: (file?: any) => void;
  disabled?: boolean;
  clearPicker?: boolean;
  mediaTypePicked?: (type: string) => void;
  mediaTypeUploaded?: any;
  downloadDocument?: () => void;
}) {
  /**Location */
  const [location, setLocation] = useState<Location.LocationObject>();
  const [showLocationModal, setShowLocationModal] = useState<boolean>(false);
  const [isLocalizationFinished, setIsLocalizationFinished] = useState<boolean>(false);

  const [errorMsg, setErrorMsg] = useState<string>('');

  const [image, setImage] = useState<string>('');
  const [imageMultiple, setImageMultiple] = useStateWithCallbackLazy<string[]>([]);

  const [isDocument, setIsDocument] = useState<boolean>(false);
  const [hasPermission, setHasPermission] = useState(null);
  const [type, setType] = useState(CameraType.back);
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [showPreview, setShowPreview] = useState<boolean>(false);

  const [chooseMediaTypeState, setChooseMediaTypeState] = useState<boolean>(false);

  const [locationPreview, setLocationPreview] = useState<LocationPreviewProps | undefined>(undefined);

  useEffect(() => {
    (async () => {
      await ImagePicker.getCameraPermissionsAsync();
      await ImagePicker.getMediaLibraryPermissionsAsync();
    })();
  }, []);

  useEffect(() => {
    if (props.clearPicker) {
      setImage('');
      setImageMultiple([], () => {});
      setLocationPreview(undefined);
      setIsDocument(false);
    }
  }, [props.clearPicker]);

  //It used for the preview
  useEffect(() => {
    if (props.uri && props.mediaTypeUploaded == PickerMediaType.Image) {
      setImage(props.uri);
    }
    if (props.mediaTypeUploaded == PickerMediaType.Pdf) {
      setIsDocument(true);
    }
  }, [props.uri]);

  useEffect(() => {
    if (typeof props.locationProps?.latitude !== 'undefined' && typeof props.locationProps?.longitude !== 'undefined') {
      setLocationPreview({
        latitude: props.locationProps.latitude,
        longitude: props.locationProps.longitude
      });
    } else {
      setLocationPreview(undefined);
    }
  }, [props.locationProps]);

  //It used for the multiple Preview
  useEffect(() => {
    if (props.uriMultiple && props.mediaTypeUploaded == PickerMediaType.Image) {
      setImageMultiple(props.uriMultiple, () => {});
    }
    if (props.mediaTypeUploaded == PickerMediaType.Pdf) {
      setIsDocument(true);
    }
  }, [props.uriMultiple]);

  const getLocation = async () => {
    if (!PlatformHelper.isWeb() && props.uploadType != UploadType.all) {
      let { status } = await Location.requestForegroundPermissionsAsync();
      if (status !== 'granted') {
        setErrorMsg('Permission to access location was denied');
        return;
      }
      setIsLocalizationFinished(false);

      let networkOk = await NetworkHelper.isNetworkOk();
      const timeout = networkOk ? 6000 : 10000;

      let locationSubscribe = await Location.watchPositionAsync(
        {
          accuracy: Location.Accuracy.BestForNavigation,
          timeInterval: 700,
          distanceInterval: 1
        },
        location => {
          if (location) {
            // console.log('New location', location);
            setLocation(location);
            setLocationPreview({
              latitude: location.coords?.latitude,
              longitude: location.coords?.longitude
            });
            setShowLocationModal(false);
          }
        }
      );

      setTimeout(() => {
        setIsLocalizationFinished(true);
        locationSubscribe.remove();
      }, timeout);
    }
  };

  /**
   * First function invoked
   */
  const pickerManager = () => {
    if (!location && !PlatformHelper.isWeb()) {
      setShowLocationModal(!location);
    } else {
      if (props.uploadType == UploadType.all) {
        setChooseMediaTypeState(true); // Document or image
      } else {
        setModalVisible(true); // Open camera
      }
    }
  };

  //Selecting a photo from gallery.
  const pickImage = async () => {
    if (props.uploadType == UploadType.all) {
      // No permissions request is necessary for launching the image library
      let result = await ImagePicker.launchImageLibraryAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.Images,
        allowsEditing: true,
        quality: 1,
        base64: true
      });

      if (!result.cancelled) {
        if (props.multiple) {
          let imgMulti = result.uri!;
          setImageMultiple(
            prev => [...prev, imgMulti],
            () => {}
          );
        } else {
          setImage(result.uri!);
        }

        props.mediaTypePicked ? props.mediaTypePicked(PickerMediaType.Image) : null;
        props.saveBase64(result.base64, location);
        setChooseMediaTypeState(false);
      } else {
        setChooseMediaTypeState(false);
      }
    } else {
      setModalVisible(true);
      setChooseMediaTypeState(false);
    }
  };

  const uploadDocument = async () => {
    let fileBase64: string = '';

    const result: any = await DocumentPicker.getDocumentAsync({
      type: 'application/pdf',
      multiple: false
    }).then(async document => {
      if (document.type == 'cancel') {
        setChooseMediaTypeState(false);
      } else {
        setChooseMediaTypeState(false);

        if (PlatformHelper.isWeb()) {
          fileBase64 = document.uri.split(',')[1];
        } else {
          fileBase64 = await FileSystem.readAsStringAsync(document.uri, {
            encoding: 'base64'
          });
        }
        setIsDocument(true);
        setImage('');
        props.mediaTypePicked ? props.mediaTypePicked(PickerMediaType.Pdf) : null;
        props.saveBase64(fileBase64, location!);
      }
    });
    if (!result) setChooseMediaTypeState(false);
  };

  const closeCamera = () => {
    setModalVisible(false);
  };

  const takePhoto = async (imageCaptured: any) => {
    if (props.multiple) {
      let imgMulti = imageCaptured.uri;
      setImageMultiple(
        prev => [...prev, imgMulti],
        () => {}
      );
    } else {
      setImage(imageCaptured.uri);
    }
    props.mediaTypePicked ? props.mediaTypePicked(PickerMediaType.Image) : null;

    // compress file or image size
    const file = await ImageManipulator.manipulateAsync(imageCaptured.uri, [], { base64: true, compress: 0.2 });

    // console.log('file ...', file?.base64?.length);
    // console.log('old file ...', imageCaptured?.base64?.length);
    // console.log('diff ...', imageCaptured?.base64?.length - (file?.base64?.length || 0));

    props.saveBase64(file.base64, location!, file.uri);
  };

  const deleteFile = (element?: any) => {
    if (props.multiple) {
      setImageMultiple(
        prev => prev?.filter((data: any) => data != element),
        () => {}
      );
      setIsDocument(false);
      return props.deleteBase64(element);
    }
    setImage('');
    setLocationPreview(undefined);
    setIsDocument(false);
    return props.deleteBase64();
  };

  const showPreviewByMediaType = (data?: string) => {
    if (props.mediaTypeUploaded == PickerMediaType.Image) {
      setShowPreview(true);
      setImage(data!);
    } else {
      props.downloadDocument ? props.downloadDocument() : null;
    }
  };

  const isAnImage = (): boolean => {
    if (
      image.length > 0 ||
      (props.mediaTypeUploaded != PickerMediaType.Pdf && !isDocument) ||
      (imageMultiple && imageMultiple?.length > 0)
    )
      return true;
    return false;
  };

  const showCameraModal = async () => {
    props.uploadType == UploadType.camera ? await getLocation() : null;
    setModalVisible(true);
  };

  return (
    <View style={UrstammStyle.flexGrow1}>
      {/* Title */}
      {props.textInputTitle && (
        <View style={UrstammStyle.textTitleContainerFlex}>
          <Text style={UrstammStyle.textTitle}> {props.textInputTitle + getMandatorySymbol(props.mandatory!)} </Text>
        </View>
      )}

      <View style={[props.inputStyle]}>
        {/* File Picker */}
        <View style={[styles.uploadFileContainer]}>
          <Text style={styles.placeholderText}>{props.placeholder}</Text>
        </View>

        <View style={[UrstammStyle.flexRow, { justifyContent: 'flex-end' }]}>
          {/**Document */}
          {props.uploadType == UploadType.all ? (
            <TouchableOpacity style={styles.uploadFromFile} disabled={props.disabled} onPress={() => uploadDocument()}>
              {props.icon.showIcon && (
                <View style={[styles.cameraButtonContainer]}>
                  <UrstammIcon
                    name={'file'}
                    size={PlatformHelper.normalizeByDevice(24)}
                    color={props.icon.iconColor!}
                  />
                </View>
              )}
            </TouchableOpacity>
          ) : null}
          {/**Image */}
          {!PlatformHelper.isWeb() ? (
            <TouchableOpacity
              style={styles.uploadFromCamera}
              disabled={props.disabled}
              onPress={() => {
                //setType(type === CameraType.back ? CameraType.front : CameraType.back);
                showCameraModal();
              }}>
              {props.icon.showIcon && (
                <View style={[styles.cameraButtonContainer]}>
                  <UrstammIcon
                    name={props.icon.iconName}
                    size={PlatformHelper.normalizeByDevice(24)}
                    color={props.icon.iconColor!}
                  />
                </View>
              )}
            </TouchableOpacity>
          ) : null}
        </View>
      </View>

      {/* Camera Picker */}
      {modalVisible && (
        <UrstammCameraPicker
          testID={'logging_camera_picker'}
          openCamera={modalVisible}
          closeCamera={closeCamera}
          saveImage={imageCaptured => takePhoto(imageCaptured)}
          isLocalizationFinished={isLocalizationFinished}
          uploadType={props.uploadType}
        />
      )}

      {/* Managing image (or document) when it's uploaded */}
      {((image && image.length > 0) || isDocument) && !props.multiple ? (
        <View style={[styles.imagePreviewContainer]}>
          {/**Image preview */}
          <TouchableOpacity style={styles.imagePreview} onPress={() => showPreviewByMediaType(image)}>
            {isAnImage() ? (
              <Image source={{ uri: image }} style={{ width: 52, height: 52 }} />
            ) : (
              <Image source={require('../../../../assets/document-pdf-icon.png')} style={{ width: 40, height: 48 }} />
            )}
          </TouchableOpacity>

          {/**Delete button */}
          <View
            style={{
              width: PlatformHelper.normalizeByDevice(52),
              height: PlatformHelper.normalizeByDevice(52),
              alignSelf: 'center'
            }}>
            <TouchableOpacity
              style={[!props.disabled ? styles.deletePreviewContainer : styles.deletePreviewContainerDisabled]}
              onPress={deleteFile}
              disabled={props.disabled || Platform.OS == 'web'}>
              <UrstammIcon name={'trash'} size={PlatformHelper.normalizeByDevice(24)} color={'white'} />
            </TouchableOpacity>
          </View>
        </View>
      ) : imageMultiple && imageMultiple?.length > 0 ? (
        imageMultiple.map((data: any, idx) => {
          return (
            <View key={idx} style={[styles.imagePreviewContainer]}>
              {/**MULTIPLE */}

              {/**Image preview */}
              <TouchableOpacity style={styles.imagePreview} onPress={() => showPreviewByMediaType(data)}>
                {isAnImage() ? (
                  <Image source={{ uri: data }} style={{ width: 52, height: 52 }} />
                ) : (
                  <Image
                    source={require('../../../../assets/document-pdf-icon.png')}
                    style={{ width: 40, height: 48 }}
                  />
                )}
              </TouchableOpacity>

              {/**Delete button */}
              <View
                style={{
                  width: PlatformHelper.normalizeByDevice(52),
                  height: PlatformHelper.normalizeByDevice(52),
                  alignSelf: 'center'
                }}>
                <TouchableOpacity
                  style={[
                    styles.deletePreviewContainerDisabled,
                    props.multiple ? styles.deletePreviewContainerHidden : null
                  ]}
                  onPress={() => deleteFile(data)}
                  disabled={props.multiple}>
                  <UrstammIcon name={'trash'} size={24} color={'white'} />
                </TouchableOpacity>
              </View>
            </View>
          );
        })
      ) : null}

      {/* Preview modal */}
      {showPreview && (
        <View style={UrstammStyleModal.centeredView}>
          <Modal animationType="slide" transparent={true} visible={showPreview}>
            <View style={UrstammStyleModal.centeredView}>
              <View style={UrstammStyleModal.modalView}>
                <View style={[styles.cameraTopButton]}>
                  {/**Localization - (UploadType.all used only for Logging) */}
                  {!PlatformHelper.isWeb() && props.uploadType != UploadType.all ? (
                    <TouchableOpacity
                      style={[
                        styles.top_button,
                        UrstammStyle.baseShadow,
                        { justifyContent: 'center', alignItems: 'center' }
                      ]}
                      onPress={() =>
                        urstammUtilOpenMaps({
                          latitude: locationPreview?.latitude,
                          longitude: locationPreview?.longitude
                        })
                      }>
                      <UrstammIcon
                        name={!locationPreview ? 'search-location' : 'map-marker-alt'}
                        size={PlatformHelper.normalizeByDevice(24)}
                        color={!locationPreview ? ColorTheme.lightGrey : ColorTheme.mainGreen}
                        type={'FontAwesome5'}
                      />
                    </TouchableOpacity>
                  ) : null}
                  {/**Close Preview */}
                  <TouchableOpacity
                    style={[
                      styles.top_button,
                      UrstammStyle.baseShadow,
                      { justifyContent: 'center', alignItems: 'center' }
                    ]}
                    onPress={() => setShowPreview(false)}>
                    <View style={[styles.iconCloseContainer]}>
                      <UrstammIcon name={'close'} size={24} color={ColorTheme.mediumGrey} />
                    </View>
                  </TouchableOpacity>
                </View>

                {image.length > 0 && (
                  <Image
                    source={{ uri: image }}
                    style={{
                      width: Layout.window.width * 0.9,
                      height: Layout.window.height * 0.7
                    }}
                  />
                )}
              </View>
            </View>
          </Modal>
        </View>
      )}

      {/* Localization modal */}
      {showLocationModal && (
        <View style={UrstammStyleModal.centeredView}>
          <Modal animationType="slide" transparent={true} visible={showLocationModal}>
            <View style={UrstammStyleModal.centeredView}>
              <View style={UrstammStyleModal.modalView}>
                <View style={[styles.cameraTopButton]}>
                  {/**Close Preview */}
                  <TouchableOpacity
                    style={[styles.top_button, UrstammStyle.baseShadow, { alignSelf: 'flex-end' }]}
                    onPress={() => setShowLocationModal(false)}>
                    <View style={[styles.iconCloseContainer]}>
                      <UrstammIcon name={'close'} size={24} color={ColorTheme.mediumGrey} />
                    </View>
                  </TouchableOpacity>
                </View>

                <ActivityIndicator size="large" color={ColorTheme.mainGreen} />

                <Text style={[UrstammStyle.formBaseRMainGreen, { padding: 40 }]}>
                  {i18n.t('utility_components.photo_picker.no_location_found')}
                </Text>
              </View>
            </View>
          </Modal>
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  uploadFileContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    minHeight: 64
  },
  uploadFromFile: {
    flex: 0.1,
    minWidth: 48,
    minHeight: 48,
    borderWidth: 1,
    backgroundColor: 'white',
    borderRadius: 4,
    borderColor: ColorTheme.darkGrey,
    marginRight: 4
  },
  uploadFromCamera: {
    flex: 0.1,
    minWidth: 48,
    minHeight: 48,
    borderWidth: 1,
    backgroundColor: 'white',
    borderRadius: 4,
    borderColor: ColorTheme.darkGrey
  },
  cameraButtonContainer: {
    flex: 1,
    justifyContent: 'center',
    alignSelf: 'center'
  },
  imagePreview: {
    flex: 1,
    justifyContent: 'center'
  },
  imagePreviewContainer: {
    flex: 1,
    flexDirection: 'row',
    marginTop: 4,
    padding: 12,
    borderWidth: 1,
    borderColor: ColorTheme.lightGreen,
    borderRadius: 4,
    backgroundColor: 'white'
  },
  deletePreviewContainer: {
    flex: 1,
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    padding: 12,
    backgroundColor: ColorTheme.lightGreen,
    borderRadius: 8
  },
  deletePreviewContainerDisabled: {
    flex: 1,
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    padding: 12,
    backgroundColor: ColorTheme.lightGreen,
    borderRadius: 8,
    opacity: 0.5
  },
  deletePreviewContainerHidden: {
    display: 'none'
  },
  placeholderText: {
    color: ColorTheme.black,
    fontSize: PlatformHelper.normalizeByDevice(UrstammFontsize.baseFontSize),
    fontFamily: UrstammFontFamily.lightFont,
    paddingRight: 8,
    maxWidth: PlatformHelper.normalizeByDevice(150)
  },
  deletePreviewText: {
    color: 'white',
    fontSize: PlatformHelper.normalizeByDevice(UrstammFontsize.baseFontSize),
    fontFamily: UrstammFontFamily.lightFont
  },
  centeredView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 22
  },
  modalView: {
    margin: 20,
    backgroundColor: 'white',
    borderRadius: 20,
    padding: 35,
    alignItems: 'center',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5
  },
  button: {
    borderRadius: 20,
    padding: 10,
    elevation: 2
  },
  buttonOpen: {
    backgroundColor: '#F194FF'
  },
  buttonClose: {
    backgroundColor: '#2196F3'
  },
  textStyle: {
    color: 'white',
    fontWeight: 'bold',
    textAlign: 'center'
  },
  modalText: {
    marginBottom: 15,
    textAlign: 'center'
  },
  camera: {
    flex: 1,
    width: 280
  },
  cameraTopButton: {
    width: Layout.window.width * 0.9,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignSelf: 'flex-end'
  },
  top_button: {
    flex: 1,
    backgroundColor: ColorTheme.lightGrey,
    borderColor: ColorTheme.mainGreen,
    borderRadius: 8,
    padding: 8,
    marginBottom: 8,
    borderWidth: 1,
    zIndex: 2,
    elevation: 2,
    maxWidth: 40,
    alignSelf: 'flex-end'
  },
  iconCloseContainer: {
    minWidth: 20,
    justifyContent: 'center',
    alignSelf: 'center',
    alignItems: 'center'
  }
});
