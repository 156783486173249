import UrstammImageBack from '@components/images/corner/UrstammImageBack';
import UrstammImageMenu from '@components/images/corner/UrstammImageMenu';
import UrstammImagePlus from '@components/images/corner/UrstammImagePlus';
import BaseLayoutCorner, { BaseLayoutOptions } from '@components/layouts/base/BaseLayoutCorner';
import { ButtonSelect } from '@components/utility-components/button/UrstammButtonSelect';
import {
  applySavingToReference,
  deepCopyAndAddSelectedParameter,
  restoreListToReference,
  selectElementFormList
} from '@components/utility-components/modal/UrstammModalSelect';
import UrstammTitle from '@components/utility-components/title/UrstammTitle';
import ShippingNoteDetailsView from '@components/views/shipping-note/ShippingNoteDetailsView';
import { AlertHelper } from '@helpers/AlertHelper';
import { ErrorHelper } from '@helpers/ErrorHelper';
import { PlatformHelper } from '@helpers/PlatformHelper';
import { i18n } from '@i18n/i18n';
import { resetShippingNoteList } from '@redux/features/shipping-note/shippingNoteSlice';
import { changeLoaderStatus } from '@redux/features/loader/loaderSlice';
import { setPacketPage } from '@redux/features/packet/packetSlice';
import { RootState } from '@redux/store';
import {
  customShippingNoteResourceApi,
  customCompanyResourceApi,
  customBatchResourceApi,
  customPacketResourceApi,
  longListSize
} from '@services/apis/ApiConfiguration';
import {
  ShippingNote,
  ShippingNoteCurrentStateEnum,
  ShippingNoteDTO,
  Company,
  Packet,
  PacketCurrentStateEnum,
  PageCompany,
  BatchDTO,
  PageBatch,
  Batch,
  ShippingNoteDTOCurrentStateEnum,
  ShippingNoteStateUpdateDTOUpdatedShippingNoteStateEnumEnum
} from '@services/apis/generated';
import React, { useEffect, useState } from 'react';
import { Keyboard } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import { RootStackScreenProps, UrstammNavigationHelper } from '../../navigation/UrstammNavigationHelper';
import { generateSet, isCompanyMock, sleep } from '../../utils/classes/UrstammUtilityFunctions';
import { UrstammStyleCornerButton, UrstammStyleHeader, UrstammStyleLayout } from '../../utils/styles/UrstammStyle';
import { setBatchList, setBatchPage } from '@redux/features/batch/batchSlice';
import { createOnlyIdBatch, createOnlyIdCompany, createOnlyIdPacket } from '../../utils/classes/UrstammClassMapper';
import { ExternalCompany } from '@services/apis/generated/models/ExternalCompany';

export default function ShippingNoteDetailsContainer({
  navigation,
  route
}: RootStackScreenProps<'ShippingNoteDetails'>) {
  const rdxBatchPage = useSelector((state: RootState) => state.persistedReducer.batch.page);
  const rdxUserExtendedMe = useSelector((state: RootState) => state.persistedReducer.user.extendedMe);

  const rdxPacketPage = useSelector((state: RootState) => state.persistedReducer.packet.page);
  const rdxPacketTotalPages = useSelector((state: RootState) => state.persistedReducer.packet.totalPages);
  const rdxBatchTotalPages = useSelector((state: RootState) => state.persistedReducer.batch.totalPages);
  const dispatch = useDispatch();

  const [pktList, setPktList] = useStateWithCallbackLazy<any>(() => {});
  const [pktListReference, setPktListReference] = useState<any>();

  const [batchSelected, setBatchSelected] = useState<any>();
  const [processorSelected, setProcessorSelected] = useState<Company>();
  const [batchListState, setBatchListState] = useState<any>();
  const [batchListReference, setBatchListReference] = useState<any>();
  const [processorList, setProcessorList] = useState<Company[]>([]);
  //const [packetListState, setPacketListState] = useState<Packet[]>([]);
  const [shippingNoteList, setShippingNoteList] = useStateWithCallbackLazy<ShippingNote[]>([]);
  const [shippingNoteSelected, setShippingNoteSelected] = useState<ShippingNote>();
  const [shippingNoteDTO, setShippingNoteDTO] = useState<any>(() => {});
  const [resetForm, setResetForm] = useState<boolean>(false);
  const [packetList, setPacketList] = useStateWithCallbackLazy<any>(() => {});
  const [showSortBy, setShowSortBy] = useState<boolean>(false);

  let cornerOption: BaseLayoutOptions = {
    cornerTopLeft: {
      showCorner: true,
      text: i18n.t('utility_components.corner_button.back'),
      icon: (
        <UrstammImageBack width={PlatformHelper.normalizeByDevice(34)} height={PlatformHelper.normalizeByDevice(24)} />
      )
    },
    cornerTopRight: {
      showCorner: true,
      text: i18n.t('utility_components.corner_button.menu'),
      icon: (
        <UrstammImageMenu width={PlatformHelper.normalizeByDevice(34)} height={PlatformHelper.normalizeByDevice(24)} />
      )
    },
    cornerBottomLeft: {
      showCorner: false,
      text: i18n.t('utility_components.corner_button.sort_by'),
      icon: undefined
    },
    cornerBottomRight: {
      showCorner: false,
      text: undefined,
      icon: (
        <UrstammImagePlus width={PlatformHelper.normalizeByDevice(34)} height={PlatformHelper.normalizeByDevice(24)} />
      )
    }
  };

  useEffect(() => {
    customBatchResourceApi
      .getPageBatchesForMyCompany({
        customBatchCriteria: { currentState: {} },
        page: rdxBatchPage,
        sort: ['creationDate,desc'],
        size: longListSize
      })
      .then((batchList: PageBatch) => {
        dispatch(changeLoaderStatus(false));
        if (batchList) {
          dispatch(setBatchList(batchList.content));
          let list: BatchDTO[] = JSON.parse(JSON.stringify(batchList.content));

          setShippingNoteList(route.params?.shippingNoteList, () => {
            deepCopyAndAddSelectedParameter(list, setBatchListState, setBatchListReference);
          });
        }
      })
      .catch(async error => {
        dispatch(changeLoaderStatus(false));
        let errorMessage = await ErrorHelper.getServerMessageFromJsonOrResponse(error);
        let errorJson = await ErrorHelper.getDetailFromServerMessage(errorMessage);
        AlertHelper.showSimpleAlert('Error', errorJson);
      });
  }, []);

  useEffect(() => {
    if (batchSelected && processorSelected && shippingNoteDTO) {
      customPacketResourceApi
        .getPacketsByBatch({
          batchId: batchSelected.id,
          batchIdEquals: batchSelected.id,
          currentStateIn: [PacketCurrentStateEnum.Closed],
          shippingNoteIdEquals: shippingNoteDTO.id,
          distinct: true,
        })
        .then((packetList: Packet[]) => {
          packetList = packetList.filter(
            item =>
              (item.processor?.id == processorSelected?.id ||
                (isCompanyMock(item.processor) && isCompanyMock(processorSelected)))
          );
          setPacketsSelected(shippingNoteDTO, packetList);
        })
        .catch(async error => {
          dispatch(changeLoaderStatus(false));
          let errorMessage = await ErrorHelper.getServerMessageFromJsonOrResponse(error);
          let errorJson = await ErrorHelper.getDetailFromServerMessage(errorMessage);
          AlertHelper.showSimpleAlert('Error', errorJson);
        });
    }
  }, [batchSelected, processorSelected, shippingNoteDTO]);

  useEffect(() => {
    if (route.params?.shippingNoteSelected) {
      setShippingNoteSelected(route.params?.shippingNoteSelected);
      getShippingNoteDTObyShippingNoteId(route.params?.shippingNoteSelected);
    }
  }, [route.params?.shippingNoteSelected]);

  useEffect(() => {
    getAllCompanyProcessorConnectedToMe();
  }, []);

  const getShippingNoteDTObyShippingNoteId = (shippingNote: ShippingNote) => {
    customShippingNoteResourceApi
      .getShippingNoteCustom({ id: shippingNote.id! })
      .then((shippingNoteDTO: ShippingNoteDTO) => {
        if (shippingNoteDTO) {
          setBatchSelected(shippingNoteDTO.batch);
          setProcessorSelected(shippingNoteDTO.processor);
          setShippingNoteDTO(shippingNoteDTO);
        }
      })
      .catch(async error => {
        dispatch(changeLoaderStatus(false));
        let errorMessage = await ErrorHelper.getServerMessageFromJsonOrResponse(error);
        let errorJson = await ErrorHelper.getDetailFromServerMessage(errorMessage);
        AlertHelper.showSimpleAlert('Error', errorJson);
      });
  };

  /**
   *
   * @param shippingNoteDTO
   * @param list with custom value (selected: boolean)
   */
  const setPacketsSelected = (shippingNoteDTO: ShippingNoteDTO, list) => {
    let packetsSelected: Packet[] = JSON.parse(JSON.stringify(Array.from(shippingNoteDTO.packets || new Set()))); //Copy Packet from shippingNoteDTO (Set)
    if (packetsSelected.length > 0) {
      let copyList = JSON.parse(JSON.stringify(list));
      packetsSelected.forEach((packetsSel: Packet) => {
        copyList = selectPackets(packetsSel, copyList, true);
      });
      //if i haven't Packets saved in the shippingNote I set the default list with (selected: false)
    } else {
      setPktList(list, () => {}); // setPktList(list, () => {});
      setPktListReference(list);
    }
  };

  const selectPackets = (data, list, setReference?: boolean) => {
    let listCopy = JSON.parse(JSON.stringify(list));
    listCopy.forEach(val => {
      if (val.id == data.id) {
        val.selected = !data.selected;
      }
    });
    setPktList(listCopy, () => {});
    setReference ? setPktListReference(listCopy) : null;
    return listCopy;
  };

  /**
   * When user closes modal I restore the previous list (using the reference)
   */
  const restorePacketList = () => {
    const cloneList = JSON.parse(JSON.stringify(pktListReference));
    setPktList(cloneList, () => {});
  };

  const updateShippingNote = (shippingNote: ShippingNoteDTO, closingShippingNote?: boolean) => {
    let packetsSet: Set<Packet> = packetList ? new Set(generateSet(packetList)) : new Set();
    let selectedPacketsList = pktList.filter(p => p.selected).map(p => createOnlyIdPacket(p));

    dispatch(changeLoaderStatus(true));

    let shippingNoteObj: ShippingNote = {
      id: shippingNoteSelected?.id!,
      name: shippingNote.name!,
      creationDate: shippingNote.creationDate,
      currentState: closingShippingNote ? ShippingNoteCurrentStateEnum.Sent : ShippingNoteCurrentStateEnum.InProgress,
      comment: shippingNote.comment!,
      batch: createOnlyIdBatch(shippingNote.batch),
      processor: createOnlyIdCompany(shippingNote.processor),
      packets: selectedPacketsList
    };

    customShippingNoteResourceApi
      .updateShippingNoteCustom({ id: shippingNoteSelected?.id!, shippingNote: shippingNoteObj })
      .then((shippingNote: ShippingNote) => {
        dispatch(changeLoaderStatus(false));
        if (shippingNote) {
          dispatch(resetShippingNoteList());
          UrstammNavigationHelper.navigateToShippingNoteList(navigation, true);
        }
      })
      .catch(async error => {
        dispatch(changeLoaderStatus(false));
        let errorMessage = await ErrorHelper.getServerMessageFromJsonOrResponse(error);
        let errorJson = await ErrorHelper.getDetailFromServerMessage(errorMessage);
        AlertHelper.showSimpleAlert('Error', errorJson);
      });
  };

  const goBack = async (): Promise<void> => {
    dispatch(resetShippingNoteList());
    Keyboard.dismiss();
    await sleep(PlatformHelper.isIos() ? 300 : 100);
    UrstammNavigationHelper.navigateToShippingNoteList(navigation, true);
  };

  const getMorePacketsItems = (): void => {
    if (rdxPacketPage < rdxPacketTotalPages - 1) {
      dispatch(setPacketPage(rdxPacketPage + 1));
    }
  };

  const closeShippingNote = (shippingNoteDTO: ShippingNoteDTO, externalCompany?: ExternalCompany) => {
    customShippingNoteResourceApi
      .updateShippingNoteStateCustom({
        id: shippingNoteDTO.id!,
        shippingNoteStateUpdateDTO: {
          updatedShippingNoteStateEnum: ShippingNoteStateUpdateDTOUpdatedShippingNoteStateEnumEnum.Sent,
          name: externalCompany?.name,
          streetName: externalCompany?.streetName,
          streetNumber: externalCompany?.streetNumber,
          phoneNumber: externalCompany?.phoneNumber,
          email: externalCompany?.email,
          city: externalCompany?.city,
          zipCode: externalCompany?.zipCode
        }
      })
      .then((shippingNote: ShippingNote) => {
        dispatch(changeLoaderStatus(false));
        if (shippingNote) {
          dispatch(resetShippingNoteList());
          UrstammNavigationHelper.navigateToShippingNoteList(navigation, true);
        }
      })
      .catch(async error => {
        dispatch(changeLoaderStatus(false));
        let errorMessage = await ErrorHelper.getServerMessageFromJsonOrResponse(error);
        let errorJson = await ErrorHelper.getDetailFromServerMessage(errorMessage);
        AlertHelper.showSimpleAlert('Error', errorJson);
      });
  };

  const showPacketDetails = (packet?: Packet) => {
    UrstammNavigationHelper.navigateToPacketDetails(
      navigation,
      packet?.batch,
      packet,
      {
        readOnly: true,
        returnTo: 'back'
      },
    )
  }

  /*const applyOrder = (orderSelected: ButtonSelect[]): void => {
    dispatch(setPacketListSort(orderSelected));
    setShowSortBy(false);
  };*/

  const navigateToPacketRegistration = () => {
    UrstammNavigationHelper.navigateToPacketRegistration(navigation, shippingNoteDTO);
  };

  const batchSelectID = batch => {
    setBatchSelected(batch);
  };

  const processorSelectID = processor => {
    setProcessorSelected(processor);
  };

  const getMoreBatchItems = (): void => {
    if (rdxBatchPage < rdxBatchTotalPages - 1) {
      dispatch(setBatchPage(rdxBatchPage + 1));
    }
  };

  /**
   * Setting processor list, it used for "Registration" and "Detail" Pages.
   * @param order
   */
  const getAllCompanyProcessorConnectedToMe = (order?: ButtonSelect[]) => {
    let sortBy = order && order.length > 0 ? [order[0].sort?.sortBy + ',' + order[0].sort?.direction] : ['name,desc'];
    dispatch(changeLoaderStatus(true));

    customCompanyResourceApi
      .getCompaniesConnectedToMe({
        sort: sortBy,
        customCompanyCriteria: {},
        page: 0,
        size: 1000
      })
      .then((list: PageCompany) => {
        dispatch(changeLoaderStatus(false));

        if (list && list.content?.length) {
          list.content.push({ name: i18n.t('views.shipping_note.no_urstamm_processor'), id: -200 });
          setProcessorList(list.content);
        }
      })
      .catch(async error => {
        dispatch(changeLoaderStatus(false));
        let errorMessage = await ErrorHelper.getServerMessageFromJsonOrResponse(error);
        let errorJson = await ErrorHelper.getDetailFromServerMessage(errorMessage);
        AlertHelper.showSimpleAlert('Error', errorJson);
      });
  };

  return (
    <BaseLayoutCorner
      navigation={navigation}
      cornerOptions={cornerOption}
      layoutStyle={{
        topContainer: UrstammStyleLayout.topContainerCorner,
        bottomContainer: UrstammStyleLayout.baseBottomContainer,
        bottomSubContainer: UrstammStyleLayout.bottomSubContainer40,
        headerContainer: UrstammStyleHeader.headerCenterLogo,
        headerDetails: UrstammStyleHeader.headerDetails,
        cornerBottomLeft: UrstammStyleCornerButton.cornerBottomLeft40,
        cornerBottomRight: UrstammStyleCornerButton.cornerBottomRight40
      }}
      submitTopLeft={goBack}
      submitBottomRight={navigateToPacketRegistration}
      title={
        <UrstammTitle
          testID={'shippingNote_title'}
          text={i18n.t('generics.shipping_note')}
          fontStyle={UrstammStyleHeader.headerTextStyleBlack}
        />
      }
      subTitle={
        <UrstammTitle
          testID={'trunk_details_subtitle'}
          text={shippingNoteSelected?.id ? shippingNoteSelected?.id + '' : ''}
          fontStyle={UrstammStyleHeader.headerDetailsTextStyleBlack}
        />
      }
      headerDetails={[{ title: i18n.t('generics.name'), value: shippingNoteSelected?.name!, truncateValue: 30 }]}
      view={
        <ShippingNoteDetailsView
          navigation={navigation}
          shippingNoteSelectedDTO={shippingNoteDTO!}
          moreItems={getMorePacketsItems}
          userExtendedMe={rdxUserExtendedMe}
          batchProps={{
            batchList: batchListState,
            batchSelected: batchSelected => selectElementFormList(batchSelected, setBatchListState, batchListState),
            moreItems: getMoreBatchItems,
            resetBatchSelected: () => restoreListToReference(setBatchListState, batchListReference),
            batchSelectedID: batch => batchSelectID(batch)
          }}
          processorProps={{
            getAll: getAllCompanyProcessorConnectedToMe,
            processorList: processorList,
            processorSelectedID: processor => processorSelectID(processor)
          }}
          packetListProps={{
            packetSelected: showPacketDetails,
          }}
          updateShippingNote={(shippingNoteDTO: ShippingNoteDTO) => updateShippingNote(shippingNoteDTO)}
          batchList={batchListState}
          showSortBy={showSortBy}
          closeShowSortBy={() => setShowSortBy(false)}
          processorList={processorList}
          closingShippingNote={(shippingNoteDTO: ShippingNoteDTO, externalCompany?: ExternalCompany) =>
            closeShippingNote(shippingNoteDTO, externalCompany)
          }
          customPacketList={pktList}
          confirmPacketSelected={() => applySavingToReference(setPktListReference, pktList)}
          resetPacketSelected={restorePacketList}
          packetSelected={pktSelected => selectPackets(pktSelected.item, pktList, false)}
          shippingNoteList={shippingNoteList}
        />
      }
    />
  );
}
