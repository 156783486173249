import UrstammButtonBase from '@components/utility-components/button/UrstammButtonBase';
import UrstammSlideButton, { SlideButtonOption } from '@components/utility-components/button/UrstammButtonFilter';
import UrstammButtonSelect, { ButtonSelect } from '@components/utility-components/button/UrstammButtonSelect';
import UrstammFilePicker, {
  PickerMediaType,
  UploadType
} from '@components/utility-components/file-picker/UrstammFilePicker';
import UrstammIcon from '@components/utility-components/icon/UrstammIcon';
import UrstammInput from '@components/utility-components/input/UrstammInput';
import UrstammList, { ListSubData } from '@components/utility-components/list/UrstammList';
import UrstammModalSelect from '@components/utility-components/modal/UrstammModalSelect';
import { i18n } from '@i18n/i18n';
import {
  BatchDTO,
  BatchDTORecordingTypeEnum,
  Company,
  Packet,
  PacketDTO,
  PacketDTOCurrentStateEnum,
  //PacketDTOSpeciesEnum,
  //PacketSpeciesEnum,
  PacketSpeciesSpeciesEnum,
  TrunkSpeciesEnum
} from '@services/apis/generated';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { Image, Modal, ScrollView, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import Collapsible from 'react-native-collapsible';
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import {
  getColorByPacketState,
  getColorByTrunkState,
  getIconByPacketState,
  getTextByDsRecordingType,
  getTextByPacketSpecies,
  getTextByPacketState,
  getTextByTreeOrTrunkSpecies,
  getTextByTrunkState
} from '../../../utils/classes/UrstammUtilityCurrentState';
import {
  urstammUtilDisplayError,
  urstammUtilValidator,
  urstammUtilValidatorInError
} from '../../../utils/classes/UrstammUtilityForm';
import {
  getCardTitleByTrunkNumberFas,
  getCounterBySelected,
  getMandatorySymbol,
  getPacketSpeciesList,
  isCompanyMock,
  packetIsEditable
} from '../../../utils/classes/UrstammUtilityFunctions';
import { ColorTheme } from '../../../utils/styles/ColorTheme';
import {
  lightGreenBorderWhiteBGButton,
  mainGreenButton,
  mainGreenButtonSelectActive
} from '../../../utils/styles/UrstammButtonModelStyle';
import {
  UrstammStyle,
  UrstammStyleButton,
  UrstammStyleCollapsible,
  UrstammStyleContainer,
  UrstammStyleLayout,
  UrstammStyleList,
  UrstammStyleModal
} from '../../../utils/styles/UrstammStyle';
import {
  CertificationProps,
  CompanyProductTypeProps,
  CompanyPacketWoodQualityProps,
  CompanySurfaceProps,
  PacketForm,
  SpeciesProps,
  TrunkProps
} from './PacketRegistrationView';
import Layout from '../../../config/Layout';
import { buildPacketPhotoUrl, buildTreePhotoUrl } from '../../../utils/classes/UrstammUtilityImage';
import { useSelector } from 'react-redux';
import { RootState } from '@redux/store';
import UrstammModal from '@components/utility-components/modal/UrstammModal';
import { NumberHelper } from '@helpers/NumberHelper';
import { t } from 'i18n-js';

export interface PacketImageForm {
  base64File?: string;
  longitude?: string;
  latitude?: string;
  photoCreationDate?: Date;
}


export default function PacketDetailsView(props: {
  navigation: any;
  batchDTO: BatchDTO;
  packetSelected: Packet;
  submitPacketForm: (data: PacketForm, packetPhoto?: PacketImageForm) => void;
  companyProductTypeProps?: CompanyProductTypeProps;
  companyPacketWoodQualityProps?: CompanyPacketWoodQualityProps;
  companySurfaceProps?: CompanySurfaceProps;
  trunkProps?: TrunkProps;
  certificationProps?: CertificationProps;
  speciesProps?: SpeciesProps;
  processorList: Company[];
  closingPacket?: boolean;
  readOnly?: boolean;
}) {
  
  const editable = useMemo(() => {
    return !props.readOnly;
  }, [!props.readOnly])
  
  const rdxLoaderStatus = useSelector((state: RootState) => state.persistedReducer.loader.enabled);
  const [speciesList, setSpeciesList] = useStateWithCallbackLazy<any[]>([]);
  const [showProductTypeModalSelect, setShowProductTypeModalSelect] = useState<boolean>(false);
  const [showPacketWoodQualityModalSelect, setShowPacketWoodQualityModalSelect] = useState<boolean>(false);
  const [showSurfaceModalSelect, setShowSurfaceModalSelect] = useState<boolean>(false);
  const [showTrunkModalSelect, setShowTrunkModalSelect] = useState<boolean>(false);
  const [showCertificationModalSelect, setShowCertificationModalSelect] = useState<boolean>(false);
  const [showProcessorModalSelect, setShowProcessorModalSelect] = useState<boolean>(false);
  const [showNoUrstammProcessor, setShowNoUrstammProcessor] = useState<boolean>(false);
  const [saveDisabled, setSaveDisabled] = useState<boolean>(true);
  const [collapsed, setCollapsed] = React.useState<boolean>(true);
  const [showPreview, setShowPreview] = useState<any>(false);
  const [photoPreview, setPhotoPreview] = useState<string>('');
  const [packetProcessorCustomName, setPacketProcessorCustomName] = useState<string>('');
  const [packetProcessorCustomNameReference, setPacketProcessorCustomNameReference] = useState<string>('');
  const [showClosePacketConfirm, setShowClosePacketConfirm] = useState<boolean>(false);
 
  const [packetForm, setPacketForm] = useStateWithCallbackLazy<PacketForm>({
    packet: {
      uniqueIdentifier: props.packetSelected?.uniqueIdentifier ? props.packetSelected?.uniqueIdentifier : '',
      name: '',
      creationDate: new Date(),
      currentState: PacketDTOCurrentStateEnum.InProgress,
      comment: '',
      processorCustomName: '',
    }
  });
  
  const [packetImageForm, setPacketImageForm] = useStateWithCallbackLazy<PacketImageForm>({});
  
  let trunkKeys: ListSubData[] = [
    {
      title: i18n.t('generics.quality') + ':',
      titleTextStyle: UrstammStyleList.cardTitleTextStyle,
      key: 'quality',
      keyId: '1quality',
      changedKey: 'qualityChanged',
      dataContainerViewStyle: { height: 24, flexBasis: '50%' }
    },
    {
      title: i18n.t('generics.length') + ' (m):',
      titleTextStyle: UrstammStyleList.cardTitleTextStyle,
      key: 'length',
      keyId: '1length',
      changedKey: 'lengthChanged',
      dataViewStyle: { justifyContent: 'flex-end', alignItems: 'center' }
    },
    {
      title: i18n.t('generics.specie') + ':',
      titleTextStyle: UrstammStyleList.cardTitleTextStyle,
      key: 'species',
      keyId: '1species',
      changedKey: 'speciesChanged',
      dataContainerViewStyle: { height: 24, flexBasis: '50%' },
      isEnum: true
    },
    {
      title: i18n.t('generics.net_volume') + ':',
      titleTextStyle: UrstammStyleList.cardTitleTextStyle,
      key: 'volume',
      keyId: '1netVolume',
      dataViewStyle: { justifyContent: 'flex-end', alignItems: 'center' }
    },
    {
      title: i18n.t('generics.diameter') + ' (cm):',
      titleTextStyle: UrstammStyleList.cardTitleTextStyle,
      key: 'diameter',
      keyId: '1diameter',
      changedKey: 'diameterChanged',
      dataContainerViewStyle: { height: 24, flexBasis: '50%' }
    },
    {
      title: i18n.t('utility_components.list.creation'),
      titleTextStyle: UrstammStyleList.cardTitleTextStyle,
      key: 'creationDate',
      keyId: '1creationDate',
      dataViewStyle: { justifyContent: 'flex-end', alignItems: 'center' }
    },
    {
      title: i18n.t('views.tree.tree_registration.photo_number') + ':',
      titleTextStyle: UrstammStyleList.cardTitleTextStyle,
      key: 'treePhotoNumber',
      keyId: '1treePhotoNumber',
      dataContainerViewStyle: { height: 24, flexBasis: '50%' }
    }
  ];

  

  useEffect(() => {
    if (props.packetSelected?.photoFilepath) {
      buildPacketPhotoUrl(props.packetSelected).then(res => {
        setPhotoPreview(res);
      });
    }
    setPacketForm(
      {
        packet: {
          currentState: PacketDTOCurrentStateEnum.InProgress,
          uniqueIdentifier: props.packetSelected?.uniqueIdentifier,
          name: props.packetSelected?.name,
          creationDate: props.packetSelected?.creationDate,
          length: props.packetSelected?.length,
          width: props.packetSelected?.width,
          thickness: props.packetSelected?.thickness,
          amountOfProducts: props.packetSelected?.amountOfProducts,
          discount: props.packetSelected?.discount,
          dryness: props.packetSelected?.dryness,
          planningDimension: props.packetSelected?.planningDimension,
          comment: props.packetSelected?.comment,
          processor: props.packetSelected?.processorCustomName
            ? { name: props.packetSelected?.processorCustomName }
            : props.packetSelected?.processor,
          processorCustomName: props.packetSelected?.processorCustomName,
          productType: props.packetSelected?.productType,
          woodQuality: props.packetSelected?.woodQuality,
          surface: props.packetSelected?.surface
        }
      },
      packetUpdated => {
        saveButtonDisabled(errors, packetUpdated, packetImageForm);
        setPacketProcessorCustomName(packetUpdated.packet?.processorCustomName!);
        setPacketProcessorCustomNameReference(packetUpdated.packet?.processorCustomName!);
      }
    );
  }, [props.packetSelected]);

  useEffect(() => {
    if (props.speciesProps?.speciesList)
      setSpeciesList(props.speciesProps?.speciesList, list => {});
  }, [props.speciesProps?.speciesList])

  
  /**
   *
   * @param key
   * @param value
   */
  const updatePacketForm = (key: string, value: any, mustValidate?: boolean): void => {
    let packet = { ...packetForm.packet };

    const formKey = key as keyof any; //TODO: add interface
    packet[formKey] = value;

    return setPacketForm(
      { packet: packet },
      mustValidate ? updatedLoggingForm => validate(value, key, updatedLoggingForm) : () => {}
    );
  };

  const updatePhoto = (base64: string, location: any, mustValidate?: boolean): void => {
    let photo: PacketImageForm = {
      base64File: base64,
      longitude: location.coords.longitude,
      latitude: location.coords.latitude,
      photoCreationDate: new Date(),
    };
    setPacketImageForm(photo, packetImageFormUpdated => {
      saveButtonDisabled(errors, packetForm, packetImageFormUpdated);
    });
  };

  const deletePhoto = async () => {
    let photo: PacketImageForm = {
      base64File: undefined,
      longitude: undefined,
      latitude: undefined
    };
    setPacketImageForm(photo, packetImageFormUpdated => {
      saveButtonDisabled(errors, packetForm, packetImageFormUpdated);
    });
  };

  //Validators
  const validators = useState<any>(
    !props.closingPacket
      ? {
          name: { required: true },
          length: { numeric: true, max2digits: true },
          width: { numeric_no_digits: true },
          thickness: { numeric_no_digits: true },
          amountOfProducts: { numeric_no_digits: true },
          dryness: { numeric: true, percentage: true }
        }
      : {
          name: { required: true },
          productType: { required: true },
          length: { required: true, numeric: true, max2digits: true },
          width: { required: true, numeric_no_digits: true },
          thickness: { required: true, numeric_no_digits: true },
          amountOfProducts: { required: true, numeric_no_digits: true },
          dryness: { numeric: true, percentage: true },
          //species: { required: true },
          processor: { required: true },
          base64File: { required: true }
        }
  );

  const [errors, setErrors] = useStateWithCallbackLazy<any>(
    !props.closingPacket
      ? {
          name: [],
          length: [],
          width: [],
          thickness: [],
          amountOfProducts: [],
          dryness: []
        }
      : {
          name: [],
          productType: [],
          length: [],
          width: [],
          thickness: [],
          amountOfProducts: [],
          dryness: [],
          //species: [],
          processor: [],
          base64File: []
        }
  );

  const validate = (value: string, key: string, updatedTreeForm: PacketForm) => {
    let errorList = urstammUtilValidator(value, key, validators);
    setErrors(
      (state: any) => ({ ...state, [key]: errorList }),
      errorsUpdated => saveButtonDisabled(errorsUpdated, updatedTreeForm, packetImageForm)
    );
  };

  const saveButtonDisabled = (errorsUpdated, updatedPacketForm?: PacketForm, updatedImageForm?: PacketImageForm) => {
    let disabled: boolean = false;
    if (props.closingPacket) {
      if (
        urstammUtilValidatorInError(errorsUpdated) ||
        !updatedPacketForm?.packet.name ||
        !updatedPacketForm?.packet.length ||
        !updatedPacketForm?.packet.width ||
        !updatedPacketForm?.packet.thickness ||
        !updatedPacketForm?.packet.amountOfProducts ||
        // !updatedPacketForm?.packet.packetSpecies ||
        !updatedPacketForm?.packet.thickness ||
        !updatedPacketForm?.packet.processor ||
        updatedImageForm?.base64File?.length == 0 ||
        !updatedImageForm?.base64File ||
        (!props.trunkProps?.trunkList?.length && !trunkSelectedFound())
      ) {
        disabled = true;
      }
    } else {
      if (urstammUtilValidatorInError(errorsUpdated) || !updatedPacketForm?.packet.name) {
        disabled = true;
      }
    }

    return setSaveDisabled(disabled);
  };

  const trunkSelectedFound = () => {
    return props.trunkProps?.trunkList?.find(trunk => trunk.selected);
  };

  const updateProcessor = (processor: Company) => {
    if (processor.id == -200) {
      setShowProcessorModalSelect(false);
      return setTimeout(() => setShowNoUrstammProcessor(true), 300);
    }
    packetForm.packet.processorCustomName = '';
    updatePacketForm('processor', processor, props.closingPacket);
    return setShowProcessorModalSelect(false);
  };

  const submitForm = () => {
    props.speciesProps?.confirmSpeciesSelected();
    props.submitPacketForm(packetForm, props.closingPacket ? packetImageForm : undefined);
  }

  const printCertificationList = (packet: Packet | PacketDTO | undefined): string => {
    const certifications = packet?.packetCertifications instanceof Set ? [...packet?.packetCertifications.values()] : [...(packet?.packetCertifications || [])];
    return certifications
      .filter(c => !!c.certification)
      .map(c => c.certification!.toString())
      .reduce((val, next) => {
        return val.length > 0 ? `${val}, ${next}` : next;
      }, '') || '';
  }

  return (
    <View style={UrstammStyle.flex1}>
      <ScrollView
        keyboardShouldPersistTaps="always"
        contentContainerStyle={[{ flexGrow: 1, flexDirection: 'column', marginBottom: 40 }]}>
        {/* Preview modal */}
        {showPreview && (
          <View style={UrstammStyleModal.centeredView}>
            <Modal animationType="slide" transparent={true} visible={showPreview}>
              <View style={UrstammStyleModal.centeredView}>
                <View style={UrstammStyleModal.modalView}>
                  <View style={[styles.cameraTopButton]}>
                    {/**Close Preview */}
                    <TouchableOpacity
                      style={[styles.top_button, UrstammStyle.baseShadow, { alignSelf: 'flex-end' }]}
                      onPress={() => setShowPreview(false)}>
                      <View style={[styles.iconCloseContainer]}>
                        <UrstammIcon name={'close'} size={24} color={ColorTheme.mediumGrey} />
                      </View>
                    </TouchableOpacity>
                  </View>

                  {photoPreview.length > 0 && (
                    <Image
                      source={{ uri: photoPreview }}
                      style={{
                        width: Layout.window.width * 0.9,
                        height: Layout.window.height * 0.7
                      }}
                    />
                  )}
                </View>
              </View>
            </Modal>
          </View>
        )}

        {/**Status and creation date */}
        <View style={[UrstammStyleLayout.formElementContainer]}>
          <View style={[UrstammStyleContainer.row1SpaceBetween, UrstammStyle.wrap]}>
            {props.packetSelected?.currentState && (
              <View style={[UrstammStyle.flexRow, UrstammStyle.paddingBottom4]}>
                {props.packetSelected && getIconByPacketState(props.packetSelected?.currentState as any)}
                <Text
                  style={[
                    UrstammStyleContainer.statusCreationDateTextWithIcon,
                    getColorByPacketState(props.packetSelected.currentState)
                  ]}>
                  {getTextByPacketState(props.packetSelected.currentState!)}
                </Text>
              </View>
            )}
            {props.packetSelected?.creationDate ? (
              <View style={[UrstammStyle.flexRow, UrstammStyle.flexEndX, { paddingBottom: 10 }]}>
                <Text style={UrstammStyle.titleStyle}>{i18n.t('generics.creation') + ' '}</Text>
                <Text style={UrstammStyle.dataStyle}>
                  {moment(props.packetSelected.creationDate).format('DD/MM/yyyy HH:mm')}
                </Text>
              </View>
            ) : null}
          </View>
        </View>

        {props.packetSelected?.length || props.packetSelected?.planningDimension ? (
          <View style={UrstammStyleLayout.formElementContainerPd8}>
            <View style={[UrstammStyleContainer.row1SpaceBetween, UrstammStyle.wrap]}>
              {props.packetSelected?.batch?.name ? (
                <View style={[UrstammStyle.flexRow, UrstammStyle.wrap, UrstammStyle.paddingBottom4]}>
                  <Text style={UrstammStyle.titleStyle}>{i18n.t('generics.batch') + ': '}</Text>
                  <Text style={[UrstammStyle.dataStyle]}>{props.packetSelected?.batch?.name}</Text>
                </View>
              ) : null}
            </View>
          </View>
        ) : null}

        {!props.closingPacket ? (
          <View style={UrstammStyleLayout.formElementContainer}>

            {props.packetSelected?.productType || props.packetSelected?.dryness ? (
              <View style={UrstammStyleLayout.formElementContainerPd8}>
                <View style={[UrstammStyleContainer.row1SpaceBetween, UrstammStyle.wrap]}>
                  {props.packetSelected?.productType ? (
                    <View style={[UrstammStyle.flexRow, UrstammStyle.paddingBottom4, UrstammStyle.wrap]}>
                      <Text style={UrstammStyle.titleStyle}>{i18n.t('generics.productType') + ': '}</Text>
                      <Text style={[UrstammStyle.dataStyle]}>{props.packetSelected.productType.name}</Text>
                    </View>
                  ) : null}
                  {props.packetSelected?.dryness ? (
                    <View style={[UrstammStyle.flexRow, UrstammStyle.wrap]}>
                      <Text style={UrstammStyle.titleStyle}>{i18n.t('generics.dryness') + ': '}</Text>
                      <Text style={UrstammStyle.dataStyle}>{props.packetSelected.dryness}</Text>
                    </View>
                  ) : null}
                </View>
              </View>
            ) : null}

            {props.packetSelected?.length || props.packetSelected?.planningDimension ? (
              <View style={UrstammStyleLayout.formElementContainerPd8}>
                <View style={[UrstammStyleContainer.row1SpaceBetween, UrstammStyle.wrap]}>
                  {props.packetSelected?.length ? (
                    <View style={[UrstammStyle.flexRow, UrstammStyle.paddingBottom4]}>
                      <Text style={UrstammStyle.titleStyle}>{i18n.t('generics.length_with_unit') + ': '}</Text>
                      <Text style={[UrstammStyle.dataStyle]}>{props.packetSelected.length}</Text>
                    </View>
                  ) : null}
                  {props.packetSelected?.planningDimension ? (
                    <View style={UrstammStyle.flexRow}>
                      <Text style={UrstammStyle.titleStyle}>{i18n.t('generics.planningDimension') + ': '}</Text>
                      <Text style={UrstammStyle.dataStyle}>{props.packetSelected.planningDimension}</Text>
                    </View>
                  ) : null}
                </View>
              </View>
            ) : null}

            {props.packetSelected?.thickness || props.packetSelected?.packetSpecies ? (
              <View style={UrstammStyleLayout.formElementContainerPd8}>
                <View style={[UrstammStyleContainer.row1SpaceBetween, UrstammStyle.wrap]}>
                  {props.packetSelected?.thickness ? (
                    <View style={[UrstammStyle.flexRow, UrstammStyle.paddingBottom4]}>
                      <Text style={UrstammStyle.titleStyle}>{i18n.t('generics.thickness') + ': '}</Text>
                      <Text style={[UrstammStyle.dataStyle]}>{props.packetSelected.thickness}</Text>
                    </View>
                  ) : null}
                  {props.packetSelected?.packetSpecies ? (
                    <View style={[UrstammStyle.flexRow, UrstammStyle.wrap]}>
                      <Text style={UrstammStyle.titleStyle}>{i18n.t('generics.treeSpecies') + ': '}</Text>
                      <Text style={UrstammStyle.dataStyle}>
                        {getTextByPacketSpecies(props.packetSelected.packetSpecies)}
                      </Text>
                    </View>
                  ) : null}
                </View>
              </View>
            ) : null}

            {props.packetSelected?.width || props.packetSelected?.woodQuality ? (
              <View style={UrstammStyleLayout.formElementContainerPd8}>
                <View style={[UrstammStyleContainer.row1SpaceBetween, UrstammStyle.wrap]}>
                  {props.packetSelected?.width ? (
                    <View style={[UrstammStyle.flexRow, UrstammStyle.paddingBottom4]}>
                      <Text style={UrstammStyle.titleStyle}>{i18n.t('generics.width_with_unit') + ': '}</Text>
                      <Text style={[UrstammStyle.dataStyle]}>{props.packetSelected.width}</Text>
                    </View>
                  ) : null}
                  {props.packetSelected?.woodQuality ? (
                    <View style={UrstammStyle.flexRow}>
                      <Text style={UrstammStyle.titleStyle}>{i18n.t('generics.woodQuality') + ': '}</Text>
                      <Text style={UrstammStyle.dataStyle}>
                        {props.packetSelected.woodQuality.name}
                      </Text>
                    </View>
                  ) : null}
                </View>
              </View>
            ) : null}

            {props.packetSelected?.amountOfProducts || props.packetSelected?.surface ? (
              <View style={UrstammStyleLayout.formElementContainerPd8}>
                <View style={[UrstammStyleContainer.row1SpaceBetween, UrstammStyle.wrap]}>
                  {props.packetSelected?.amountOfProducts ? (
                    <View style={[UrstammStyle.flexRow, UrstammStyle.paddingBottom4]}>
                      <Text style={UrstammStyle.titleStyle}>{i18n.t('generics.amountOfProducts') + ': '}</Text>
                      <Text style={[UrstammStyle.dataStyle]}>{props.packetSelected.amountOfProducts}</Text>
                    </View>
                  ) : null}
                  {props.packetSelected?.surface ? (
                    <View style={UrstammStyle.flexRow}>
                      <Text style={UrstammStyle.titleStyle}>{i18n.t('generics.surface') + ': '}</Text>
                      <Text style={UrstammStyle.dataStyle}>{props.packetSelected.surface.name}</Text>
                    </View>
                  ) : null}
                </View>
              </View>
            ) : null}

            {props.packetSelected?.sumCubage || props.packetSelected?.packetCertifications ? (
              <View style={UrstammStyleLayout.formElementContainerPd8}>
                <View style={[UrstammStyleContainer.row1SpaceBetween, UrstammStyle.wrap]}>
                  {props.packetSelected?.sumCubage ? (
                    <View style={[UrstammStyle.flexRow, UrstammStyle.paddingBottom4]}>
                      <Text style={UrstammStyle.titleStyle}>{i18n.t('generics.volume_with_unit') + ': '}</Text>
                      <Text style={[UrstammStyle.dataStyle]}>
                        {NumberHelper.roundWithThreeDecimals(props.packetSelected?.sumCubage)}
                      </Text>
                    </View>
                  ) : null}
                  {props.packetSelected?.packetCertifications && (false /* never show as the packetCertifications was always undefined */) ? (
                    <View style={UrstammStyle.flexRow}>
                      <Text style={UrstammStyle.titleStyle}>{i18n.t('generics.certifications') + ': '}</Text>
                      <Text style={UrstammStyle.dataStyle}>{printCertificationList(props.packetSelected)}</Text>
                    </View>
                  ) : null}
                </View>
              </View>
            ) : null}

            <View style={UrstammStyleLayout.formElementContainerPd8}>
              <View style={[UrstammStyleContainer.row1SpaceBetween, UrstammStyle.wrap]}>
                {props.batchDTO.sawmill ? (
                  <View style={[UrstammStyle.flexRow, UrstammStyle.paddingBottom4]}>
                    <Text style={UrstammStyle.titleStyle}>{i18n.t('generics.sawmill') + ': '}</Text>
                    <Text style={[UrstammStyle.dataStyle]}>{props.batchDTO.sawmill.name}</Text>
                  </View>
                ) : null}
                {props.packetSelected?.processor || props.packetSelected?.processorCustomName ? (
                  <View style={[UrstammStyle.flexRow, UrstammStyle.wrap]}>
                    <Text style={UrstammStyle.titleStyle}>{i18n.t('generics.processor') + ': '}</Text>
                    {props.packetSelected?.processor && !isCompanyMock(props.packetSelected?.processor) && props.packetSelected?.processor?.name ? (
                      <Text style={UrstammStyle.dataStyle}>{props.packetSelected.processor.name}</Text>
                    ) : props.packetSelected?.processorCustomName ? (
                      <Text style={UrstammStyle.dataStyle}>{props.packetSelected.processorCustomName}</Text>
                    ) : null}
                  </View>
                ) : null}
              </View>
            </View>

            <View style={UrstammStyleLayout.formElementContainerPd8}>
              <View style={[UrstammStyleContainer.row1SpaceBetween, UrstammStyle.wrap]}>
                {props.packetSelected?.comment ? (
                  <View style={[UrstammStyle.flexRow, UrstammStyle.paddingBottom4]}>
                    <Text style={UrstammStyle.titleStyle}>{i18n.t('generics.comment') + ': '}</Text>
                    <Text style={[UrstammStyle.dataStyle]}>{props.packetSelected.comment}</Text>
                  </View>
                ) : null}
              </View>

              <View style={UrstammStyleLayout.formElementContainerPd8}>
                <View style={[UrstammStyle.flex1, UrstammStyle.flexRow, UrstammStyle.flexEndX, { marginRight: 8 }]}>
                  {photoPreview ? (
                    <View>
                      {photoPreview ? (
                        <TouchableOpacity style={styles.imagePreview} onPress={() => setShowPreview(true)}>
                          <Image source={{ uri: photoPreview }} style={{ width: 52, height: 52 }} />
                        </TouchableOpacity>
                      ) : null}
                    </View>
                  ) : null}
                </View>
              </View>
            </View>
          </View>
        ) : null}
        <View style={UrstammStyle.divider} />

        {/**Edit DS Collapsible */}
        {props.packetSelected && editable && packetIsEditable(props.packetSelected) ? (
          <View style={{ marginTop: 8 }}>
            <View
              style={[
                UrstammStyleButton.smallBaseButtonStructure,
                { backgroundColor: ColorTheme.yellow },
                UrstammStyle.baseShadow
              ]}>
              <View style={UrstammStyleCollapsible.selectMainContainer}>
                <TouchableOpacity
                  style={UrstammStyleCollapsible.selectCollapsible}
                  onPress={() => setCollapsed(!collapsed)}
                  disabled={props.closingPacket}>
                  <Text style={UrstammStyleButton.baseButtonBWStyle}>{i18n.t('generics.edit')}</Text>

                  <UrstammIcon name={collapsed ? 'angle-down' : 'angle-up'} size={24} color={'white'} />
                </TouchableOpacity>
              </View>
            </View>

            <View style={[{ marginTop: 4 }]}>
              <Collapsible
                duration={500}
                easing={'easeInBounce'}
                collapsed={props.closingPacket ? false : collapsed}
                style={UrstammStyleCollapsible.containerCollapsible}>
                <ScrollView
                  keyboardShouldPersistTaps="always"
                  contentContainerStyle={[{ flexGrow: 1, flexDirection: 'column', marginBottom: 28 }]}>
                  {/** Packet name */}
                  <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
                    <UrstammInput
                      testID="packet_name"
                      style={{
                        containerViewStyle: UrstammStyleContainer.inputContainer,
                        textStyle: UrstammStyle.formBaseLDarkGrey
                      }}
                      icon={{ showIcon: false }}
                      value={packetForm.packet.name ? packetForm.packet.name : ''}
                      onChangeText={(text: string) => {
                        updatePacketForm('name', text, true);
                      }}
                      placeholder={i18n.t('views.packet.packet_registration.insert_packet_name_here')}
                      textInputTitle={i18n.t('views.packet.packet_registration.packet_name')}
                      mandatory={true}
                      editable={packetIsEditable(props.packetSelected)}
                    />

                    {/**Validator */}
                    {errors['name'] && errors['name'].length > 0
                      ? errors['name'].map((error, idx) => {
                          return urstammUtilDisplayError(error, idx);
                        })
                      : null}
                  </View>

                  {/** Product type list */}
                  {packetForm.packet.amountOfProducts != 0 ? (
                    <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
                      {/**Title */}
                      <View style={UrstammStyle.textTitleContainerFlex}>
                        <Text style={UrstammStyle.textTitle}>{i18n.t('generics.productType')}</Text>
                      </View>

                      <UrstammButtonBase
                        testID={'choose_product_type'}
                        text={
                          packetForm.packet.productType?.name
                            ? packetForm.packet.productType?.name
                            : i18n.t('views.packet.packet_registration.choose_product_type')
                        }
                        style={lightGreenBorderWhiteBGButton}
                        onSubmit={() => setShowProductTypeModalSelect(true)}
                        disabled={!packetIsEditable(props.packetSelected)}
                      />

                      {/**Packet product type  */}
                      {showProductTypeModalSelect ? (
                        <UrstammModalSelect
                          testID={'modal_packet_company_product_type'}
                          visible={showProductTypeModalSelect}
                          text={{
                            textInputTitle: i18n.t('views.packet.packet_registration.choose_product_type'),
                            textStyle: UrstammStyle.textTitle
                            //textInputSubTitle: i18n.t('views.batch.batch_registration.delivery_sheet_selected')+ ': ' + getCounterByDSSelected()
                          }}
                          closeModal={() => {
                            setShowProductTypeModalSelect(false);
                          }}
                          //confirmButton={{ enabled: false, text: i18n.t('views.packet.packet_registration.add_new_product'), submitValue: () => { setProductType((prev) => ({ ...prev, name: '', id: undefined, company: undefined, deletionDate: undefined }), (updated) => { setShowProductTypeModalSelect(false), setTimeout(() => setOpenUpdateCreateProductType(true), 300) }) } }}
                          listJSX={
                            <UrstammList
                              titleKey={'name'}
                              elementSelected={(productType: any) => {
                                updatePacketForm('productType', productType.item, props.closingPacket);
                                setShowProductTypeModalSelect(false);
                              }}
                              testID={'products_type_list'}
                              textForEmptyList={i18n.t('views.packet.products_type_not_found')}
                              list={props.companyProductTypeProps?.companyProductTypeList}
                              listStyle={{
                                mainTopContainerViewStyle: UrstammStyleList.mainTopContainerCol,
                                mainBottomContainerViewStyle: UrstammStyleList.cardSubContainerPDT12,
                                mainTitleTextStyle: UrstammStyleList.xCardMainTitleTextStyle,
                                bottomContainerViewStyle: UrstammStyleList.cardSubData,
                                bottomDataTextStyle: UrstammStyleList.cardSubTitleTextStyle,
                                paddingBottom: 4
                              }}
                              //listSubData={keys}
                              moreItems={props.companyProductTypeProps?.getMoreProductType}
                              filterList={true}
                              multipleSelection={false}
                              showHash={false}
                            />
                          }
                        />
                      ) : null}
                    </View>
                  ) : null}

                  {/** Packet length */}
                  {packetForm.packet.amountOfProducts != 0 ? (
                    <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
                      <UrstammInput
                        testID="length"
                        style={{
                          containerViewStyle: UrstammStyleContainer.inputContainer,
                          textStyle: UrstammStyle.formBaseLDarkGrey
                        }}
                        icon={{ showIcon: false }}
                        value={packetForm.packet.length ? packetForm.packet.length.toString() : ''}
                        onChangeText={(text: number) => updatePacketForm('length', text, true)}
                        placeholder={i18n.t('views.packet.packet_registration.insert_packet_length_here')}
                        textInputTitle={i18n.t('views.packet.packet_registration.packet_length')}
                        keyboardType={'numeric'}
                        maxLength={5}
                        mandatory={props.closingPacket}
                        editable={packetIsEditable(props.packetSelected)}
                      />

                      {/**Validator */}
                      {errors['length'] && errors['length'].length > 0
                        ? errors['length'].map((error, idx) => {
                            return urstammUtilDisplayError(error, idx);
                          })
                        : null}
                    </View>
                  ) : null}

                  {/** Packet width */}
                  {packetForm.packet.amountOfProducts != 0 ? (
                    <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
                      <UrstammInput
                        testID="width"
                        style={{
                          containerViewStyle: UrstammStyleContainer.inputContainer,
                          textStyle: UrstammStyle.formBaseLDarkGrey
                        }}
                        icon={{ showIcon: false }}
                        value={packetForm.packet.width ? packetForm.packet.width.toString() : ''}
                        onChangeText={(text: number) => updatePacketForm('width', text, true)}
                        placeholder={i18n.t('views.packet.packet_registration.insert_packet_width_here')}
                        textInputTitle={i18n.t('views.packet.packet_registration.packet_width')}
                        keyboardType={'numeric'}
                        mandatory={props.closingPacket}
                        editable={packetIsEditable(props.packetSelected)}
                      />

                      {/**Validator */}
                      {errors['width'] && errors['width'].length > 0
                        ? errors['width'].map((error, idx) => {
                            return urstammUtilDisplayError(error, idx);
                          })
                        : null}
                    </View>
                  ) : null}

                  {/** Packet thickness */}

                  {packetForm.packet.amountOfProducts != 0 ? (
                    <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
                      <UrstammInput
                        testID="thickness"
                        style={{
                          containerViewStyle: UrstammStyleContainer.inputContainer,
                          textStyle: UrstammStyle.formBaseLDarkGrey
                        }}
                        icon={{ showIcon: false }}
                        value={packetForm.packet.thickness ? packetForm.packet.thickness.toString() : ''}
                        onChangeText={(text: number) => updatePacketForm('thickness', text, true)}
                        placeholder={i18n.t('views.packet.packet_registration.insert_packet_thickness_here')}
                        textInputTitle={i18n.t('views.packet.packet_registration.packet_thickness')}
                        keyboardType={'numeric'}
                        mandatory={props.closingPacket}
                        editable={packetIsEditable(props.packetSelected)}
                      />

                      {/**Validator */}
                      {errors['thickness'] && errors['thickness'].length > 0
                        ? errors['thickness'].map((error, idx) => {
                            return urstammUtilDisplayError(error, idx);
                          })
                        : null}
                    </View>
                  ) : null}

                  {/** Packet amount of products sawmill */}
                  <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
                    <UrstammInput
                      testID="amountOfProducts"
                      style={{
                        containerViewStyle: UrstammStyleContainer.inputContainer,
                        textStyle: UrstammStyle.formBaseLDarkGrey
                      }}
                      icon={{ showIcon: false }}
                      value={
                        packetForm.packet.amountOfProducts || packetForm.packet.amountOfProducts == 0
                          ? packetForm.packet.amountOfProducts.toString()
                          : ''
                      }
                      onChangeText={(text: number) => {
                        updatePacketForm('amountOfProducts', text, true);
                      }}
                      placeholder={i18n.t('views.packet.packet_registration.insert_amount_of_products_here')}
                      textInputTitle={i18n.t('views.packet.packet_registration.amount_of_products')}
                      keyboardType={'numeric'}
                      mandatory={props.closingPacket}
                      editable={packetIsEditable(props.packetSelected)}
                    />

                    {/**Validator */}
                    {errors['amountOfProducts'] && errors['amountOfProducts'].length > 0
                      ? errors['amountOfProducts'].map((error, idx) => {
                          return urstammUtilDisplayError(error, idx);
                        })
                      : null}
                  </View>

                  {/** Packet discount **/}
                  {packetForm.packet.amountOfProducts != 0 ? (
                    <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
                      <UrstammInput
                        testID="discount"
                        style={{
                          containerViewStyle: UrstammStyleContainer.inputContainer,
                          textStyle: UrstammStyle.formBaseLDarkGrey
                        }}
                        icon={{ showIcon: false }}
                        value={packetForm.packet.discount ? packetForm.packet.discount.toString() : ''}
                        onChangeText={(text: string) => updatePacketForm('discount', text, false)}
                        placeholder={i18n.t('views.packet.packet_registration.insert_packet_discount_here')}
                        textInputTitle={i18n.t('views.packet.packet_registration.discount')}
                        mandatory={false}
                        editable={packetIsEditable(props.packetSelected)}
                      />
                    </View>
                  ) : null}

                  {/** Packet dryness (in %, +/- 2%) */}
                  {packetForm.packet.amountOfProducts != 0 ? (
                    <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
                      <UrstammInput
                        testID="dryness"
                        style={{
                          containerViewStyle: UrstammStyleContainer.inputContainer,
                          textStyle: UrstammStyle.formBaseLDarkGrey
                        }}
                        icon={{ showIcon: false }}
                        value={packetForm.packet.dryness ? packetForm.packet.dryness.toString() : ''}
                        onChangeText={(text: number) => updatePacketForm('dryness', text, true)}
                        placeholder={i18n.t('views.packet.packet_registration.insert_packet_dryness_here')}
                        textInputTitle={i18n.t('views.packet.packet_registration.packet_dryness')}
                        keyboardType={'numeric'}
                        maxLength={3}
                        mandatory={false}
                        editable={packetIsEditable(props.packetSelected)}
                      />
                      {/**Validator */}
                      {errors['dryness'] && errors['dryness'].length > 0
                        ? errors['dryness'].map((error, idx) => {
                            return urstammUtilDisplayError(error, idx);
                          })
                        : null}
                    </View>
                  ) : null}

                  {/** Packet Planning dimension (free text field)*/}
                  {packetForm.packet.amountOfProducts != 0 ? (
                    <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
                      <UrstammInput
                        testID="planning_dimension"
                        style={{
                          containerViewStyle: UrstammStyleContainer.inputContainer,
                          textStyle: UrstammStyle.formBaseLDarkGrey
                        }}
                        icon={{ showIcon: false }}
                        value={
                          packetForm.packet.planningDimension ? packetForm.packet.planningDimension.toString() : ''
                        }
                        onChangeText={(text: number) => updatePacketForm('planningDimension', text)}
                        placeholder={i18n.t('views.packet.packet_registration.insert_packet_planning_dimension_here')}
                        textInputTitle={i18n.t('views.packet.packet_registration.packet_planning_dimension')}
                        mandatory={false}
                        editable={packetIsEditable(props.packetSelected)}
                      />
                    </View>
                  ) : null}

                  {/** Packet wood quality */}
                  {packetForm.packet.amountOfProducts != 0 ? (
                    <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
                      {/**Title */}
                      <View style={UrstammStyle.textTitleContainerFlex}>
                        <Text style={UrstammStyle.textTitle}>{i18n.t('generics.woodQuality')}</Text>
                      </View>

                      <UrstammButtonBase
                        testID={'choose_wood_quality'}
                        text={
                          packetForm.packet.woodQuality?.name
                            ? packetForm.packet.woodQuality?.name
                            : i18n.t('views.packet.packet_registration.choose_wood_quality')
                        }
                        style={lightGreenBorderWhiteBGButton}
                        onSubmit={() => setShowPacketWoodQualityModalSelect(true)}
                        disabled={!packetIsEditable(props.packetSelected)}
                      />

                      {/**Packet Modal multiple select */}
                      {showPacketWoodQualityModalSelect ? (
                        <UrstammModalSelect
                          testID={'modal_packet_company_choose_wood_quality'}
                          visible={showPacketWoodQualityModalSelect}
                          text={{
                            textInputTitle: i18n.t('views.packet.packet_registration.choose_wood_quality'),
                            textStyle: UrstammStyle.textTitle
                          }}
                          closeModal={() => {
                            setShowPacketWoodQualityModalSelect(false);
                          }}
                          
                          listJSX={
                            <UrstammList
                              titleKey={'name'}
                              elementSelected={(woodQuality: any) => {
                                updatePacketForm('woodQuality', woodQuality.item, false);
                                setShowPacketWoodQualityModalSelect(false);
                              }}
                              testID={'wood_quality_list'}
                              textForEmptyList={i18n.t('views.packet.woods_quality_not_found')}
                              list={props.companyPacketWoodQualityProps?.companyPacketWoodQualityList}
                              listStyle={{
                                mainTopContainerViewStyle: UrstammStyleList.mainTopContainerCol,
                                mainBottomContainerViewStyle: UrstammStyleList.cardSubContainerPDT12,
                                mainTitleTextStyle: UrstammStyleList.xCardMainTitleTextStyle,
                                bottomContainerViewStyle: UrstammStyleList.cardSubData,
                                bottomDataTextStyle: UrstammStyleList.cardSubTitleTextStyle,
                                paddingBottom: 4
                              }}
                              //listSubData={keys}
                              moreItems={props.companyPacketWoodQualityProps?.getMorePacketWoodQuality}
                              filterList={true}
                              multipleSelection={false}
                              showHash={false}
                            />
                          }
                        />
                      ) : null}
                    </View>
                  ) : null}


                  {/**Packet species */}
                  {packetForm.packet.amountOfProducts != 0 ? (
                    <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
                      <UrstammSlideButton
                        testID={'button_tree_species'}
                        slideButtonList={speciesList}
                        slideButtonTitle={i18n.t('generics.tree_species')}
                        slideButtonRotatedTitle={i18n.t('generics.species')}
                        mandatory={props.closingPacket}
                        buttonPressed={(selected: SlideButtonOption) => {
                          props.speciesProps?.speciesSelected({item: selected})
                          // updatePacketForm('packetSpecies', speciesList, true)
                        }}
                        disabled={!packetIsEditable(props.packetSelected)}
                      />
                    </View>
                  ) : null}

                  {/** Surface list */}

                  {packetForm.packet.amountOfProducts != 0 ? (
                    <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
                      {/**Title */}
                      <View style={UrstammStyle.textTitleContainerFlex}>
                        <Text style={UrstammStyle.textTitle}>{i18n.t('generics.surface')}</Text>
                      </View>

                      <UrstammButtonBase
                        testID={'choose_surface'}
                        text={
                          packetForm.packet.surface?.name
                            ? packetForm.packet.surface?.name
                            : i18n.t('views.packet.packet_registration.choose_surface')
                        }
                        style={lightGreenBorderWhiteBGButton}
                        onSubmit={() => setShowSurfaceModalSelect(true)}
                        disabled={!packetIsEditable(props.packetSelected)}
                      />

                      {/**Packet Modal multiple select */}
                      {showSurfaceModalSelect ? (
                        <UrstammModalSelect
                          testID={'modal_packet_company_choose_surface'}
                          visible={showSurfaceModalSelect}
                          text={{
                            textInputTitle: i18n.t('views.packet.packet_registration.choose_surface'),
                            textStyle: UrstammStyle.textTitle
                            //textInputSubTitle: i18n.t('views.batch.batch_registration.delivery_sheet_selected')+ ': ' + getCounterByDSSelected()
                          }}
                          closeModal={() => {
                            setShowSurfaceModalSelect(false);
                          }}
                          //confirmButton={{ enabled: false, text: i18n.t('views.packet.packet_registration.add_new_product'), submitValue: () => { setSurface((prev) => ({ ...prev, name: '', id: undefined, company: undefined, deletionDate: undefined }), (updated) => { setShowSurfaceModalSelect(false), setTimeout(() => setOpenUpdateCreateSurface(true), 300) }) } }}
                          listJSX={
                            <UrstammList
                              titleKey={'name'}
                              elementSelected={(surface: any) => {
                                updatePacketForm('surface', surface.item, false);
                                setShowSurfaceModalSelect(false);
                              }}
                              testID={'surface_list'}
                              textForEmptyList={i18n.t('views.packet.surfaces_not_found')}
                              list={props.companySurfaceProps?.companySurfaceList}
                              listStyle={{
                                mainTopContainerViewStyle: UrstammStyleList.mainTopContainerCol,
                                mainBottomContainerViewStyle: UrstammStyleList.cardSubContainerPDT12,
                                mainTitleTextStyle: UrstammStyleList.xCardMainTitleTextStyle,
                                bottomContainerViewStyle: UrstammStyleList.cardSubData,
                                bottomDataTextStyle: UrstammStyleList.cardSubTitleTextStyle,
                                paddingBottom: 4
                              }}
                              //listSubData={keys}
                              moreItems={props.companySurfaceProps?.getMoreSurface}
                              filterList={true}
                              multipleSelection={false}
                              showHash={false}
                            />
                          }
                        />
                      ) : null}
                    </View>
                  ) : null}

                  {/** Trunks list */}
                  {props.batchDTO?.recordingType == BatchDTORecordingTypeEnum.Single &&
                  packetForm.packet.amountOfProducts != 0 ? (
                    <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
                      {/**Title */}
                      <View style={UrstammStyle.textTitleContainerFlex}>
                        <Text style={UrstammStyle.textTitle}>
                          {i18n.t('views.packet.packet_registration.choose_trunk') +
                            getMandatorySymbol(
                              props.batchDTO.recordingType == BatchDTORecordingTypeEnum.Single && props.closingPacket!
                            )}
                        </Text>
                      </View>

                      <UrstammButtonBase
                        testID={'choose_trunk'}
                        text={i18n.t('views.packet.packet_registration.choose_trunk')}
                        style={lightGreenBorderWhiteBGButton}
                        onSubmit={() => setShowTrunkModalSelect(true)}
                        counter={getCounterBySelected(props.trunkProps?.trunkList)}
                        disabled={!packetIsEditable(props.packetSelected)}
                      />

                      {/**Trunk Modal multiple select */}
                      {showTrunkModalSelect ? (
                        <UrstammModalSelect
                          testID={'modal_trunk_multiple_select'}
                          visible={showTrunkModalSelect}
                          text={{
                            textInputTitle: i18n.t('views.packet.packet_registration.choose_trunk'),
                            textStyle: UrstammStyle.textTitle
                            //textInputSubTitle: i18n.t('views.batch.batch_registration.delivery_sheet_selected')+ ': ' + getCounterByDSSelected()
                          }}
                          closeModal={() => {
                            setShowTrunkModalSelect(false);
                            props.trunkProps?.resetTrunkSelected();
                          }}
                          confirmButton={{
                            enabled: true,
                            text: i18n.t('generics.save'),
                            submitValue: () => {
                              setShowTrunkModalSelect(false);
                              props.trunkProps?.confirmTrunkSelected();
                            }
                          }}
                          listJSX={
                            <UrstammList
                              elementSelected={(trunkSelected: any) => props.trunkProps?.trunkSelected(trunkSelected)}
                              testID={'trunk_list'}
                              textForEmptyList={i18n.t('views.trunk.trunks_not_found')}
                              list={props.trunkProps?.trunkList}
                              titleByFunction={data => getCardTitleByTrunkNumberFas(data)}
                              listStyle={{
                                mainTopContainerViewStyle: UrstammStyleList.mainTopContainerCol,
                                mainBottomContainerViewStyle: UrstammStyleList.cardSubContainerPDT12,
                                mainTitleTextStyle: UrstammStyleList.xCardMainTitleTextStyle,
                                bottomContainerViewStyle: UrstammStyleList.cardSubData,
                                bottomDataTextStyle: UrstammStyleList.cardSubTitleTextStyle,
                                paddingBottom: 4
                              }}
                              listSubData={trunkKeys}
                              moreItems={props.trunkProps?.getMoreTrunks}
                              filterList={true}
                              multipleSelection={true}
                              //filterListFunction={(text, data) => filterDSByNameOrCompany(text, data, props.userExtendedMe!)}
                              currentState={{
                                //icon: (state) => getIconByLoggingState(state),
                                color: state => getColorByTrunkState(state),
                                text: state => getTextByTrunkState(state)
                              }}
                              titleKey={'name'}
                              getTextForData={data => getTextByTreeOrTrunkSpecies(data)}
                              showHash={false}
                            />
                          }
                        />
                      ) : null}
                    </View>
                  ) : null}

                  {/** Certification list */}
                  {packetForm.packet.amountOfProducts != 0 ? (
                    <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
                      {/**Title */}
                      <View style={UrstammStyle.textTitleContainerFlex}>
                        <Text style={UrstammStyle.textTitle}>
                          {i18n.t('views.packet.packet_registration.choose_certification')}
                        </Text>
                      </View>

                      <UrstammButtonBase
                        testID={'choose_certification'}
                        text={i18n.t('views.packet.packet_registration.choose_certification')}
                        style={lightGreenBorderWhiteBGButton}
                        onSubmit={() => setShowCertificationModalSelect(true)}
                        counter={getCounterBySelected(props.certificationProps?.certificationList)}
                        disabled={!packetIsEditable(props.packetSelected)}
                      />

                      {/**Trunk Modal multiple select */}
                      {showCertificationModalSelect ? (
                        <UrstammModalSelect
                          testID={'modal_certification_multiple_select'}
                          visible={showCertificationModalSelect}
                          text={{
                            textInputTitle: i18n.t('views.packet.packet_registration.choose_certification'),
                            textStyle: UrstammStyle.textTitle
                            //textInputSubTitle: i18n.t('views.batch.batch_registration.delivery_sheet_selected')+ ': ' + getCounterByDSSelected()
                          }}
                          closeModal={() => {
                            setShowCertificationModalSelect(false);
                            props.certificationProps?.resetCertificationSelected();
                          }}
                          confirmButton={{
                            enabled: true,
                            text: i18n.t('generics.save'),
                            submitValue: () => {
                              setShowCertificationModalSelect(false);
                              props.certificationProps?.confirmCertificationSelected();
                            }
                          }}
                          listJSX={
                            <UrstammList
                              elementSelected={(certificationSelected: any) =>
                                props.certificationProps?.certificationSelected(certificationSelected)
                              }
                              testID={'certification_list'}
                              textForEmptyList={i18n.t('views.packet.certifications_not_found')}
                              list={props.certificationProps?.certificationList}
                              listStyle={{
                                mainTopContainerViewStyle: UrstammStyleList.mainTopContainerCol,
                                mainBottomContainerViewStyle: UrstammStyleList.cardSubContainerPDT12,
                                mainTitleTextStyle: UrstammStyleList.xCardMainTitleTextStyle,
                                bottomContainerViewStyle: UrstammStyleList.cardSubData,
                                bottomDataTextStyle: UrstammStyleList.cardSubTitleTextStyle,
                                paddingBottom: 4
                              }}
                              moreItems={props.certificationProps?.getMoreCertifications}
                              filterList={false}
                              multipleSelection={true}
                              //filterListFunction={(text, data) => filterDSByNameOrCompany(text, data, props.userExtendedMe!)}
                              titleKey={'name'}
                              getTextForData={data => getTextByTreeOrTrunkSpecies(data)}
                              showHash={false}
                            />
                          }
                        />
                      ) : null}
                    </View>
                  ) : null}

                  {/** Processor list */}
                  {packetForm.packet.amountOfProducts != 0 ? (
                    <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
                      {/**Title */}
                      <View style={UrstammStyle.textTitleContainerFlex}>
                        <Text style={UrstammStyle.textTitle}>
                          {i18n.t('generics.processor') + getMandatorySymbol(props.closingPacket!)}
                        </Text>
                      </View>

                      <UrstammButtonBase
                        testID={'choose_product_type'}
                        text={
                          packetForm.packet.processor
                            ? packetForm.packet.processor.name
                            : i18n.t('views.packet.packet_registration.choose_processor')
                        }
                        style={lightGreenBorderWhiteBGButton}
                        onSubmit={() => setShowProcessorModalSelect(true)}
                        disabled={!packetIsEditable(props.packetSelected)}
                      />

                      {/**Packet processor  */}
                      {showProcessorModalSelect ? (
                        <UrstammModalSelect
                          testID={'modal_packet_company_processor'}
                          visible={showProcessorModalSelect}
                          text={{
                            textInputTitle: i18n.t('views.packet.packet_registration.choose_processor'),
                            textStyle: UrstammStyle.textTitle
                            //textInputSubTitle: i18n.t('views.batch.batch_registration.delivery_sheet_selected')+ ': ' + getCounterByDSSelected()
                          }}
                          closeModal={() => {
                            setShowProcessorModalSelect(false);
                          }}
                          confirmButton={{
                            enabled: false,
                            text: i18n.t('views.packet.no_urstamm_processor'),
                            submitValue: () => {}
                          }}
                          listJSX={
                            <UrstammList
                              titleKey={'name'}
                              elementSelected={(processor: any) => {
                                updateProcessor(processor.item);
                              }}
                              testID={'processor_list'}
                              textForEmptyList={i18n.t('views.packet.processors_not_found')}
                              list={props.processorList}
                              listStyle={{
                                mainTopContainerViewStyle: UrstammStyleList.mainTopContainerCol,
                                mainBottomContainerViewStyle: UrstammStyleList.cardSubContainerPDT12,
                                mainTitleTextStyle: UrstammStyleList.xCardMainTitleTextStyle,
                                bottomContainerViewStyle: UrstammStyleList.cardSubData,
                                bottomDataTextStyle: UrstammStyleList.cardSubTitleTextStyle,
                                paddingBottom: 4
                              }}
                              //listSubData={keys}
                              filterList={true}
                              multipleSelection={false}
                              showHash={false}
                            />
                          }
                        />
                      ) : null}
                    </View>
                  ) : null}

                  {/**Image picker */}
                  {props.closingPacket && packetForm.packet.amountOfProducts != 0 ? (
                    <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
                      <UrstammFilePicker
                        testID="packet_image_picker"
                        inputStyle={UrstammStyleContainer.cameraContainer}
                        fontStyle={UrstammStyle.formBaseLDarkGrey}
                        icon={{
                          showIcon: true,
                          iconName: 'camera',
                          iconColor: ColorTheme.lightGreen
                        }}
                        placeholder={i18n.t('generics.upload_photo_file')}
                        textInputTitle={i18n.t('views.packet.packet_registration.packet_photo')}
                        uploadType={UploadType.camera}
                        mandatory={true}
                        deleteBase64={() => deletePhoto()}
                        disabled={!packetIsEditable(props.packetSelected)}
                        mediaTypeUploaded={PickerMediaType.Image}
                        saveBase64={(base64: string, location: any) => {
                          updatePhoto(base64, location, true);
                        }}
                      />

                      {/**Validator */}
                      {errors['base64File'] && errors['base64File'].length > 0
                        ? errors['base64File'].map((error, idx) => {
                            return urstammUtilDisplayError(error, idx);
                          })
                        : null}
                    </View>
                  ) : null}

                  {/** Comment */}
                  <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
                    <UrstammInput
                      testID="packet_comment"
                      style={{
                        containerViewStyle: UrstammStyleContainer.textareaContainer,
                        textStyle: UrstammStyle.formBaseLDarkGrey
                      }}
                      icon={{ showIcon: false }}
                      value={packetForm.packet.comment ? packetForm.packet.comment : ''}
                      onChangeText={(text: string) => updatePacketForm('comment', text, false)}
                      placeholder={i18n.t('generics.insert_comment_here')}
                      textInputTitle={i18n.t('generics.comment')}
                      mandatory={false}
                      multiline={true}
                      editable={packetIsEditable(props.packetSelected)}
                    />
                  </View>

                  {/**Save */}
                  {packetIsEditable(props.packetSelected) ? (
                    <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
                      <UrstammButtonBase
                        testID={'save_packet'}
                        text={i18n.t('generics.save')}
                        style={mainGreenButton}
                        onSubmit={() =>
                          props.closingPacket
                            ? setShowClosePacketConfirm(true)
                            : submitForm()
                        }
                        disabled={(saveDisabled && packetForm.packet.amountOfProducts != 0) || rdxLoaderStatus}
                      />
                    </View>
                  ) : null}

                  {showNoUrstammProcessor ? (
                    <UrstammModalSelect
                      testID={'modal_no_processor_urstamm'}
                      visible={showNoUrstammProcessor}
                      text={{
                        textInputTitle: i18n.t('views.packet.packet_registration.enter_processor'),
                        textStyle: UrstammStyle.textTitle
                        //textInputSubTitle: i18n.t('views.batch.batch_registration.delivery_sheet_selected')+ ': ' + getCounterByDSSelected()
                      }}
                      closeModal={() => {
                        setPacketProcessorCustomName(packetProcessorCustomNameReference);
                        updatePacketForm('processorCustomName', packetProcessorCustomNameReference, false);
                        setShowNoUrstammProcessor(false);
                      }}
                      modalSize={{ mainContainerWidth: 0.74, mainContainerHeight: 0.4, secondaryContainerHeight: 0.2 }}
                      confirmButton={{
                        enabled: true,
                        text: i18n.t('generics.save'),
                        submitValue: () => {
                          setPacketProcessorCustomNameReference(packetProcessorCustomName);
                          updatePacketForm(
                            'processor',
                            { name: packetForm.packet.processorCustomName },
                            props.closingPacket
                          );
                          setShowNoUrstammProcessor(false);
                          setShowProcessorModalSelect(false);
                        }
                      }}
                      listJSX={
                        <>
                          <UrstammInput
                            testID="packet_no_urstamm_processor"
                            style={{
                              containerViewStyle: UrstammStyleContainer.textareaContainer,
                              textStyle: UrstammStyle.formBaseLDarkGrey
                            }}
                            icon={{ showIcon: false }}
                            value={packetForm.packet.processorCustomName ? packetForm.packet.processorCustomName : ''}
                            onChangeText={(text: string) => {
                              updatePacketForm('processorCustomName', text, false);
                              setPacketProcessorCustomName(text);
                            }}
                            placeholder={i18n.t('views.packet.packet_registration.insert_processor_name_address')}
                            textInputTitle={i18n.t('generics.processor')}
                            mandatory={false}
                            multiline={true}
                            editable={packetIsEditable(props.packetSelected)}
                          />
                        </>
                      }
                    />
                  ) : null}

                  {showClosePacketConfirm && (
                    <UrstammModal
                      testID={'close_logging_modal'}
                      text={i18n.t('views.packet.are_you_sure_want_close_packet')}
                      textStyle={UrstammStyleModal.formBtextModalStyle}
                      visible={showClosePacketConfirm}
                      buttonText={{
                        left: i18n.t('utility_components.modal.cancel'),
                        right: i18n.t('utility_components.modal.confirm')
                      }}
                      confirm={(confirmed: boolean) => {
                        confirmed
                          ? props.submitPacketForm(packetForm, props.closingPacket ? packetImageForm : undefined)
                          : null,
                          setShowClosePacketConfirm(false);
                      }}
                    />
                  )}
                </ScrollView>
              </Collapsible>
            </View>
          </View>
        ) : null}
      </ScrollView>
    </View>
  );
}

const styles = StyleSheet.create({
  imagePreview: {
    flex: 1,
    justifyContent: 'center'
  },
  cameraTopButton: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignSelf: 'flex-end'
  },
  top_button: {
    backgroundColor: ColorTheme.lightGrey,
    borderColor: ColorTheme.mainGreen,
    borderRadius: 8,
    padding: 8,
    marginBottom: 8,
    borderWidth: 1,
    zIndex: 2,
    elevation: 2,
    maxWidth: 40,
    alignSelf: 'flex-end'
  },
  iconCloseContainer: {
    minWidth: 20,
    justifyContent: 'center',
    alignSelf: 'center',
    alignItems: 'center'
  }
});
