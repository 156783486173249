import {
  Batch,
  Company,
  DeliverySheet,
  Logging,
  Packet,
  Pile,
  ProcessorBatch,
  Product,
  Project,
  Tree
} from '@services/apis/generated';

export const createOnlyIdLogging = (logging: Logging | undefined): Logging | undefined => {
  if (!logging) return undefined;
  return {
    id: logging.id,
    name: logging.name,
    currentState: logging.currentState
  };
};

export const createOnlyIdPile = (pile: Pile | undefined): Pile | undefined => {
  return createOnlyIdObject(pile);
};

export const createOnlyIdCompany = (company: Company | undefined): Company | undefined => {
  return createOnlyIdObject(company);
};

export const createOnlyIdBatch = (batch: Batch | undefined): Batch | undefined => {
  return createOnlyIdObject(batch);
};

export const createOnlyIdPacket = (packet: Packet | undefined): Packet | undefined => {
  return createOnlyIdObject(packet);
};

export const createOnlyIdProcessorBatch = (processorBatch: ProcessorBatch | undefined): ProcessorBatch | undefined => {
  return createOnlyIdObject(processorBatch);
};

export const createOnlyIdProduct = (product: Product | undefined): Product | undefined => {
  return createOnlyIdObject(product);
};

export const createOnlyIdProject = (project: Project | undefined): Project | undefined => {
  return createOnlyIdObject(project);
};

export const createOnlyIdDeliverySheet = (deliverySheet: DeliverySheet | undefined): DeliverySheet | undefined => {
  return createOnlyIdObject(deliverySheet);
};

export const createOnlyIdTree = (tree: Tree | undefined): Tree | undefined => {
  return createOnlyIdObject(tree);
};

/**
 * Clears the given element from fields not supported by redux
 * @param element element to clear
 * @returns The updated element
 */
export const clearElementForRedux = (element: any, keepAsList: boolean = false): any => {
  for (const key in element) {
    if (element[key] instanceof Set) {
      // TODO: why not keep it as list?
      // skip this keys
      if (keepAsList) {
        // console.log(`Transforming ${key} because it is a Set`);
        element[key] = [...element[key].values()];
      } else {
        // console.log(`Removing ${key} because it is a Set`);
        element[key] = undefined;
      }
    }
  }

  return element;
};

const createOnlyIdObject = (object: any | undefined): any | undefined => {
  if (!object) return undefined;
  return { id: object.id };
};
