import { environment } from '@environments/environment';

import { BaseAPIManager } from '@services/model/BaseAPIManager';
import { Logger } from '../../helpers/Logger';
import {
  AccountResourceApi,
  Configuration,
  ConfigurationParameters,
  CustomAccountResourceApi,
  CustomBatchResourceApi,
  CustomCompanyPacketWoodQualityResourceApi,
  CustomCompanyProductTypeResourceApi,
  CustomCompanyResourceApi,
  CustomCompanySurfaceResourceApi,
  CustomDeliverySheetResourceApi,
  CustomLoggingResourceApi,
  CustomOtherAssortmentResourceApi,
  CustomPileResourceApi,
  CustomPlotResourceApi,
  CustomProcessorBatchResourceApi,
  CustomProcessorShippingNoteResourceApi,
  CustomProductResourceApi,
  CustomProjectResourceApi,
  CustomPushTokenResourceApi,
  CustomTreeResourceApi,
  CustomTrunkBarkDiscountTableResourceApi,
  CustomTrunkDiameterClassTableResourceApi,
  CustomTrunkLengthRangeTableResourceApi,
  CustomTrunkResourceApi,
  CustomUserExtendedResourceApi,
  CustomUserResourceApi,
  FetchParams,
  Middleware,
  PacketCertificationResourceApi,
  RequestContext,
  ResponseContext,
  UserJwtControllerApi
} from './generated';
import { CustomCompanyAppearanceResourceApi } from './generated/apis/CustomCompanyAppearanceResourceApi';
import { CustomPacketResourceApi } from './generated/apis/CustomPacketResourceApi';
import { CustomShippingNoteResourceApi } from './generated/apis/CustomShippingNoteResourceApi';

let accountResourceApi: AccountResourceApi;
let customAccountResourceApi: CustomAccountResourceApi;
let customBatchResourceApi: CustomBatchResourceApi;
let customCompanyProductTypeResourceApi: CustomCompanyProductTypeResourceApi;
let customCompanyResourceApi: CustomCompanyResourceApi;
let customCompanyPacketWoodQualityResourceApi: CustomCompanyPacketWoodQualityResourceApi;
let customCompanySurfaceResourceApi: CustomCompanySurfaceResourceApi;
let customCompanyAppearanceResourceApi: CustomCompanyAppearanceResourceApi;
let customDeliverySheetResourceApi: CustomDeliverySheetResourceApi;
let customLoggingResourceApi: CustomLoggingResourceApi;
let customOtherAssortmentResourceApi: CustomOtherAssortmentResourceApi;
let customPacketResourceApi: CustomPacketResourceApi;
let packetCertificationResourceApi: PacketCertificationResourceApi;
let customPileResourceApi: CustomPileResourceApi;
let customPlotResourceApi: CustomPlotResourceApi;
let customProcessorBatchResourceApi: CustomProcessorBatchResourceApi;
let customProcessorShippingNoteResourceApi: CustomProcessorShippingNoteResourceApi;
let customProductResourceApi: CustomProductResourceApi;
let customProjectResourceApi: CustomProjectResourceApi;
let customPushTokenResourceApi: CustomPushTokenResourceApi;
let customShippingNoteResourceApi: CustomShippingNoteResourceApi;
let customTreeResourceApi: CustomTreeResourceApi;
let customTrunkResourceApi: CustomTrunkResourceApi;
let customTrunkBarkDiscountTableResourceApi: CustomTrunkBarkDiscountTableResourceApi;
let customTrunkDiameterClassTableResourceApi: CustomTrunkDiameterClassTableResourceApi;
let customTrunkLengthRangeTableResourceApi: CustomTrunkLengthRangeTableResourceApi;
let customUserExtendedResourceApi: CustomUserExtendedResourceApi;
let customUserResourceApi: CustomUserResourceApi;
let userJwtControllerApi: UserJwtControllerApi;

const className = 'ApiConfiguration';

export const listSize: number = 10;
const logoutOnUnauthorized = false;
export const longListSize: number = 1000;

export function reloadApiConfiguration() {
  // console.log('Reloading API');
  let configParameters: ConfigurationParameters = {};
  configParameters.basePath = environment.apiUrl;
  configParameters.fetchApi = async (input: RequestInfo, init?: RequestInit | undefined) => {
    let fetchApi = window.fetch.bind(window);
    try {
      return await fetchApi(input, init);
    } catch (e) {
      // In case of errors it hides the loader
      BaseAPIManager.contextLoadingCallback ? BaseAPIManager.contextLoadingCallback(false) : null;
      throw e;
    }
  };
  let loaderMiddleware: Middleware = {
    pre(context: RequestContext): Promise<FetchParams | void> {
      Logger.log(className, `Starting: ${context.url}`);
      return Promise.resolve({ url: context.url, init: context.init });
    },
    post(context: ResponseContext): Promise<Response | void> {
      //console.log(context, "Context")
      Logger.log(className, `Ending: ${context.url}`);
      if (context.response.status < 200 || context.response.status > 207) {
        BaseAPIManager.contextLoadingCallback ? BaseAPIManager.contextLoadingCallback(false) : null;
      }
      if (context.response.status == 401 && logoutOnUnauthorized) {
        BaseAPIManager.logoutCallback ? BaseAPIManager.logoutCallback() : null;
      }
      Logger.log(className, `Response status code: ${context.response.status}`);
      return Promise.resolve(context.response);
    }
  };
  configParameters.middleware = [loaderMiddleware];
  if (BaseAPIManager.token) {
    configParameters.headers = {
      Authorization: 'Bearer ' + BaseAPIManager.token
    };
  }
  let config = new Configuration(configParameters);

  accountResourceApi = new AccountResourceApi(config);
  customAccountResourceApi = new CustomAccountResourceApi(config);
  customBatchResourceApi = new CustomBatchResourceApi(config);
  customCompanyProductTypeResourceApi = new CustomCompanyProductTypeResourceApi(config);
  customCompanyResourceApi = new CustomCompanyResourceApi(config);
  customCompanyPacketWoodQualityResourceApi = new CustomCompanyPacketWoodQualityResourceApi(config);
  customCompanySurfaceResourceApi = new CustomCompanySurfaceResourceApi(config);
  customCompanyAppearanceResourceApi = new CustomCompanyAppearanceResourceApi(config);
  customDeliverySheetResourceApi = new CustomDeliverySheetResourceApi(config);
  customLoggingResourceApi = new CustomLoggingResourceApi(config);
  customOtherAssortmentResourceApi = new CustomOtherAssortmentResourceApi(config);
  customPacketResourceApi = new CustomPacketResourceApi(config);
  packetCertificationResourceApi = new PacketCertificationResourceApi(config);
  customPileResourceApi = new CustomPileResourceApi(config);
  customPlotResourceApi = new CustomPlotResourceApi(config);
  customProcessorBatchResourceApi = new CustomProcessorBatchResourceApi(config);
  customProcessorShippingNoteResourceApi = new CustomProcessorShippingNoteResourceApi(config);
  customProductResourceApi = new CustomProductResourceApi(config);
  customProjectResourceApi = new CustomProjectResourceApi(config);
  customPushTokenResourceApi = new CustomPushTokenResourceApi(config);
  customShippingNoteResourceApi = new CustomShippingNoteResourceApi(config);
  customTreeResourceApi = new CustomTreeResourceApi(config);
  customTrunkResourceApi = new CustomTrunkResourceApi(config);
  customTrunkBarkDiscountTableResourceApi = new CustomTrunkBarkDiscountTableResourceApi(config);
  customTrunkDiameterClassTableResourceApi = new CustomTrunkDiameterClassTableResourceApi(config);
  customTrunkLengthRangeTableResourceApi = new CustomTrunkLengthRangeTableResourceApi(config);
  customUserExtendedResourceApi = new CustomUserExtendedResourceApi(config);
  customUserResourceApi = new CustomUserResourceApi(config);
  userJwtControllerApi = new UserJwtControllerApi(config);
}

export {
  accountResourceApi,
  customAccountResourceApi,
  customBatchResourceApi,
  customCompanyAppearanceResourceApi,
  customCompanyPacketWoodQualityResourceApi,
  customCompanyProductTypeResourceApi,
  customCompanyResourceApi,
  customCompanySurfaceResourceApi,
  customDeliverySheetResourceApi,
  customLoggingResourceApi,
  customOtherAssortmentResourceApi,
  customPacketResourceApi,
  customPileResourceApi,
  customPlotResourceApi,
  customProcessorBatchResourceApi,
  customProcessorShippingNoteResourceApi,
  customProductResourceApi,
  customProjectResourceApi,
  customPushTokenResourceApi,
  customShippingNoteResourceApi,
  customTreeResourceApi,
  customTrunkBarkDiscountTableResourceApi,
  customTrunkDiameterClassTableResourceApi,
  customTrunkLengthRangeTableResourceApi,
  customTrunkResourceApi,
  customUserExtendedResourceApi,
  customUserResourceApi,
  packetCertificationResourceApi,
  userJwtControllerApi
};
