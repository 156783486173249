import UrstammButtonBase from '@components/utility-components/button/UrstammButtonBase';
import UrstammDatePicker from '@components/utility-components/date-picker/UrstammDatePicker';
import UrstammInput from '@components/utility-components/input/UrstammInput';
import UrstammList, { ListSubData } from '@components/utility-components/list/UrstammList';
import UrstammModalSelect from '@components/utility-components/modal/UrstammModalSelect';
import { i18n } from '@i18n/i18n';
import { Batch, Company, Packet, UserExtended } from '@services/apis/generated';

import { ShippingNoteDTO } from '@services/apis/generated/models/ShippingNoteDTO';
import React, { useEffect, useState } from 'react';
import { ScrollView, StyleSheet, Text, View } from 'react-native';
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import {
  getColorByBatchState,
  getColorByPacketState,
  getIconByBatchState,
  getTextByBatchState,
  getTextByPacketState
} from '../../../utils/classes/UrstammUtilityCurrentState';
import { filterPacketByNameOrUniqueIdentifierOrProductTypeOrProcessor } from '../../../utils/classes/UrstammUtilityFilter';
import {
  urstammUtilDisplayError,
  urstammUtilValidator,
  urstammUtilValidatorInError
} from '../../../utils/classes/UrstammUtilityForm';
import {
  getCardTitleByBatchOrPacket,
  getCounterBySelected,
  getMandatorySymbol
} from '../../../utils/classes/UrstammUtilityFunctions';
import { ColorTheme } from '../../../utils/styles/ColorTheme';
import { lightGreenBorderWhiteBGButton, mainGreenButton } from '../../../utils/styles/UrstammButtonModelStyle';
import {
  UrstammStyle,
  UrstammStyleContainer,
  UrstammStyleLayout,
  UrstammStyleList
} from '../../../utils/styles/UrstammStyle';

//Future developments
export interface ShippingNoteForm {
  shippingNote: ShippingNoteDTO;
}

interface BatchProps {
  batchList: any[];
  batchSelected: (data: any) => void;
  resetBatchSelected?: () => void;
  batchSelectedID: (batch: Batch) => void;
  moreItems: () => void;
}
interface ProcessorProps {
  getAll: Function;
  processorList: any[];
  processorSelectedID: (processor: Company) => void;
}

interface PacketProps {
  packetList: any[];
  packetSelected: (data: any) => void;
  resetPacketSelected?: () => void;
  confirmPacketSelected?: () => void;
  moreItems: () => void;
}

export default function ShippingNoteRegistrationView(props: {
  navigation: any;
  submitShippingNoteForm: (data: ShippingNoteDTO) => void;
  resetShippingNoteForm: boolean;
  userExtendedMe?: UserExtended;
  batchProps: BatchProps;
  processorProps: ProcessorProps;
  packetProps: PacketProps;
}) {
  const [shippingNoteForm, setShippingNoteForm] = useStateWithCallbackLazy<ShippingNoteForm>({
    shippingNote: {
      name: '',
      creationDate: new Date(),
      comment: '',
      batch: undefined,
      processor: undefined,
      packets: undefined
    }
  });

  const [batchList, setBatchList] = useStateWithCallbackLazy<any>(() => {});
  const [processorList, setProcessorList] = useStateWithCallbackLazy<any>(() => {});
  const [packetList, setPacketList] = useStateWithCallbackLazy<any>(() => {});
  const [showBatchSelect, setShowBatchSelect] = useState<boolean>(false);
  const [showProcessorModalSelect, setShowProcessorModalSelect] = useState<boolean>(false);
  const [showPacketSelect, setShowPacketSelect] = useState<boolean>(false);
  const [saveDisabled, setSaveDisabled] = useState<boolean>(true);

  let keys: ListSubData[] = [
    {
      title: i18n.t('utility_components.list.creation'),
      titleTextStyle: UrstammStyleList.cardTitleTextStyle,
      key: 'creationDate',
      keyId: '1creationDate'
      // dataContainerViewStyle: { height: 24, flexBasis: '50%' }
    },
    {
      title: i18n.t('generics.productType') + ':',
      titleTextStyle: UrstammStyleList.cardTitleTextStyle,
      key: 'productType',
      secondKey: 'name',
      keyId: '1productType',
      dataViewStyle: { justifyContent: 'flex-end', alignItems: 'center' }
    },
    {
      title: i18n.t('views.batch.sum_volume_packets') + ':',
      titleTextStyle: UrstammStyleList.cardTitleTextStyle,
      key: 'sumCubage',
      keyId: '1sumCubage'
      // dataContainerViewStyle: { height: 24, flexBasis: '50%' }
    },
    {
      title: i18n.t('generics.processor') + ':',
      titleTextStyle: UrstammStyleList.cardTitleTextStyle,
      key: 'processor',
      secondKey: 'name',
      keyId: '1processor',
      dataViewStyle: { justifyContent: 'flex-end', alignItems: 'center' }
    }
  ];

  let batchKeys: ListSubData[] = [
    {
      title: undefined,
      titleTextStyle: UrstammStyleList.cardTitleTextStyle,
      key: 'currentState',
      keyId: '1currentState',
      dataViewStyle: { justifyContent: 'flex-start', alignItems: 'center' }
    },
    {
      title: i18n.t('utility_components.list.creation'),
      titleTextStyle: UrstammStyleList.cardTitleTextStyle,
      key: 'creationDate',
      keyId: '1creationDate',
      dataViewStyle: { justifyContent: 'flex-end', alignItems: 'center' }
    }
  ];

  useEffect(() => {
    if (props.resetShippingNoteForm) {
      clearForm();
    }
  }, [props.resetShippingNoteForm]);

  useEffect(() => {
    if (props.batchProps.batchList)
      setBatchList(props.batchProps.batchList, updatedBatchList => {
        saveButtonDisabled(errors, shippingNoteForm, updatedBatchList);
      });
  }, [props.batchProps.batchList]);

  useEffect(() => {
    props.processorProps.getAll();
    if (props.processorProps.processorList)
      setProcessorList(props.processorProps.processorList, updatedProcessorList => {
        saveButtonDisabled(errors, shippingNoteForm, updatedProcessorList);
      });
  }, []);

  useEffect(() => {
    if (props.packetProps.packetList && shippingNoteForm.shippingNote.batch) {
      let pl = JSON.parse(JSON.stringify(props.packetProps.packetList));
      pl.filter((packet: Packet) => packet.batch == shippingNoteForm.shippingNote.batch!.id);
      setPacketList(pl, updatedPacketList => {
        saveButtonDisabled(errors, shippingNoteForm, updatedPacketList);
      });
    }
  }, [props.packetProps.packetList]);

  /**
   * Resetting batch form for new recording
   */
  const clearForm = () => {
    setShippingNoteForm(
      prevState => ({
        ...prevState,
        shippingNote: {
          name: '',
          comment: ''
        }
      }),
      updatedShippingNoteForm => {
        saveButtonDisabled(errors, updatedShippingNoteForm);
      }
    );
  };

  /**
   *
   * @param key
   * @param value
   */
  const updateShippingNoteForm = (key: string, value: any, mustValidate?: boolean): void => {
    let shippingNote = { ...shippingNoteForm.shippingNote };

    const formKey = key as keyof any; //TODO: add interface
    shippingNote[formKey] = value;

    return setShippingNoteForm(
      { shippingNote: shippingNote },
      mustValidate ? updatedShippingNoteForm => validate(value, key, updatedShippingNoteForm) : () => {}
    );
  };

  //Validators
  const validators = useState<any>({
    name: { required: true }
  });

  const [errors, setErrors] = useStateWithCallbackLazy<any>({
    name: []
  });

  const validate = (value: string, key: string, updatedShippingNoteForm: ShippingNoteForm) => {
    let errorList = urstammUtilValidator(value, key, validators);
    setErrors(
      (state: any) => ({ ...state, [key]: errorList }),
      errorsUpdated => saveButtonDisabled(errorsUpdated, updatedShippingNoteForm, batchList)
    );
  };

  const saveButtonDisabled = (errorsUpdated, updatedShippingNoteForm?: ShippingNoteForm, batchList?: any) => {
    let disabled: boolean = false;
    if (urstammUtilValidatorInError(errorsUpdated) || !updatedShippingNoteForm?.shippingNote.name) {
      disabled = true;
    }
    return setSaveDisabled(disabled);
  };

  // console.log('packet......', packetList);

  const [filterPacket, setFilterPacket] = useState(false);

  useEffect(() => {
    const filter = packetList?.filter(packet => packet?.selected != true);
    if (filter) {
      setFilterPacket(filter);
    }
  }, []);

  return (
    <View style={[UrstammStyle.flex1, UrstammStyle.flexColumn, UrstammStyle.flexStartX]}>
      <ScrollView
        keyboardShouldPersistTaps="always"
        contentContainerStyle={[{ flexGrow: 1, flexDirection: 'column', marginBottom: 28 }]}>
        {/** ShippingNote name */}
        <View style={[UrstammStyleLayout.formElementContainerGrw1, { justifyContent: 'flex-start' }]}>
          <UrstammInput
            testID="shipping_note_name"
            style={{
              containerViewStyle: UrstammStyleContainer.inputContainer,
              textStyle: UrstammStyle.formBaseLDarkGrey
            }}
            icon={{ showIcon: false }}
            value={shippingNoteForm.shippingNote.name ? shippingNoteForm.shippingNote.name : ''}
            onChangeText={(text: string) => {
              updateShippingNoteForm('name', text, true);
            }}
            placeholder={i18n.t('views.shipping_note.shipping_note_registration.insert_shipping_note_name_here')}
            textInputTitle={i18n.t('views.shipping_note.shipping_note_registration.shipping_note_name')}
            mandatory={true}
          />

          {/**Validator */}
          {errors['name'] && errors['name'].length > 0
            ? errors['name'].map((error, idx) => {
                return urstammUtilDisplayError(error, idx);
              })
            : null}
        </View>

        {/** ShippingNote creation date (preset: today) */}
        <View style={UrstammStyleLayout.formElementContainerGrw1}>
          <UrstammDatePicker
            testID={'shipping_note_date'}
            containerStyle={UrstammStyleContainer.inputContainer}
            fontStyle={UrstammStyle.formBaseLDarkGrey}
            textInputTitle={i18n.t('generics.date')}
            onChange={(dateTime: string) => updateShippingNoteForm('creationDate', dateTime)}
            icon={{
              showIcon: true,
              iconName: 'calendar',
              iconColor: ColorTheme.lightGreen
            }}
            placeholder={i18n.t('views.shipping_note.shipping_note_registration.insert_shipping_note_date_here')}
            mandatory={true}
            mode={'date'}
          />
        </View>

        {/** ShippingNote list */}
        <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
          {/**Title */}
          <View style={UrstammStyle.textTitleContainerFlex}>
            <Text style={UrstammStyle.textTitle}>
              {i18n.t('views.shipping_note.shipping_note_registration.choose_batch')}
            </Text>
          </View>

          <UrstammButtonBase
            testID={'choose_batch'}
            text={
              shippingNoteForm.shippingNote.batch
                ? shippingNoteForm.shippingNote.batch.name
                : i18n.t('views.shipping_note.shipping_note_registration.choose_batch')
            }
            style={lightGreenBorderWhiteBGButton}
            onSubmit={() => setShowBatchSelect(true)}
          />

          {/**ShippingNote Modal single select */}
          {showBatchSelect ? (
            <UrstammModalSelect
              testID={'modal_batch_multiple_select'}
              visible={showBatchSelect}
              text={{
                textInputTitle: i18n.t('views.shipping_note.shipping_note_registration.choose_batch'),
                textStyle: UrstammStyle.textTitle
              }}
              closeModal={() => {
                setShowBatchSelect(false);
              }}
              listJSX={
                <UrstammList
                  titleKey={'name'}
                  elementSelected={(batch: any) => {
                    updateShippingNoteForm('batch', batch.item, false);
                    setShowBatchSelect(false);
                    props.batchProps.batchSelectedID(batch.item);
                  }}
                  showHash={false}
                  testID={'batch_list'}
                  titleByFunction={data => getCardTitleByBatchOrPacket(data)}
                  textForEmptyList={i18n.t('views.batch.batch_not_found')}
                  listSubData={batchKeys}
                  list={props.batchProps?.batchList}
                  listStyle={{
                    mainTopContainerViewStyle: UrstammStyleList.mainTopContainerCol,
                    mainBottomContainerViewStyle: UrstammStyleList.cardSubContainerPDT12,
                    mainTitleTextStyle: UrstammStyleList.xCardMainTitleTextStyle,
                    bottomContainerViewStyle: UrstammStyleList.cardSubData,
                    bottomDataTextStyle: UrstammStyleList.cardSubTitleTextStyle,
                    paddingBottom: 4
                  }}
                  currentState={{
                    icon: state => getIconByBatchState(state),
                    color: state => getColorByBatchState(state),
                    text: state => getTextByBatchState(state)!
                  }}
                  moreItems={props.batchProps?.moreItems}
                  filterList={true}
                  multipleSelection={false}
                />
              }
            />
          ) : null}
        </View>

        {/** Processor list */}
        <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
          {/**Title */}
          <View style={UrstammStyle.textTitleContainerFlex}>
            <Text style={UrstammStyle.textTitle}>{i18n.t('generics.processor')}</Text>
          </View>

          <UrstammButtonBase
            testID={'choose_processor'}
            text={
              shippingNoteForm.shippingNote.processor
                ? shippingNoteForm.shippingNote.processor.name
                : i18n.t('views.shipping_note.shipping_note_registration.choose_processor')
            }
            style={lightGreenBorderWhiteBGButton}
            onSubmit={() => setShowProcessorModalSelect(true)}
          />

          {/**Packet processor  */}
          {showProcessorModalSelect ? (
            <UrstammModalSelect
              testID={'modal_packet_company_processor'}
              visible={showProcessorModalSelect}
              text={{
                textInputTitle: i18n.t('views.shipping_note.shipping_note_registration.choose_processor'),
                textStyle: UrstammStyle.textTitle
              }}
              closeModal={() => {
                setShowProcessorModalSelect(false);
              }}
              confirmButton={{
                enabled: false,
                text: i18n.t('views.packet.no_urstamm_processor'),
                submitValue: () => {}
              }}
              listJSX={
                <UrstammList
                  titleKey={'name'}
                  elementSelected={(processor: any) => {
                    updateShippingNoteForm('processor', processor.item, false);
                    setShowProcessorModalSelect(false);
                    props.processorProps.processorSelectedID(processor.item);
                  }}
                  showHash={false}
                  testID={'processor_list'}
                  textForEmptyList={i18n.t('views.packet.processors_not_found')}
                  list={props.processorProps?.processorList}
                  listStyle={{
                    mainTopContainerViewStyle: UrstammStyleList.mainTopContainerCol,
                    mainBottomContainerViewStyle: UrstammStyleList.cardSubContainerPDT12,
                    mainTitleTextStyle: UrstammStyleList.xCardMainTitleTextStyle,
                    bottomContainerViewStyle: UrstammStyleList.cardSubData,
                    bottomDataTextStyle: UrstammStyleList.cardSubTitleTextStyle,
                    paddingBottom: 4
                  }}
                  filterList={true}
                  multipleSelection={false}
                />
              }
            />
          ) : null}
        </View>

        {/** Packet list */}
        <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
          {/**Title */}
          <View style={UrstammStyle.textTitleContainerFlex}>
            <Text style={UrstammStyle.textTitle}>
              {i18n.t('views.shipping_note.shipping_note_registration.choose_packets') + getMandatorySymbol(false)}
            </Text>
          </View>

          <UrstammButtonBase
            testID={'choose_packet'}
            text={i18n.t('views.shipping_note.shipping_note_registration.choose_packets')}
            style={lightGreenBorderWhiteBGButton}
            onSubmit={() => setShowPacketSelect(true)}
            disabled={!shippingNoteForm.shippingNote.processor}
            counter={getCounterBySelected(packetList)}
          />

          {/**Packet Modal multiple select */}
          {showPacketSelect ? (
            <UrstammModalSelect
              testID={'modal_batch_multiple_select'}
              visible={showPacketSelect}
              text={{
                textInputTitle: i18n.t('views.shipping_note.shipping_note_registration.choose_packets'),
                textStyle: UrstammStyle.textTitle
              }}
              closeModal={() => {
                setShowPacketSelect(false),
                  props.packetProps.resetPacketSelected ? props.packetProps.resetPacketSelected() : null;
              }}
              confirmButton={{
                enabled: true,
                text: i18n.t('generics.save'),
                submitValue: () => {
                  setShowPacketSelect(false), setFilterPacket(packetList?.filter(packet => packet?.selected !== true));
                  props.packetProps.confirmPacketSelected ? props.packetProps.confirmPacketSelected() : null;
                }
              }}
              listJSX={
                <UrstammList
                  elementSelected={(packetSelected: any) => {
                    props.packetProps.packetSelected(packetSelected);
                    saveButtonDisabled(errors, shippingNoteForm, packetList);
                  }}
                  showHash={false}
                  testID={'packet_list'}
                  textForEmptyList={i18n.t('views.packet.packets_not_found')}
                  titleByFunction={data => getCardTitleByBatchOrPacket(data)}
                  list={filterPacket ? filterPacket : packetList}
                  listStyle={{
                    mainTopContainerViewStyle: UrstammStyleList.mainTopContainerCol,
                    mainBottomContainerViewStyle: UrstammStyleList.cardSubContainerPDT12,
                    mainTitleTextStyle: UrstammStyleList.xCardMainTitleTextStyle,
                    bottomContainerViewStyle: UrstammStyleList.cardSubData,
                    bottomDataTextStyle: UrstammStyleList.cardSubTitleTextStyle,
                    paddingBottom: 4
                  }}
                  listSubData={keys}
                  moreItems={props.packetProps.moreItems}
                  filterList={true}
                  multipleSelection={true}
                  filterListFunction={(text, data) =>
                    filterPacketByNameOrUniqueIdentifierOrProductTypeOrProcessor(text, data, props.userExtendedMe!)
                  }
                  currentState={{
                    //icon: (state) => getIconByLoggingState(state),
                    color: state => getColorByPacketState(state),
                    text: state => getTextByPacketState(state)
                  }}
                  titleKey={'name'}
                  getTextForData={data => getTextByPacketState(data)}
                />
              }
            />
          ) : null}
        </View>

        {/** Comment */}
        <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
          <UrstammInput
            testID="shippingNote_comment"
            style={{
              containerViewStyle: UrstammStyleContainer.textareaContainer,
              textStyle: UrstammStyle.formBaseLDarkGrey
            }}
            icon={{ showIcon: false }}
            value={shippingNoteForm.shippingNote.comment ? shippingNoteForm.shippingNote.comment : ''}
            onChangeText={(text: string) => updateShippingNoteForm('comment', text, false)}
            placeholder={i18n.t('generics.insert_comment_here')}
            textInputTitle={i18n.t('generics.comment')}
            mandatory={false}
            multiline={true}
          />
        </View>

        {/**Save */}
        <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
          <UrstammButtonBase
            testID={'save_shippingNote'}
            text={i18n.t('generics.save')}
            style={mainGreenButton}
            onSubmit={() => props.submitShippingNoteForm(shippingNoteForm.shippingNote)}
            disabled={saveDisabled}
          />
        </View>
      </ScrollView>
    </View>
  );
}

const styles = StyleSheet.create({});
