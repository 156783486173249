import UrstammImageBack from '@components/images/corner/UrstammImageBack';
import UrstammImageMenu from '@components/images/corner/UrstammImageMenu';
import UrstammImagePlus from '@components/images/corner/UrstammImagePlus';
import BaseLayoutCorner, { BaseLayoutOptions } from '@components/layouts/base/BaseLayoutCorner';
import { ButtonSelect } from '@components/utility-components/button/UrstammButtonSelect';
import UrstammTitle from '@components/utility-components/title/UrstammTitle';
import ProcessorBatchListView from '@components/views/processor-batch/ProcessorBatchListView';
import { AlertHelper } from '@helpers/AlertHelper';
import { ErrorHelper } from '@helpers/ErrorHelper';
import { PlatformHelper } from '@helpers/PlatformHelper';
import { i18n } from '@i18n/i18n';
import { useFocusEffect } from '@react-navigation/native';
import { changeLoaderStatus } from '@redux/features/loader/loaderSlice';
import {
  setProcessorBatchList,
  setProcessorBatchListSort,
  setProcessorBatchPage,
  setProcessorBatchTotalPages
} from '@redux/features/processor-batch/processorBatchSlice';
import { RootState } from '@redux/store';
import { customProcessorBatchResourceApi, listSize } from '@services/apis/ApiConfiguration';
import { PageProcessorBatch, ProcessorBatch } from '@services/apis/generated';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootStackScreenProps, UrstammNavigationHelper } from '../../navigation/UrstammNavigationHelper';
import { urstammUtilGoBack } from '../../utils/classes/UrstammUtilityFunctions';
import { UrstammStyleCornerButton, UrstammStyleHeader, UrstammStyleLayout } from '../../utils/styles/UrstammStyle';

export default function ProcessorBatchListContainer({ navigation, route }: RootStackScreenProps<'ProcessorBatchList'>) {
  //REDUX
  const rdxProcessorBatchList = useSelector((state: RootState) => state.persistedReducer.processorBatch.list);
  const rdxProcessorBatchPage = useSelector((state: RootState) => state.persistedReducer.processorBatch.page);
  const rdxProcessorBatchTotalPages = useSelector(
    (state: RootState) => state.persistedReducer.processorBatch.totalPages
  );
  const rdxSortBy = useSelector((state: RootState) => state.persistedReducer.processorBatch.sortBy);
  const dispatch = useDispatch();
  const [showSortBy, setShowSortBy] = useState<boolean>(false);

  /**
   * Settings for corner buttons
   */
  let cornerOption: BaseLayoutOptions = {
    cornerTopLeft: {
      showCorner: false,
      text: i18n.t('utility_components.corner_button.back'),
      icon: (
        <UrstammImageBack width={PlatformHelper.normalizeByDevice(34)} height={PlatformHelper.normalizeByDevice(24)} />
      )
    },
    cornerTopRight: {
      showCorner: true,
      text: i18n.t('utility_components.corner_button.menu'),
      icon: (
        <UrstammImageMenu width={PlatformHelper.normalizeByDevice(34)} height={PlatformHelper.normalizeByDevice(24)} />
      )
    },
    cornerBottomLeft: {
      showCorner: true,
      text: i18n.t('utility_components.corner_button.sort_by'),
      icon: undefined,
      subText:
        rdxSortBy.length > 0 && rdxSortBy[0] ? rdxSortBy[0].text : i18n.t('utility_components.modal.sortBy.date_desc')
    },
    cornerBottomRight: {
      showCorner: true,
      text: i18n.t('generics.processor_batch'),
      icon: (
        <UrstammImagePlus width={PlatformHelper.normalizeByDevice(34)} height={PlatformHelper.normalizeByDevice(24)} />
      )
    }
  };

  useEffect(() => {
    dispatch(changeLoaderStatus(false));
    getProcessorBatchList(rdxSortBy);
  }, [route.params, rdxSortBy]);

  useFocusEffect(
    useCallback(() => {
      getProcessorBatchList(rdxSortBy);
    }, [route.params, rdxProcessorBatchPage, rdxSortBy])
  );

  const applyOrder = (orderSelected: ButtonSelect[]): void => {
    dispatch(setProcessorBatchListSort(orderSelected));
    setShowSortBy(false);
  };

  const navigateToProcessorBatchDetails = (processorBatch: ProcessorBatch) => {
    UrstammNavigationHelper.navigateToProcessorBatchDetails(navigation, processorBatch);
  };

  const getProcessorBatchList = (order?: ButtonSelect[]) => {
    let sortBy =
      order && order.length > 0 ? [order[0].sort?.sortBy + ',' + order[0].sort?.direction] : ['creationDate,desc'];
    dispatch(changeLoaderStatus(true));

    customProcessorBatchResourceApi
      .getPageProcessorBatchesForMyCompany({
        sort: sortBy,
        customProcessorBatchCriteria: { currentState: {} },
        page: rdxProcessorBatchPage,
        size: listSize
      })
      .then((processorBatchList: PageProcessorBatch) => {
        dispatch(changeLoaderStatus(false));
        if (processorBatchList) {
          dispatch(setProcessorBatchTotalPages(processorBatchList.totalPages));
          dispatch(setProcessorBatchList(processorBatchList.content));
        }
      })
      .catch(async error => {
        dispatch(changeLoaderStatus(false));
        let errorMessage = await ErrorHelper.getServerMessageFromJson(error);
        let errorJson = await ErrorHelper.getDetailFromServerMessage(errorMessage);
        AlertHelper.showSimpleAlert('Error', errorJson);
      });
  };

  const navigateToProcessorBatchRegistration = () => {
    UrstammNavigationHelper.navigateToProcessorBatchRegistration(navigation, rdxProcessorBatchList);
  };

  const getMoreProcessorBatchItems = (): void => {
    if (rdxProcessorBatchPage < rdxProcessorBatchTotalPages - 1) {
      dispatch(setProcessorBatchPage(rdxProcessorBatchPage + 1));
    }
  };

  return (
    <BaseLayoutCorner
      navigation={navigation}
      cornerOptions={cornerOption}
      layoutStyle={{
        topContainer: UrstammStyleLayout.topContainerCorner,
        bottomContainer: UrstammStyleLayout.baseBottomContainer,
        bottomSubContainer: UrstammStyleLayout.bottomSubContainerCorner12,
        headerContainer: UrstammStyleHeader.headerLogoTitleStart,
        cornerBottomLeft: UrstammStyleCornerButton.cornerBottomLeft12,
        cornerBottomRight: UrstammStyleCornerButton.cornerBottomRight12
      }}
      submitBottomRight={navigateToProcessorBatchRegistration}
      submitTopLeft={() => urstammUtilGoBack(navigation)}
      submitBottomLeft={() => setShowSortBy(true)}
      title={
        <UrstammTitle
          testID={'processorBatch_title'}
          text={i18n.t('generics.processor_batch')}
          fontStyle={UrstammStyleHeader.headerTextStyleBlack}
        />
      }
      view={
        <ProcessorBatchListView
          navigation={navigation}
          processorBatchList={rdxProcessorBatchList}
          modalVisible={false}
          moreItems={getMoreProcessorBatchItems}
          processorBatchSelected={(processorBatch: ProcessorBatch) => navigateToProcessorBatchDetails(processorBatch)}
          showSortBy={showSortBy}
          closeShowSortBy={() => setShowSortBy(false)}
          applyOrder={(orderSelected: ButtonSelect[]) => applyOrder(orderSelected)}
        />
      }
    />
  );
}
