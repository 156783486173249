/* tslint:disable */
/* eslint-disable */
/**
 * Urstamm API
 * Urstamm API documentation
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Company } from './Company';
import {
    CompanyFromJSON,
    CompanyFromJSONTyped,
    CompanyToJSON,
} from './Company';

/**
 * 
 * @export
 * @interface CompanyAppearance
 */
export interface CompanyAppearance {
    /**
     * 
     * @type {number}
     * @memberof CompanyAppearance
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof CompanyAppearance
     */
    name: string;
    /**
     * 
     * @type {Date}
     * @memberof CompanyAppearance
     */
    deletionDate?: Date;
    /**
     * 
     * @type {Company}
     * @memberof CompanyAppearance
     */
    company?: Company;
}

/**
 * Check if a given object implements the CompanyAppearance interface.
 */
export function instanceOfCompanyAppearance(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;

    return isInstance;
}

export function CompanyAppearanceFromJSON(json: any): CompanyAppearance {
    return CompanyAppearanceFromJSONTyped(json, false);
}

export function CompanyAppearanceFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanyAppearance {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': json['name'],
        'deletionDate': !exists(json, 'deletionDate') ? undefined : (new Date(json['deletionDate'])),
        'company': !exists(json, 'company') ? undefined : CompanyFromJSON(json['company']),
    };
}

export function CompanyAppearanceToJSON(value?: CompanyAppearance | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'deletionDate': value.deletionDate === undefined ? undefined : (value.deletionDate.toISOString()),
        'company': CompanyToJSON(value.company),
    };
}

