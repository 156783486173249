import UrstammImageBack from '@components/images/corner/UrstammImageBack';
import UrstammImageMenu from '@components/images/corner/UrstammImageMenu';
import UrstammImagePlus from '@components/images/corner/UrstammImagePlus';
import BaseLayoutCorner, { BaseLayoutOptions } from '@components/layouts/base/BaseLayoutCorner';
import { ButtonSelect } from '@components/utility-components/button/UrstammButtonSelect';
import {
  applySavingToReference,
  restoreListToReference,
  selectElementFormList,
  selectSlideElementFormList
} from '@components/utility-components/modal/UrstammModalSelect';
import UrstammTitle from '@components/utility-components/title/UrstammTitle';
import ProductDetailsView from '@components/views/product/ProductDetailsView';
import { ProductForm } from '@components/views/product/ProductRegistrationView';
import { AlertHelper } from '@helpers/AlertHelper';
import { ErrorHelper } from '@helpers/ErrorHelper';
import { PlatformHelper } from '@helpers/PlatformHelper';
import { i18n } from '@i18n/i18n';
import {
  resetCompanyAppearanceList,
  setCompanyAppearanceList
} from '@redux/features/appearance/companyAppearanceSlice';
import { changeLoaderStatus } from '@redux/features/loader/loaderSlice';
import { resetProcessorBatchList } from '@redux/features/processor-batch/processorBatchSlice';
import {
  resetCompanyProductTypeList,
  setCompanyProductTypeList
} from '@redux/features/product-type/companyProductTypeSlice';
import { resetProductList } from '@redux/features/product/productSlice';
import { resetCompanySurfaceList, setCompanySurfaceList } from '@redux/features/surface/companySurfaceSlice';
import { RootState } from '@redux/store';
import {
  customCompanyAppearanceResourceApi,
  customCompanyProductTypeResourceApi,
  customCompanyResourceApi,
  customCompanySurfaceResourceApi,
  customProductResourceApi,
  customTrunkResourceApi,
  longListSize
} from '@services/apis/ApiConfiguration';
import {
  Company,
  CompanyAppearance,
  CompanyProductType,
  CompanySurface,
  PageCompany,
  ProcessorBatchDTO,
  Product,
  ProductCertification,
  ProductCertificationCertificationEnum,
  ProductCurrentStateEnum,
  ProductDTO,
  ProductSpecies,
  ProductSpeciesSpeciesEnum
} from '@services/apis/generated';
import React, { useEffect, useMemo, useState } from 'react';
import { Keyboard } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import { RootStackScreenProps, UrstammNavigationHelper } from '../../navigation/UrstammNavigationHelper';
import { RouteProcessorBatchDetails } from '../../navigation/UrstammNavigationRoutes';
import { getTextByTreeOrTrunkSpecies } from '../../utils/classes/UrstammUtilityCurrentState';
import {
  generateSetForCertification,
  generateSetForProductSpecies,
  sleep
} from '../../utils/classes/UrstammUtilityFunctions';
import { UrstammStyleCornerButton, UrstammStyleHeader, UrstammStyleLayout } from '../../utils/styles/UrstammStyle';

export default function ProductDetailsContainer({ route, navigation }: RootStackScreenProps<'ProductDetails'>) {
  let cornerOption: BaseLayoutOptions = {
    cornerTopLeft: {
      showCorner: true,
      text: i18n.t('utility_components.corner_button.back'),
      icon: (
        <UrstammImageBack width={PlatformHelper.normalizeByDevice(34)} height={PlatformHelper.normalizeByDevice(24)} />
      )
    },
    cornerTopRight: {
      showCorner: true,
      text: i18n.t('utility_components.corner_button.menu'),
      icon: (
        <UrstammImageMenu width={PlatformHelper.normalizeByDevice(34)} height={PlatformHelper.normalizeByDevice(24)} />
      )
    },
    cornerBottomLeft: {
      showCorner: false,
      text: i18n.t('utility_components.corner_button.sort_by'),
      icon: undefined
    },
    cornerBottomRight: {
      showCorner: false,
      text: i18n.t('generics.product'),
      icon: (
        <UrstammImagePlus width={PlatformHelper.normalizeByDevice(34)} height={PlatformHelper.normalizeByDevice(24)} />
      )
    }
  };

  //Redux
  const rdxCompanyProductTypeList = useSelector((state: RootState) => state.persistedReducer.companyProductType.list);
  const rdxCompanyProductTypeCompleteList = useSelector(
    (state: RootState) => state.persistedReducer.companyProductType.completeList
  );

  const rdxCompanySurfaceList = useSelector((state: RootState) => state.persistedReducer.companySurface.list);
  const rdxCompanySurfaceCompleteList = useSelector(
    (state: RootState) => state.persistedReducer.companySurface.completeList
  );

  const rdxCompanyAppearanceList = useSelector((state: RootState) => state.persistedReducer.companyAppearance.list);
  const rdxCompanyAppearanceCompleteList = useSelector(
    (state: RootState) => state.persistedReducer.companyAppearance.completeList
  );
  const dispatch = useDispatch();

  const [productSelected, setProductSelected] = useState<ProductDTO>();
  const [processorBatchSelected, setProcessorBatchSelected] = useState<ProcessorBatchDTO>();

  const [productSpeciesList, setProductSpeciesList] = useStateWithCallbackLazy<any[]>([]);
  const [productSpeciesListReference, setProductSpeciesListReference] = useStateWithCallbackLazy<any[]>([]);

  const [certificationList, setCertificationList] = useStateWithCallbackLazy<any[]>([]);
  const [certificationListReference, setCertificationListReference] = useStateWithCallbackLazy<any[]>([]);

  const [processorList, setProcessorList] = useState<Company[]>([]);

  const readOnly = useMemo(() => {
    return !!route.params?.readOnly;
  }, [!!route.params?.readOnly]);

  useEffect(() => {
    if (route.params?.productSelected && route.params?.processorBatchSelected) {
      // console.log(route.params?.processorBatchSelected)
      getProductCustom(route.params.productSelected);
      setProcessorBatchSelected(route.params?.processorBatchSelected);
      getAllCompanyProcessorConnectedToMe();
      getCompanyProductType();
      getCompanySurfaceResourceApi();
      getCompanyAppearanceResourceApi();
    }
  }, [route.params]);

  const goBack = async (): Promise<void> => {
    // Get the source
    const returnTo: string = (route.params?.returnTo || '') ?? '';
    switch (returnTo) {
      case 'back':
        Keyboard.dismiss();
        await sleep(PlatformHelper.isIos() ? 300 : 100);
        navigation.goBack();
        break;
      case RouteProcessorBatchDetails:
      default:
        dispatch(resetProcessorBatchList());
        Keyboard.dismiss();
        await sleep(PlatformHelper.isIos() ? 300 : 100);
        UrstammNavigationHelper.navigateToProcessorBatchDetails(navigation, processorBatchSelected!, true);
        break;
    }
  };

  const getProductCustom = (productSelected: Product) => {
    dispatch(changeLoaderStatus(true));

    customProductResourceApi
      .getProductCustom({ id: productSelected.id! })
      .then((product: ProductDTO) => {
        dispatch(changeLoaderStatus(false));
        if (product) {
          setProductSelected(product);
          setProcessorBatchSelected(readOnly ? product.processorBatch : route.params?.processorBatchSelected);
          getAllProductCertifications(product);
          getAllProductSpecies(product);
        }
      })
      .catch(async error => {
        dispatch(changeLoaderStatus(false));
        let errorMessage = await ErrorHelper.getServerMessageFromJson(error);
        let errorJson = await ErrorHelper.getDetailFromServerMessage(errorMessage);
        AlertHelper.showSimpleAlert('Error', errorJson);
      });
  };

  /**
   * Setting processor list, it used for "Registration" and "Detail" Pages.
   * @param order
   */
  const getAllCompanyProcessorConnectedToMe = (order?: ButtonSelect[]) => {
    let sortBy = order && order.length > 0 ? [order[0].sort?.sortBy + ',' + order[0].sort?.direction] : ['name,desc'];
    dispatch(changeLoaderStatus(true));

    customCompanyResourceApi
      .getCompaniesConnectedToMe({
        sort: sortBy,
        customCompanyCriteria: {},
        page: 0,
        size: longListSize
      })
      .then((list: PageCompany) => {
        dispatch(changeLoaderStatus(false));

        if (list && list.content?.length) {
          list.content.push({ name: i18n.t('views.product.no_urstamm_processor'), id: -200 });
          setProcessorList(list.content);
        }
      })
      .catch(async error => {
        dispatch(changeLoaderStatus(false));
        let errorMessage = await ErrorHelper.getServerMessageFromJson(error);
        let errorJson = await ErrorHelper.getDetailFromServerMessage(errorMessage);
        AlertHelper.showSimpleAlert('Error', errorJson);
      });
  };

  const getCompanyProductType = () => {
    dispatch(changeLoaderStatus(true));

    customCompanyProductTypeResourceApi
      .getAllCompanyProductTypesForCompany({})
      .then((productTypeList: CompanyProductType[]) => {
        dispatch(changeLoaderStatus(false));

        if (productTypeList) {
          dispatch(resetCompanyProductTypeList());
          dispatch(setCompanyProductTypeList(productTypeList));
        }
      })
      .catch(async error => {
        dispatch(changeLoaderStatus(false));
        let errorMessage = await ErrorHelper.getServerMessageFromJson(error);
        let errorJson = await ErrorHelper.getDetailFromServerMessage(errorMessage);
        AlertHelper.showSimpleAlert('Error', errorJson);
      });
  };

  const getCompanySurfaceResourceApi = () => {
    dispatch(changeLoaderStatus(true));

    customCompanySurfaceResourceApi
      .getAllCompanySurfacesForCompany({})
      .then((surfaceList: CompanySurface[]) => {
        dispatch(changeLoaderStatus(false));

        if (surfaceList) {
          dispatch(resetCompanySurfaceList());
          dispatch(setCompanySurfaceList(surfaceList));
        }
      })
      .catch(async error => {
        dispatch(changeLoaderStatus(false));
        let errorMessage = await ErrorHelper.getServerMessageFromJson(error);
        let errorJson = await ErrorHelper.getDetailFromServerMessage(errorMessage);
        AlertHelper.showSimpleAlert('Error', errorJson);
      });
  };

  const getCompanyAppearanceResourceApi = () => {
    dispatch(changeLoaderStatus(true));

    customCompanyAppearanceResourceApi
      .getAllCompanyAppearancesForCompany({})
      .then((appearanceList: CompanyAppearance[]) => {
        dispatch(changeLoaderStatus(false));

        if (appearanceList) {
          dispatch(resetCompanyAppearanceList());
          dispatch(setCompanyAppearanceList(appearanceList));
        }
      })
      .catch(async error => {
        dispatch(changeLoaderStatus(false));
        let errorMessage = await ErrorHelper.getServerMessageFromJson(error);
        let errorJson = await ErrorHelper.getDetailFromServerMessage(errorMessage);
        AlertHelper.showSimpleAlert('Error', errorJson);
      });
  };

  const getAllProductCertifications = (product: ProductDTO) => {
    let filterList: any[] = [];
    Object.values(ProductCertificationCertificationEnum).map(state => {
      let type: any = {
        id: state,
        name: state,
        selected: state == ProductCertificationCertificationEnum.Urstamm,
        disabled: state == ProductCertificationCertificationEnum.Urstamm
      };
      filterList.push(type);
    });

    let productCertification = product.productCertifications ? [...product.productCertifications] : [];
    if (productCertification && productCertification.length > 0) {
      productCertification.forEach(elemSelected => {
        for (let val of filterList) {
          if (val.id == elemSelected.certification) {
            val.selected = true;
            break;
          }
        }
      });
    }
    setCertificationList(filterList, () => {});
    setCertificationListReference(filterList, () => {});
  };

  const getAllProductSpecies = (product: ProductDTO) => {
    let productSpecies = product.productSpecies ? [...product.productSpecies] : [];

    let filterList: any[] = Object.values(ProductSpeciesSpeciesEnum).map(state => ({
      id: state,
      name: state,
      key: state,
      text: getTextByTreeOrTrunkSpecies(state),
      selected: !!productSpecies.find(p => p.species === state),
      active: !!productSpecies.find(p => p.species === state),
      disabled: false
    }));
    setProductSpeciesList(filterList, () => {});
    setProductSpeciesListReference(filterList, () => {});
  };

  const submitProduct = (data: ProductForm) => {
    dispatch(changeLoaderStatus(true));

    let certificationSet: Set<ProductCertification> = new Set(generateSetForCertification(certificationList));
    let speciesSet: Set<ProductSpecies> = generateSetForProductSpecies(productSpeciesList);

    let productObj: Product = {
      id: productSelected?.id,
      processorBatch: processorBatchSelected as any,
      currentState: ProductCurrentStateEnum.InProgress,
      uniqueIdentifier: data.product.uniqueIdentifier!,
      name: data.product.name!,
      creationDate: data.product.creationDate,
      comment: data.product.comment,
      processor: data.product.processorCustomName ? undefined : data.product.processor,
      processorCustomName:
        data.product.processorCustomName && data.product.processorCustomName.length > 0
          ? data.product.processorCustomName
          : undefined,
      sumCubage: data.product.sumCubage,
      productCertifications: certificationSet,
      productSpecies: speciesSet,
      productType: data.product.productType,
      surface: data.product.surface,
      appearance: data.product.appearance,
      photoCreationDate: data.product.photoCreationDate
    };

    customProductResourceApi
      .updateProductCustom({
        id: productSelected?.id!,
        entityWithFileDTOProduct: { entity: productObj }
      })
      .then(async (product: Product) => {
        dispatch(changeLoaderStatus(false));

        if (product) {
          dispatch(resetProductList());
          Keyboard.dismiss();
          await sleep(PlatformHelper.isIos() ? 300 : 100);
          UrstammNavigationHelper.navigateToProcessorBatchDetails(navigation, processorBatchSelected, true);
        }
      })
      .catch(async error => {
        dispatch(changeLoaderStatus(false));
        let errorMessage = await ErrorHelper.getServerMessageFromJsonOrResponse(error);
        let errorJson = await ErrorHelper.getDetailFromServerMessage(errorMessage);
        AlertHelper.showSimpleAlert('Error', errorJson);
      });
  };

  return (
    <BaseLayoutCorner
      navigation={navigation}
      cornerOptions={cornerOption}
      layoutStyle={{
        topContainer: UrstammStyleLayout.topContainerCorner,
        bottomContainer: UrstammStyleLayout.baseBottomContainer,
        bottomSubContainer: UrstammStyleLayout.bottomSubContainer40,
        headerContainer: UrstammStyleHeader.headerCenterLogo,
        headerDetails: UrstammStyleHeader.headerDetails,
        cornerBottomLeft: UrstammStyleCornerButton.cornerBottomLeft40,
        cornerBottomRight: UrstammStyleCornerButton.cornerBottomRight40
      }}
      submitTopLeft={goBack}
      title={
        <UrstammTitle
          testID={'product_title'}
          text={i18n.t('generics.product')}
          fontStyle={UrstammStyleHeader.headerTextStyleBlack}
        />
      }
      subTitle={
        <UrstammTitle
          testID={'product_details_subtitle'}
          text={route.params?.productSelected?.uniqueIdentifier}
          fontStyle={UrstammStyleHeader.headerDetailsTextStyleBlack}
        />
      }
      headerDetails={[{ title: i18n.t('generics.name'), value: route.params?.productSelected?.name }]}
      view={
        processorBatchSelected ? (
          <ProductDetailsView
            navigation={navigation}
            processorBatchDTO={processorBatchSelected}
            productSelected={route.params?.productSelected}
            submitProductForm={(productForm: ProductForm) => submitProduct(productForm)}
            companyProductTypeProps={{
              companyProductTypeList: rdxCompanyProductTypeList
            }}
            companySurfaceProps={{
              companySurfaceList: rdxCompanySurfaceList
            }}
            companyAppearanceProps={{
              companyAppearanceList: rdxCompanyAppearanceList
            }}
            certificationProps={{
              certificationList: certificationList,
              certificationSelected: (data: any) =>
                selectElementFormList(data, setCertificationList, certificationList),
              resetCertificationSelected: () =>
                restoreListToReference(setCertificationList, certificationListReference),
              confirmCertificationSelected: () =>
                applySavingToReference(setCertificationListReference, certificationList)
            }}
            speciesProps={{
              speciesList: productSpeciesList,
              speciesSelected: (data: any) =>
                selectSlideElementFormList(data, setProductSpeciesList, productSpeciesList),
              resetSpeciesSelected: () => restoreListToReference(setProductSpeciesList, productSpeciesListReference),
              confirmSpeciesSelected: () => applySavingToReference(setProductSpeciesListReference, productSpeciesList)
            }}
            processorList={processorList}
            // speciesProps={(data: any) => getSpeciesListId(data, setSpeciesList, speciesList)}
            // allProductSpecies={productSpeciesList}
            readOnly={readOnly}
          />
        ) : (
          <></>
        )
      }
    />
  );
}
