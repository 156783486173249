import { DrawerItem } from '@react-navigation/drawer';
import { DrawerDescriptorMap, DrawerNavigationHelpers } from '@react-navigation/drawer/lib/typescript/src/types';
import {
  CommonActions,
  DrawerActions,
  DrawerNavigationState,
  ParamListBase,
  StackActions,
  useLinkBuilder
} from '@react-navigation/native';
import * as React from 'react';

type Props = {
  state: DrawerNavigationState<ParamListBase>;
  navigation: DrawerNavigationHelpers;
  descriptors: DrawerDescriptorMap;
};

/**
 * Component that renders the navigation list in the drawer.
 */
export default function DrawerItemList({ state, navigation, descriptors }: Props) {
  const buildLink = useLinkBuilder();

  const focusedRoute = state.routes[state.index];
  const focusedDescriptor = descriptors[focusedRoute.key];
  const focusedOptions = focusedDescriptor.options;

  const { drawerActiveTintColor, drawerInactiveTintColor, drawerActiveBackgroundColor, drawerInactiveBackgroundColor } =
    focusedOptions;

  const popScreen = () => {
    try {
      const popAction = StackActions.popToTop();
      if (navigation.canGoBack()) {
        // console.log("Can Go Back");
        navigation.dispatch(popAction);
      }
    } catch (e) {
      console.log('Preventing pop error');
    }
  };

  return state.routes.map((route, i) => {
    const focused = i === state.index;

    const onPress = () => {
      // console.log('Custom press');
      const event = navigation.emit({
        type: 'drawerItemPress',
        target: route.key,
        canPreventDefault: true
      });

      if (!event.defaultPrevented) {
        navigation.dispatch({
          ...(focused ? DrawerActions.closeDrawer() : CommonActions.navigate({ name: route.name, merge: true })),
          target: state.key
        });
      }
      popScreen();
    };

    const { title, drawerLabel, drawerIcon, drawerLabelStyle, drawerItemStyle, drawerAllowFontScaling } =
      descriptors[route.key].options;

    return (
      <DrawerItem
        key={route.key}
        label={drawerLabel !== undefined ? drawerLabel : title !== undefined ? title : route.name}
        icon={drawerIcon}
        focused={focused}
        activeTintColor={drawerActiveTintColor}
        inactiveTintColor={drawerInactiveTintColor}
        activeBackgroundColor={drawerActiveBackgroundColor}
        inactiveBackgroundColor={drawerInactiveBackgroundColor}
        allowFontScaling={drawerAllowFontScaling}
        labelStyle={drawerLabelStyle}
        style={drawerItemStyle}
        to={buildLink(route.name, route.params)}
        onPress={onPress}
      />
    );
  }) as React.ReactNode as React.ReactElement;
}
